// In your Vue component.
import {
    Splitpanes,
    Pane
} from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import "./designpage.css";
import {
    Threelib
} from "../../libs/3d/threelib";
import {
    MeshControl
} from "../../libs/3d/mesh_control";
import ThreeControl from "../../libs/3d/three_control";
import Common from "../../libs/common";
import * as THREE from "three";
// import { toRaw } from '@vue/reactivity';
import MaterialPropertyList from '@/components/MaterialPropertyList.vue';
import SceneProperty from '@/components/SceneProperty.vue';
import DesignTopButtons from '@/components/DesignTopButtons';
import ScriptProperty from '@/components/ScriptProperty.vue';
import LightProperty from '@/components/LightProperty.vue';
import OperationHistory from "../../libs/operation_history"
import ScreenFull from "@/components/ScreenFull.vue";
import LayerTree from "@/components/LayerTree.vue"

// import axios from "axios";
// import Common from "../libs/common";
// import { Realtime } from "../libs/realtime";
// import * as electron from "electron";

const DesignPage = {
    name: "DesignPage",
    components: {
        MaterialPropertyList,
        SceneProperty,
        DesignTopButtons,
        ScriptProperty,
        LightProperty,
        ScreenFull,
        Splitpanes,
        Pane,
        LayerTree
    },
    props: {
        msg: String,
        resUrl: String,
        resInit: Boolean
    },
    data() {
        return {

            toolsList: [
                [{
                        icon: "choose",
                        label: "选择",
                        clickEvent: () => {
                            this.threelib.setSceneToMove()
                        }
                    },
                    {
                        icon: "translation",
                        label: "移动",
                        clickEvent: () => {
                            this.threelib.setObjectToMove()
                        }
                    },
                    {
                        icon: "rotate-view",
                        label: "旋转",
                        clickEvent: () => {
                            this.threelib.setObjectToRotate()
                        }
                    },
                    {
                        icon: "zoom",
                        label: "缩放",
                        clickEvent: () => {
                            this.threelib.setObjectToScale()
                        }
                    },
                ],
                [{
                        icon: "sun",
                        label: "全局光",
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    },
                    {
                        icon: "volume",
                        label: "声音",
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    },
                    {
                        icon: "image",
                        label: "背景",
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    }
                ],
                [{
                        icon: "copy",
                        label: "复制",
                        disabled:true,
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    },
                    {
                        icon: "angle",
                        label: "角度捕获",
                        disabled:true,
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    },
                    {
                        icon: "align-center",
                        label: "对齐",
                        disabled:true,
                        isShowMore:false,
                        children:[{
                            icon: "align-right",
                        },{
                            icon: "align-center",
                        },{
                            icon: "align-left",
                        },{
                            icon: "align-top",
                        },{
                            icon: "vlign-center",
                        },{
                            icon: "align-bottom",
                        }],
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    },
                    {
                        icon: "adsorb",
                        label: "吸附",
                        disabled:true,
                        clickEvent: () => {
                            // this.threelib.setObjectToScale()
                        }
                    }
                ]
            ],
            activeTool: "场景旋转",

            activeTab: "组件",

            componentKey: "",

            activeCate: "场景",

            resList: [],
            sceneList: [{
                    src: "useredit/2022-4-14 14580.jpg",
                    label: "厂区"
                },
                //  {
                //     src: "useredit/2022-3-28 111747.jpg",
                //     label: "泡面碗",
                // }
            ],

            modelList: [{
                src: "useredit/2022-4-14 16225.jpg",
                label: "车间一",
            }, {
                src: "useredit/2022-3-28 12531.jpg",
                label: "旧款水杯",
            }],

            isShowLeft: true,

            isShowRight: true,
            isScreenfull: false,

            pureColor: "white",
            gradientColor: "white",
            meshTreeReplaceFields: {
                children: "children",
                title: "name",
                key: "id",
            },
            mesh_props: {
                label: "name",
                children: "children",
            },
            //showTab: 0,
            materials: [{
                title: "color"
            }],
            emptyNodeText: "模型列表为空",
            // meshNode: [],
            selectTopNode: null,
            meshNodes: [],
            nodelSelected: [],
            nodeTicked: [],
            nodeExpanded: ["0"],
            // statusHub: null,
            // realtime: new Realtime(),
            // loginKey: null,
            // faceLoginKey: null,
            threelib: new Threelib(),
            splitLeft: 0.2,
            splitRight: 0.8,
            isLoading: false,
            meshControl: new MeshControl(),
            threeControl: new ThreeControl(),
            // defaultMaterialType: "THREE.MeshPhongMaterial",
            isSavingScene: false,
            initBind: null,
            THREE: THREE,
            isLoadingInQueue: false,
            loadQueue: [],
            mainKey: +new Date(),
            isLoadingRes: false,
        };
    },
    async mounted() {
        // this.resInit = true;
        // this.initEvents();
        // if (this.isLoadingInQueue) {
        //     this.addToInvokeQueue(this.init)
        // }
        // else {
        //     if (this.resUrl == null) {
        //         let scope = this
        //         setTimeout(async () => {
        //             await scope.init()
        //         }, 500)
        //     }
        // }
        this.resList = this.sceneList
        await this.init();
    },
    beforeUnmount() {
        if (this.threelib) {
            this.threelib.destroyThreeScene();
        }
        this.disposeEvents();
    },

    watch: {
        // "resInit": {
        //     handler(newValue) {
        //         if (newValue) {
        //             setTimeout(async () => {
        //                 await this.init();
        //             }, 500)
        //         }
        //     },
        //     immediate: true
        // },
        "isScreenfull": {
            handler() {
                this.resizeForScreenFull()
            }
        },
        "resUrl": {
            async handler(newValue) {
                if (newValue != null && newValue != "") {
                    if (this.isLoadingInQueue) {
                        this.addToInvokeQueue(this.init)
                    } else {
                        await this.init();
                    }
                }
            }
            // deep: true
        },
    },

    methods: {
        toggleTool(item){
            item.isShowMore = !item.isShowMore
        },
        checkedAlign(item,child){
          item.icon = child.icon
        },
        handleTools(item) {
            this.activeTool = item.label;
            item.clickEvent()
        },
        handleTab(item) {
            this.activeTab = item;
        },
        searchComponent() {},
        handleCate(item, list) {
            this.activeCate = item;
            this.resList = list;
        },

        resizeForLeftSide() {
            this.$nextTick(() => {
                let sideWidth = this.$refs.leftSide.clientWidth
                this.threelib.setRendererOffsetSize(this.isShowLeft ? -sideWidth : sideWidth, 0);
            })
        },

        resizeForRightSide() {
            this.$nextTick(() => {
                let sideWidth = this.$refs.rightSide.clientWidth
                this.threelib.setRendererOffsetSize(this.isShowRight ? -sideWidth : sideWidth, 0);
            })
        },

        toggleLeftSide() {
            this.isShowLeft = !this.isShowLeft;
            this.mainKey = +new Date();
            this.resizeForLeftSide();
        },

        toggleRightSide() {
            this.isShowRight = !this.isShowRight;
            this.mainKey = +new Date();
            this.resizeForRightSide();
        },

        addToInvokeQueue() {
            let scope = this
            scope.loadQueue.push(async () => {
                if (scope.loadQueue.length > 0) {
                    let func = scope.loadQueue.shift();
                    if (func) {
                        await func()
                    }
                } else {
                    scope.isLoadingInQueue = false
                }
            })
        },

        disposeEvents() {
            // window.removeEventListener("load", this.initBind);
        },


        resizeForScreenFull() {
            if (this.isScreenfull) {
                this.$nextTick(() => {
                    this.threelib.setRendererMaxSize();
                });
            } else {
                this.resizeForLeftSide();
                this.resizeForRightSide();
            }
        },

        initEvents() {
            // this.initBind = this.init.bind(this);
            // window.addEventListener("load", this.initBind);
        },

        async changeMaterialType(e) {
            let topMesh = this.meshControl.getTopMesh(this.meshControl.selectObject3d);
            let groupMeshes = this.threelib.getSameGroupMeshes(topMesh);
            this.meshControl.changeMaterialType(groupMeshes, e);
            await this.setMaterial(this.meshControl.editMaterial);
        },

        getSelectMeshNode(node) {
            if (node) {
                if (node.parentId === '0') {
                    return node;
                }
                for (let i = 0; i < this.meshNodes.length; i++) {
                    let meshNode = this.meshNodes[i];
                    if (meshNode.children) {
                        for (let j = 0; j < meshNode.children.length; j++) {
                            let child = meshNode.children[j];
                            if (child.parentId === '0') {
                                return child;
                            }
                        }
                    }
                }
            }
            return node;
        },

        /* eslint-disable */
        meshNodeSelect(e, meshNode) {

            if (meshNode) {
                // meshNode = this.getSelectMeshNode(meshNode);
                this.selectTopNode = Common.getNodeAttr(meshNode);
                let object3d = this.selectTopNode.mesh;
                if (object3d) {
                    if (!object3d.isCamera) {
                        this.nodeSelect([meshNode.eventKey], meshNode);
                        this.meshControl.selectObject3d = object3d;
                        this.threelib.selectObjectAndSetXYZCoordnate();
                    }
                }
            }
        },
        /* eslint-enable */

        changeSelectObject3dVisible($event) {
            if (this.meshControl.selectObject3d) {
                let checked = $event.length > 0 && $event[0]
                this.meshControl.selectObject3d.visible = checked
            }
        },

        getSelectObject3dVisible() {
            return this.meshControl.selectObject3d == null ||
                this.meshControl.selectObject3d.visible === true;
        },

        nodeSelect(selectedKeys, meshNode) {
            if (meshNode.children && meshNode.children.length > 0) {
                let mTopNode = Common.getNodeAttr(meshNode);
                if (mTopNode.id === selectedKeys[0]) {
                    this.selectMeshMaterials(mTopNode.mesh);
                } else {
                    for (let i = 0; i < meshNode.children.length; i++) {
                        let mn = meshNode.children[i];
                        let mNode = Common.getNodeAttr(mn);
                        if (mNode.id === selectedKeys[0]) {
                            this.selectMeshMaterials(mNode.mesh);
                            return;
                        } else {
                            this.nodeSelect(selectedKeys, mn);
                        }
                    }
                }
            } else {
                let node = Common.getNodeAttr(meshNode);
                if (node.mesh && node.id === selectedKeys[0]) {
                    this.selectMeshMaterials(node.mesh);
                }
            }
        },

        selectMeshMaterials(mesh) {
            if (mesh) {
                this.meshControl.selectObject3d = mesh;
                let isMustChangeMaterialType = false;
                if (!mesh.material && !mesh.children) {
                    mesh.material = new THREE.MeshPhysicalMaterial();
                    isMustChangeMaterialType = true;
                }
                if (mesh.material) {
                    if (mesh.material.length && mesh.material.length > 0) {
                        this.meshControl.selectMaterials = mesh.material;
                        for (let i = 0; i < mesh.material.length; i++) {
                            if (this.setMaterial(mesh.material[i], !mesh.parent || !mesh.parent.isLight)) {
                                break;
                            }
                        }
                    } else {
                        this.setMaterial(mesh.material, !mesh.parent || !mesh.parent.isLight);
                        this.meshControl.selectMaterials = [mesh.material];
                    }
                    if (isMustChangeMaterialType) {
                        this.changeMaterialType("THREE.MeshPhysicalMaterial"); //Debug
                    }
                }
            }
        },

        onRightClick(event) {

            if (this.$refs.topControl) {
                const postition = {
                    top: event.y,
                    left: event.x
                };
                let menu = this.$refs.contextmenu;
                menu.show(postition);
            }
        },

        async init(delayms = 0) {

            if (this.isInited) {
                this.meshNodes = [];
                // this.threelib.destroyThreeScene();
                // return;
            }

            this.isInited = true;

            let scope = this;

            let initLoad = async () => {
                // this.realtime.init(null, this);
                scope.meshNodes = [];
                OperationHistory.bindEvents()
                scope.threelib.selectMeshMaterials = scope.selectMeshMaterials.bind(scope);
                scope.threelib.operationObjectEvent = scope.operationObjectInvoke.bind(scope);
                let axis = await scope.threelib.addModelFromUrl("./models/axis.mod", false, false, false);
                await scope.threelib.init(scope.$refs.container, scope.meshControl, true, axis.mesh);

                scope.threelib.setPasteEvent(scope.$refs.topControl.cloneObject3D);

                let mainLight = scope.addDirectionLight(null, "MainDirectionLight", new THREE.Color(1, 1, 1), 1, true, false)
                mainLight.colorValue = "#ffffff"
                mainLight.position.y = 0
                mainLight.scale.y = 1
                scope.threelib.mainLight = mainLight


                let camera = scope.threelib.getCamera()
                camera.add(mainLight)
                let cameraMeshNames = scope.threeControl.getMeshNames(camera)
                scope.meshNodes.push(cameraMeshNames)
                scope.meshControl.selectObject3d = null


                scope.$refs.container.removeEventListener("dragover", scope.dragover)
                scope.$refs.container.addEventListener("dragover", scope.dragover)
                scope.$refs.container.removeEventListener("drop", scope.dropFile)
                scope.$refs.container.addEventListener("drop", scope.dropFile)


                if (scope.resUrl != null && scope.resUrl != "") {
                    let file = await Common.getFileFromUrl(scope.resUrl, "tmpModFile.mod");
                    await scope.loadResFile(file)
                }
            }

            if (delayms > 0) {
                setTimeout(() => {
                    initLoad();
                }, delayms);
            } else {
                initLoad();
            }
            // let topLight = this.addDirectionLight(null, "topLight", new THREE.Color(1, 1, 1), 1)
            // topLight.position.y = 20
            // this.initFile();
            // await threelib.addModelFromBuffer("./model/VP_Free_BMW_8.fbx");
            console.log("meshNodes:", this.meshNodes)
        },

        dragover(ev) {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = "move"
        },

        operationObjectInvoke(operationType) {
            if (operationType === -1) {
                this.activeTool = "场景旋转";
            } else if (operationType === 0) {
                this.activeTool = "平移";
            } else if (operationType === 1) {
                this.activeTool = "旋转";
            } else if (operationType === 2) {
                this.activeTool = "缩放";
            }
        },

        /* eslint-disable */
        dropTreeNode(ev) {
            let dragNode = ev.dragNode
            let node = ev.node

            let realToNode = Common.getNodeAttr(node)
            let realDragNode = Common.getNodeAttr(dragNode)
            realToNode.mesh.attach(realDragNode.mesh)

            let oldParentId = realDragNode.parentId
            // realDragNode.parentId = realToNode.id
            let parentChildren = dragNode.$parent.$children
            for (let i = 0; i < parentChildren.length; i++) {
                if (parentChildren[i] === dragNode) {
                    parentChildren.splice(i, 1)
                    break
                }
            }
            let toChildren = node.$children
            toChildren.push(dragNode)

            let dragMeshNode = this.setNewMeshNodes(this.meshNodes, realToNode, realDragNode, oldParentId, ev.dropToGap, 0)
            this.setNewMeshNodes(this.meshNodes, realToNode, realDragNode, oldParentId, ev.dropToGap, 1)
            this.setNewMeshNodes(this.meshNodes, realToNode, realDragNode, oldParentId, ev.dropToGap, 2)

            // if (oldParentId === '0') {
            //     for (let i = 0; i < this.meshNodes.length; i++) {
            //         if (this.meshNodes[i].id === realDragNode.id) {
            //             this.meshNodes.splice(i, 1)
            //             break
            //         }
            //     }
            //     // if (realDragNode.parentId === "0") {
            //     //     alert(123132)
            //     //     alert(realToNode.id)
            //     //     alert(realToNode.parentId)
            //     //     this.meshNodes.push(realDragNode)
            //     // }
            // }

            if (dragMeshNode) {
                dragMeshNode.parentId = realToNode.id
            }
            // console.log(this.meshNodes)
        },
        /* eslint-enable */

        setNewMeshNodes(nodes, realToNode, realDragNode, oldParentId, dropToGap, operType) {

            let dragMeshNode = null

            let isFinded = false
            for (let i = 0; i < nodes.length; i++) {
                let meshNode = nodes[i]

                if (isFinded) {
                    break
                }

                if (!meshNode.children) {
                    meshNode.children = []
                }
                if (operType === 0) {
                    if (meshNode.id === realDragNode.id) {
                        dragMeshNode = meshNode
                    } else {
                        dragMeshNode = this.setNewMeshNodes(meshNode.children, realToNode, realDragNode, oldParentId, dropToGap, operType)
                    }
                    isFinded = dragMeshNode != null
                } else if (operType === 1) {
                    // let isFindOldParentNode = meshNode.id === oldParentId || meshNode.id === "top_" + oldParentId.split('_')[0]
                    if (meshNode.id === realDragNode.id) {
                        nodes.splice(i, 1)
                        isFinded = true
                    } else {
                        this.setNewMeshNodes(meshNode.children, realToNode, realDragNode, oldParentId, dropToGap, operType)
                    }
                } else if (operType === 2) {

                    // if (realToNode.parentId === "0") {
                    if (dropToGap) {
                        if (meshNode.id === realToNode.id) {
                            nodes.push(realDragNode)
                            isFinded = true
                        }
                    } else {
                        realToNode.children.push(realDragNode)
                        isFinded = true
                    }
                    if (!isFinded) {
                        this.setNewMeshNodes(meshNode.children, realToNode, realDragNode, oldParentId, dropToGap, operType)
                    }
                }
            }

            return dragMeshNode
        },

        async dropFile(ev) {
            ev.stopPropagation();
            ev.preventDefault();
            let file = null;
            let files = ev.dataTransfer.files
            if (files && files.length > 0) {
                file = files[0]
            }
            if (!file) {
                let src = ev.dataTransfer.getData("text").replace(".jpg", ".mod")
                if (src && src !== "") {
                    file = await Common.getFileFromUrl(src, "tmpModFile.mod");
                }
            } else {
                let name = file.name;
                if (name == null) {
                    name = "";
                }
                name = name.toLowerCase();
                if (name.indexOf('.mod') === -1 && name.indexOf('.modjson') === -1 && name.indexOf('.fbx') === -1) {
                    let src = ev.dataTransfer.getData("text").replace(".jpg", ".mod")
                    file = await Common.getFileFromUrl(src, "tmpModFile.mod");
                }
            }

            if (file) {
                await this.loadResFile(file)
            }
        },

        async loadResFromSrc(src) {

            if (this.isLoadingRes) {
                return;
            }
            this.isLoadingRes = true;
            if (src == null) {
                src = "";
            }
            src = src.toLowerCase();
            src = src.replace(".jpg", ".mod")
            let file = await Common.getFileFromUrl(src, "tmpModFile.mod");
            await this.loadResFile(file)
            this.isLoadingRes = false;
        },

        async loadResFile(file) {
            let info = {
                file: {
                    originFileObj: file,
                    name: file.name
                }
            }
            let name = file.name;
            name = name.toLowerCase();
            if (name.indexOf('.mod') !== -1 || name.indexOf('.modjson') !== -1) {
                await this.$refs.topControl.loadSceneWeb(info)
            } else if (name.indexOf('.fbx') !== -1) {
                await this.$refs.topControl.selectFileWeb(info)
            }
        },

        imgDragStart(ev) {
            let img = ev.target
            ev.dataTransfer.setDragImage(img, img.clientWidth / 2, img.clientHeight / 2);
        },

        async handleAvatarSuccess(res, file) {
            console.log(res);
            console.log(file);
            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        async initFile() {
            // // const fs = this.$remote.require("fs");
            // // const path = require("path");
            // // this.threelib.clearMeshes();
            // // let self = this;
            // // setTimeout(() => {
            // //     let filepath = path.join(self.$public, "model/HDM_06_001.fbx"); //
            // //     console.log(filepath);
            // //     let data = fs.readFileSync(filepath);
            // //     self.threelib.addModelFromBuffer(data.buffer);
            // // }, 1200);

            // this.percent = 0;
            // let self = this;
            // let url =
            //     "./models/axis.mod"; //"./gun.mod";
            // let qIndx = url.indexOf("?");
            // let splitUrls = [""];
            // if (qIndx === -1) {
            //     splitUrls = url.split(".");
            // } else {
            //     splitUrls = url.substring(0, qIndx).split(".");
            // }
            // let ext = "." + splitUrls[splitUrls.length - 1];
            // axios({
            //     method: "get",
            //     url: url,
            //     responseType: "arraybuffer",
            //     // headers: {
            //     //   "Cache-Control": "no-cache",
            //     // },
            //     onDownloadProgress(progress) {
            //         // Math.round((progress.loaded / progress.total) * 100) + "%"
            //         // self.percent = Math.round((progress.loaded / progress.total) * 90);
            //     },
            // }).then(async function (response) {
            //     let data = new Uint8Array(response.data);
            //     console.log(data);
            //     // let slowerlyAddPercent = () => {
            //     //     self.percent = Math.min(100, self.percent + 1);
            //     //     if (self.percent < 99) {
            //     //         setTimeout(slowerlyAddPercent, 500);
            //     //     }
            //     // };
            //     // setTimeout(slowerlyAddPercent, 500);
            // });
        },

        // setMaterial(material, changeTab = true) {
        async setMaterial(material) {
            if (material) {
                // if (changeTab) {
                //     this.showTab = 1;
                // }
                let mat = await this.meshControl.setMaterial(material);
                if (mat == null) {
                    return false;
                } else {
                    this.defaultMaterialType = "THREE." + material.type;
                    this.meshControl.editMaterial = material;
                    this.meshControl.editMaterialValue = mat.properties;
                    // console.log(this.meshControl.editMaterialValue);
                    return true;
                }
            }
            return false;
        },

        setLightNode(light) {
            this.meshControl.selectObject3d = light
            let meshNames = this.threeControl.getMeshNames(light)
            this.meshNodes.push(meshNames)
        },

        addDirectionLight(shadowOption, name = null, color, intensity = 1, isAddToScene = true, isShowNode = true) {
            let light = this.threelib.lightlib.addDirectionLight(shadowOption, color, intensity, isAddToScene)
            if (name != null) {
                light.name = name
            }
            if (isShowNode) {
                this.setLightNode(light)
            }
            return light
        },

        addPointLight(shadowOption, name = null, color, intensity = 1, distance = 0, decay = 1, isAddToScene = true) {
            let light = this.threelib.lightlib.addPointLight(shadowOption, color, intensity, distance, decay, isAddToScene)
            if (name != null) {
                light.name = name
            }
            this.setLightNode(light)
            return light
        },

        addSpotLight(shadowOption, name = null, color, intensity = 1, distance = 0, angle = Math.PI / 3, penumbra = 0, decay = 1, isAddToScene = true) {
            let light = this.threelib.lightlib.addSpotLight(shadowOption, color, intensity, distance, angle, penumbra, decay, isAddToScene)
            if (name != null) {
                light.name = name
            }
            this.setLightNode(light)
            return light
        }
    },
};
export default DesignPage;