import * as THREE from 'three';

import { Line2 } from "../threejslib/lines/Line2"
import { LineMaterial } from "../threejslib/lines/LineMaterial"
import { LineGeometry } from "../threejslib/lines/LineGeometry"

const COORDINATENAME = "{59AEE8B3-D2F2-4F83-B78B-6D70FD30AC95}";

const coordinateName = "coordinateName"
const coordinateAxis = "coordinateAxis"
const coordinateLine = "coordinateLine"

const XYZLine = function (parms) {

    let scene = parms.scene
    // let threeContainer = parms.threeContainer


    let topGroup = null

    this.getGroup = function () {
        return topGroup
    }

    this.setPosition = function (position) {
        topGroup.position.copy(position)
    }
    this.getPosition = function () {
        return topGroup.position
    }

    this.setScale = function (scale) {
        topGroup.scale.set(scale, scale, scale)
    }

    this.setLinesVisible = function (visible) {
        // for (let i = 0; i < allLineObjects.length; i++) {
        //     allLineObjects[i].visible = visible
        // }
        // for (let i = 0; i < allTextObjects.length; i++) {
        //     allTextObjects[i].visible = visible
        // }
        if (topGroup.visible !== visible) {
            topGroup.visible = visible
        }
    }

    this.getCoordinate = function (object3d) {
        if (object3d && object3d.userData[coordinateLine]) {
            let coordinate = object3d.userData[coordinateAxis]
            // if (!coordinate) {
            //     let idx = topGroup.children.indexOf(object3d)
            //     if (idx !== -1) {
            //         let selectObject = topGroup.children[idx]
            //         coordinate = selectObject.userData[coordinateAxis]
            //     }
            // }
            if (coordinate) {
                return coordinate.toLowerCase();
            }
        }
        return null;
    }

    this.removeLines = function () {
        // for (let i = 0; i < allLineObjects.length; i++) {
        //     scene.remove(allLineObjects[i])
        // }
        // for (let i = 0; i < allTextObjects.length; i++) {
        //     scene.remove(allTextObjects[i])
        // }
        if (topGroup) {
            topGroup.removeFromParent()
        }
    }

    this.addLines = function () {
        // for (let i = 0; i < allLineObjects.length; i++) {
        //     scene.remove(allLineObjects[i])
        // }
        // for (let i = 0; i < allTextObjects.length; i++) {
        //     scene.remove(allTextObjects[i])
        // }
        topGroup = new THREE.Group()
        // topGroup.position.set(0, 0, 0)
        // topGroup.rotation.set(0, 0, 0)
        // topGroup.scale.set(1, 1, 1)
        scene.add(topGroup)
    }

    this.drawXYZLine = function (lineLen = 220, letterLen = 150, innerLineWidth = 3, outLineWidth = 3.5) {

        this.setDeselectCricleMaterialEffect()
        beforeSelectLineMats = []
        this.removeLines();
        this.addLines();
        // let lineLen = 220
        // let letterLen = 150
        // let outLineThickness = 5

        //x轴
        let xColor = new THREE.Color(0xFF0000)
        this.drawLine("x",
            lineLen,
            xColor, innerLineWidth, true);
        this.drawLine("x",
            lineLen,
            xColor, outLineWidth, true);
        this.drawText("x", new THREE.Vector3(0, 0, letterLen), xColor.getStyle())
        this.drawText("x", new THREE.Vector3(0, 0, -letterLen), xColor.getStyle())


        //y轴
        let yColor = new THREE.Color(0x00FF00)
        this.drawLine("y",
            lineLen,
            yColor, innerLineWidth, true);
        this.drawLine("y",
            lineLen,
            yColor, outLineWidth, true);
        this.drawText("y", new THREE.Vector3(0, letterLen, 0), yColor.getStyle())
        this.drawText("y", new THREE.Vector3(0, -letterLen, 0), yColor.getStyle())


        //z轴
        let zColor = new THREE.Color(0x0000FF)
        this.drawLine("z",
            lineLen,
            zColor, innerLineWidth, true);
        this.drawLine("z",
            lineLen,
            zColor, outLineWidth, true);
        this.drawText("z", new THREE.Vector3(letterLen, 0, 0), zColor.getStyle())
        this.drawText("z", new THREE.Vector3(-letterLen, 0, 0), zColor.getStyle())
    }


    let beforeSelectLineMats = []
    let xyzLineMats = []
    this.selectLine = function (axis) {
        this.deselectEffect()
        beforeSelectLineMats = []
        if (axis) {
            let mats = xyzLineMats[axis]
            for (let i = 0; i < mats.length; i++) {
                if (mats[i].opacity === 0) {
                    this.setSelectCricleMaterialEffect(mats[i])
                    beforeSelectLineMats.push(mats[i])
                }
            }
        }
    }

    this.deselectEffect = function () {
        for (let i = 0; i < beforeSelectLineMats.length; i++) {
            this.setDeselectCricleMaterialEffect(beforeSelectLineMats[i])
        }
    }

    this.setSelectCricleMaterialEffect = function (mat) {
        if (mat) {
            mat.opacity = 1
            mat.needsUpdate = true
        }
    }

    this.setDeselectCricleMaterialEffect = function (mat) {
        if (mat) {
            mat.opacity = 0
            mat.needsUpdate = true
        }
    }

    this.udpateLineMaterials = function () {
        for (var k in xyzLineMats) {
            let mats = xyzLineMats[k]
            for (let i = 0; i < mats.length; i++) {
                let mat = mats[i]
                if (mat && mat.isLineMaterial)
                    mat.resolution.set(window.innerWidth, window.innerHeight)
            }
        }
    }

    this.setLinesRotation = function (point3dObject) {

        if (point3dObject) {
            let rot = new THREE.Euler(0, 0, 0, point3dObject.rotation.order)
            // let parent = point3dObject
            // while (parent && !parent.isScene) {
            rot.x += point3dObject.rotation.x
            rot.y += point3dObject.rotation.y
            rot.z += point3dObject.rotation.z
            //     parent = parent.parent
            // }

            topGroup.rotateX(-rot.x)
            topGroup.rotateY(-rot.y)
            topGroup.rotateZ(-rot.z)
        }
    }

    this.drawLine = function (text, lineLen, color, outLineWidth = 1, isAlpha = false) {


        // var geometry = new THREE.BufferGeometry()

        // const positions = [];
        // positions.push(start.x, start.y, start.z, end.x, end.y, end.z)
        // const vertices = new Float32Array(positions);
        // geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        // const colors = [];
        // colors.push(color.r, color.g, color.b, color.r, color.g, color.b)
        // geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));


        // let material = null;
        // if (isAlpha) {
        //     material = new THREE.MeshPhongMaterial({ color: color })
        //     material.transparent = true
        //     material.opacity = 0
        // }
        // else {
        //     material = new THREE.MeshBasicMaterial({ color: color })
        // }
        // let geometry = new THREE.CylinderGeometry(outLineWidth, outLineWidth, lineLen)
        // let line = new THREE.Mesh(geometry, material)
        var pointArr = [
            -lineLen, 0, 0,
            lineLen, 0, 0
        ]

        const lineGeometry = new LineGeometry()
        lineGeometry.setPositions(pointArr);
        const lineMaterial1 = new LineMaterial({
            transparent: isAlpha,
            opacity: 0.6,
            color: color,
            linewidth: outLineWidth
        });
        const lineMaterial2 = new LineMaterial({
            transparent: isAlpha,
            opacity: 0,
            color: color,
            linewidth: outLineWidth * 6
        });
        xyzLineMats[text] = [lineMaterial1]
        lineMaterial1.resolution.set(window.innerWidth, window.innerHeight)
        lineMaterial2.resolution.set(window.innerWidth, window.innerHeight)
        const lineInner = new Line2(lineGeometry, lineMaterial1);
        const lineOuter = new Line2(lineGeometry, lineMaterial2);

        let degrees2Radians = Math.PI / 180
        lineInner.userData[coordinateAxis] = text
        lineOuter.userData[coordinateAxis] = text

        let rot = new THREE.Euler(0, 0, 0)
        if (text === "x") {
            // lineInner.rotation.set(0, 0, 0)
            // lineOuter.rotation.set(0, 0, 0)
            rot.set(topGroup.rotation.x, topGroup.rotation.y + 90 * degrees2Radians, topGroup.rotation.z)
        }
        else if (text === "y") {
            rot.set(topGroup.rotation.x, topGroup.rotation.y, topGroup.rotation.z + 90 * degrees2Radians)
        }
        else if (text === "z") {
            rot.set(topGroup.rotation.x + 90 * degrees2Radians, topGroup.rotation.y, topGroup.rotation.z)
        }


        lineInner.rotation.set(rot.x, rot.y, rot.z)
        lineOuter.rotation.set(rot.x, rot.y, rot.z)

        lineInner.userData[coordinateName] = COORDINATENAME
        lineOuter.userData[coordinateName] = COORDINATENAME

        lineInner.userData[coordinateLine] = true
        lineOuter.userData[coordinateLine] = true

        topGroup.userData[coordinateName] = COORDINATENAME
        topGroup.userData[coordinateLine] = true
        topGroup.add(lineInner)
        topGroup.add(lineOuter)
    }

    this.drawText = function (text, position, color) {
        // var gem = new THREE.TextGeometry(text, {
        //     size: 20, //字号大小，一般为大写字母的高度
        //     height: 10, //文字的厚度
        //     weight: 'normal', //值为'normal'或'bold'，表示是否加粗
        //     font: "bolder 16px Arial", //字体，默认是'helvetiker'，需对应引用的字体文件
        //     style: 'normal', //值为'normal'或'italics'，表示是否斜体
        //     bevelThickness: 1, //倒角厚度
        //     bevelSize: 1, //倒角宽度
        //     curveSegments: 30,//弧线分段数，使得文字的曲线更加光滑
        //     bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
        // });
        // gem.center();
        // var mat = new THREE.MeshPhongMaterial({
        //     color: 0xffe502,
        //     specular: 0x009900,
        //     shininess: 30,
        //     shading: THREE.FlatShading
        // });
        // var textObj = new THREE.Mesh(gem, mat);
        // textObj.castShadow = true;
        // textObj.position.x = position.x;
        // textObj.position.y = position.y;
        // textObj.position.z = position.z;
        // this.scene.add(textObj);

        //创建canvas对象用来绘制文字
        // let position = { x: 0, y: 0, z: 0 };
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.fillStyle = color;
        ctx.font = "normal 80px Arial ";

        ctx.fillText(text.toUpperCase(), 70, 70);
        ctx.globalAlpha = 1;

        // 将画布生成的图片作为贴图给精灵使用，并将精灵创建在设定好的位置
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        //创建精灵，将该材质赋予给创建的精灵
        var spriteMaterial = new THREE.PointsMaterial({
            map: texture,
            sizeAttenuation: false,
            size: 60,
            transparent: true,
            opacity: 1,
        });


        //创建坐标点，并将材质给坐标
        var geometry = new THREE.BufferGeometry();
        var vertices = [0, 0, 0];
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        var sprite = new THREE.Points(geometry, spriteMaterial);
        sprite.position.set(position.x, position.y, position.z);
        sprite.userData[coordinateAxis] = text
        sprite.userData[coordinateName] = COORDINATENAME
        sprite.name = COORDINATENAME;
        topGroup.add(sprite);
    }
}

export { XYZLine, COORDINATENAME, coordinateName, coordinateAxis, coordinateLine }