import * as THREE from 'three';
import { StreamerMaterial, StreamerShader } from './materials/streamermaterial';

import { TGALoader } from "../threejslib/TGALoader";
// At the top of your entry point
import Common from "../common";
const MaterialControlTypes = function () {

    this.blendDst = { type: "BlendingDstFactor", name: "blendDst" };
    this.blendDstAlpha = { type: "Slider", name: "blendDstAlpha", min: 0, max: 1 };

    this.blendEquation = { type: "BlendingEquation", name: "blendEquation" };
    this.blendEquationAlpha = { type: "Slider", name: "blendEquationAlpha", min: 0, max: 1 };

    this.blending = { type: "Blending", name: "blending" };
    this.blendSrc = { type: "BlendingSrcFactor", name: "blendSrc" };

    this.blendSrcAlpha = { type: "Slider", name: "blendSrcAlpha", min: 0, max: 1 };

    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
};

const MeshBasicMaterialControlTypes = function () {

    this.alphaTest = { type: "Number", name: "alphaTest", min: 0 };
    this.alphaToCoverage = { type: "Boolean", name: "alphaToCoverage" };


    this.opacity = { type: "Slider", name: 'opacity', min: 0, max: 1 };
    this.transparent = { type: "Boolean", name: 'transparent' };

    this.color = { type: "Color", name: 'color' };
    this.map = { type: "Texture", name: 'map' };
    this.lightMap = { type: "Texture", name: 'lightMap' };
    this.lightMapIntensity = { type: "Number", name: 'lightMapIntensity', min: 0 };
    this.aoMap = { type: "Texture", name: 'aoMap' };
    this.aoMapIntensity = { type: "Number", name: 'aoMapIntensity', min: 0 };
    this.specularMap = { type: "Texture", name: 'specularMap' };
    this.alphaMap = { type: "Texture", name: 'alphaMap' };
    this.envMap = { type: "Texture", name: 'envMap' };
    this.envMapIntensity = { type: "Slider", name: 'envMapIntensity', min: 0, max: 10 };
    this.reflectivity = { type: "Slider", name: 'reflectivity', min: 0, max: 1 };
    this.refractionRatio = { type: "Slider", name: 'refractionRatio', min: 0, max: 1 };
    this.wireframe = { type: "Boolean", name: 'wireframe' };
    this.wireframeLinewidth = { type: "Number", name: 'wireframeLinewidth', min: 0 };
    /**
     * 定义线两端的外观。可选值为 'butt'，'round' 和 'square'。默认为'round'。
     */
    this.wireframeLinecap = { type: "String", name: 'wireframeLinecap' };
    /**
     * 定义线连接节点的样式。可选值为 'round', 'bevel' 和 'miter'。默认值为 'round'。
     */
    this.wireframeLinejoin = { type: "String", name: 'wireframeLinejoin' };


    MaterialControlTypes.call(this);
    this.combine = { type: "Combine", name: 'combine' };

    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
};

const MeshStandardMaterialControlTypes = function () {

    this.color = { type: "Color", name: 'color' };

    this.roughness = { type: "Slider", name: 'roughness', min: 0, max: 1 };
    this.metalness = { type: "Slider", name: 'metalness', min: 0, max: 1 };

    this.map = { type: "Texture", name: 'map' };

    this.emissive = { type: "Color", name: 'emissive' };
    this.emissiveIntensity = { type: "Number", name: 'emissiveIntensity', min: 0 };
    this.emissiveMap = { type: "Texture", name: 'emissiveMap' };
    this.bumpMap = { type: "Texture", name: 'bumpMap' };
    this.bumpScale = { type: "Number", name: 'bumpScale', min: 0 };
    this.normalMap = { type: "Texture", name: 'normalMap' };
    this.normalMapType = { type: "NormalMapTypes", name: 'normalMapType' };
    this.normalScale = { type: "Vector2", name: 'normalScale', value: { x: 1, y: 1 } };

    this.opacity = { type: "Slider", name: 'opacity', min: 0, max: 1 };
    this.transparent = { type: "Boolean", name: 'transparent' };

    this.lightMap = { type: "Texture", name: 'lightMap' };
    this.lightMapIntensity = { type: "Number", name: 'lightMapIntensity', min: 0 };
    this.aoMap = { type: "Texture", name: 'aoMap' };
    this.aoMapIntensity = { type: "Number", name: 'aoMapIntensity', min: 0 };
    this.specularMap = { type: "Texture", name: 'specularMap' };

    this.roughnessMap = { type: "Texture", name: 'roughnessMap' };
    this.metalnessMap = { type: "Texture", name: 'metalnessMap' };

    this.alphaMap = { type: "Texture", name: 'alphaMap' };
    this.envMap = { type: "Texture", name: 'envMap' };
    this.envMapIntensity = { type: "Slider", name: 'envMapIntensity', min: 0, max: 10 };
    this.reflectivity = { type: "Slider", name: 'reflectivity', min: 0, max: 1 };
    this.refractionRatio = { type: "Slider", name: 'refractionRatio', min: 0, max: 1 };
    this.wireframe = { type: "Boolean", name: 'wireframe' };
    this.wireframeLinewidth = { type: "Number", name: 'wireframeLinewidth', min: 0 };
    /**
     * 定义线两端的外观。可选值为 'butt'，'round' 和 'square'。默认为'round'。
     */
    this.wireframeLinecap = { type: "String", name: 'wireframeLinecap' };
    /**
     * 定义线连接节点的样式。可选值为 'round', 'bevel' 和 'miter'。默认值为 'round'。
     */
    this.wireframeLinejoin = { type: "String", name: 'wireframeLinejoin' };


    this.clearcoat = { type: "Slider", name: 'clearcoat', min: 0, max: 1 };
    this.clearcoatMap = { type: "Texture", name: 'clearcoatMap' };
    this.clearcoatRoughness = { type: "Slider", name: 'clearcoatRoughness', min: 0, max: 1 };
    this.clearcoatRoughnessMap = { type: "Texture", name: 'clearcoatRoughnessMap' };
    this.clearcoatNormalScale = { type: "Vector2", name: 'clearcoatNormalScale', value: { x: 1, y: 1 } };
    this.clearcoatNormalMap = { type: "Texture", name: 'clearcoatNormalMap' };

    this.reflectivity = { type: "Number", name: 'reflectivity', min: 0 };
    this.ior = { type: "Number", name: 'ior', min: 0 };

    this.sheen = { type: "Number", name: 'sheen', min: 0 };
    this.sheenColor = { type: "Color", name: 'sheenColor' };
    this.sheenColorMap = { type: "Texture", name: 'sheenColorMap' };
    this.sheenRoughness = { type: "Number", name: 'sheenRoughness', min: 0 };
    this.sheenRoughnessMap = { type: "Texture", name: 'sheenRoughnessMap' };

    this.transmission = { type: "Number", name: 'transmission', min: 0 };
    this.transmissionMap = { type: "Texture", name: 'transmissionMap' };

    this.thickness = { type: "Number", name: 'thickness', min: 0 };
    this.thicknessMap = { type: "Texture", name: 'thicknessMap' };

    this.attenuationDistance = { type: "Number", name: 'attenuationDistance', min: 0 };
    this.attenuationColor = { type: "Color", name: 'attenuationColor' };

    this.specularIntensity = { type: "Number", name: 'specularIntensity', min: 0 };
    this.specularColor = { type: "Color", name: 'specularColor' };

    this.specularIntensityMap = { type: "Texture", name: 'specularIntensityMap' };
    this.specularColorMap = { type: "Texture", name: 'specularColorMap' };


    MaterialControlTypes.call(this);
    this.combine = { type: "Combine", name: 'combine' };

    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
}

const MeshPhysicalMaterialControlTypes = function () {

    MeshStandardMaterialControlTypes.call(this);
    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
}

const StreamerMaterialControlTypes = function () {

    this.speed = { type: "Number", name: 'speed', value: 1.0 };
    this.fogDensity = { type: "Number", name: 'fogDensity', value: 0.45 };
    this.fogColor = { type: "Color", name: 'fogColor', value: "#FFFF00" };
    this.time = { type: "Number", name: 'time', value: 1.0 };
    this.uvScale = { type: "Vector2", name: 'uvScale', value: { x: 10.0, y: 0.1 } };
    this.texture1 = { type: "Texture", name: 'texture1' };
    this.texture2 = { type: "Texture", name: 'texture2' };

    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
}

const MeshLambertMaterialControlTypes = function () {

    MeshBasicMaterialControlTypes.call(this);

    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
}

const MeshPhongMaterialControlTypes = function () {

    this.specular = { type: "Color", name: 'specular' };
    this.shininess = { type: "Number", name: 'shininess', min: 0 };
    this.opacity = { type: "Slider", name: 'opacity', min: 0, max: 1 };
    this.transparent = { type: "Boolean", name: 'transparent' };
    this.emissive = { type: "Color", name: 'emissive' };
    this.emissiveIntensity = { type: "Number", name: 'emissiveIntensity', min: 0 };
    this.emissiveMap = { type: "Texture", name: 'emissiveMap' };
    this.bumpMap = { type: "Texture", name: 'bumpMap' };
    this.bumpScale = { type: "Number", name: 'bumpScale', min: 0 };
    this.normalMap = { type: "Texture", name: 'normalMap' };
    this.normalMapType = { type: "NormalMapTypes", name: 'normalMapType' };
    this.normalScale = { type: "Vector2", name: 'normalScale', value: { x: 1, y: 1 } };
    this.displacementMap = { type: "Texture", name: 'displacementMap' };
    this.displacementScale = { type: "Number", name: 'displacementScale', min: 0 };
    this.displacementBias = { type: "Number", name: 'displacementBias' };

    this.flatShading = { type: "Boolean", name: 'flatShading' };

    MeshBasicMaterialControlTypes.call(this);
    let self = this;
    this.types = function () {
        let tps = [];
        for (let p in self) {
            if (typeof p !== "function" && p != this) {
                tps.push(eval("self." + p));
            }
        }
        return tps;
    }()
};

const MaterialType = function () {

    // this.addExtTypes = function (base, ext) {
    //     for (let p in ext) {
    //         eval('base.' + p + '=ext.' + p);
    //     }
    // }

    // new THREE.MeshPhongMaterial()
    this.meshBasicMaterialControl = { materialType: "MeshBasicMaterial", properties: new MeshBasicMaterialControlTypes() };

    this.meshPhongMaterialControl = { materialType: "MeshPhongMaterial", properties: new MeshPhongMaterialControlTypes() };

    this.meshPhysicalMaterialControl = { materialType: "MeshPhysicalMaterial", properties: new MeshPhysicalMaterialControlTypes() };

    this.meshLambertMaterialControl = { materialType: "MeshLambertMaterial", properties: new MeshLambertMaterialControlTypes() };

    this.StreamerMaterialControl = { materialType: "StreamerMaterial", properties: new StreamerMaterialControlTypes() }
}


const MeshControl = function () {

    this.THREE = THREE
    // this.selectTopObject3d = { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, scale: { x: 0, y: 0, z: 0 } }
    this.selectObject3d = null
    this.selectMaterials = []
    this.editMaterial = { side: THREE.FrontSide }
    this.editMaterialValue = { types: [] }
    this.selectMaterialType = "THREE.MeshPhysicalMaterial";

    this.initParmeters = function () {
        // this.selectTopObject3d = { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, scale: { x: 0, y: 0, z: 0 } }
        this.selectObject3d = null
        this.selectMaterials = []
        this.editMaterial = { side: THREE.FrontSide }
        this.editMaterialValue = { types: [] }
    }

    /* eslint-disable */
    this.setMaterial = async function (material) {
        let newMatType = null;
        let materialType = new MaterialType();
        for (let c in materialType) {
            if (eval("materialType." + c + ".materialType === material.type")) {
                eval("newMatType = materialType." + c)
                if (THREE[material.type]) {
                    this.selectMaterialType = `THREE.${material.type}`
                }
                else {
                    this.selectMaterialType = `${material.type}`
                }
                break;
            }
        }
        if (newMatType) {

            // this.isProxy = isProxy
            // this.setProxyProperty = function (obj, proxy) {
            //     for (let p in proxy) {
            //         eval(`obj.${p} = proxy.${p}`)
            //     }
            // }
            let pureMat = material
            // if (this.isProxy(material)) {
            //     pureMat = {};
            //     this.setProxyProperty(pureMat, material);
            // }
            // else {
            //     pureMat = material;
            // }
            for (let m in pureMat) {

                if (m === "type" || newMatType.properties[m] == null) {
                    continue
                }

                // eval(`
                // // if (this.isProxy(pureMat.${m})){
                // //     let o = {}
                // //     this.setProxyProperty(o,pureMat.${m})
                // //     pureMat.${m} = o
                // // }
                if (pureMat[m] instanceof THREE.Color) {
                    newMatType.properties[m].colorValue = '#' + pureMat[m].getHexString()
                }
                else if (pureMat[m] instanceof THREE.Texture && pureMat[m].image) {
                    newMatType.properties[m].url = pureMat[m].image.src
                    newMatType.properties[m].thumbnail = await Common.getRedrawUrl(pureMat[m].image.src, 60)
                }
                else {
                    newMatType.properties[m].value = pureMat[m]
                }
                // `)
            }
            if (pureMat.uniforms) {
                let uniforms = pureMat.uniforms
                for (let m in uniforms) {
                    if (m === "type" || newMatType.properties[m] == null) {
                        continue
                    }
                    if (uniforms[m].value instanceof THREE.Color) {
                        newMatType.properties[m].colorValue = '#' + uniforms[m].value.getHexString()
                    }
                    else if (uniforms[m].value instanceof THREE.Texture && uniforms[m].value.image) {
                        newMatType.properties[m].url = uniforms[m].value.image.src
                        newMatType.properties[m].thumbnail = await Common.getRedrawUrl(uniforms[m].value.image.src, 60)
                    }
                    else {
                        newMatType.properties[m].value = uniforms[m].value
                    }
                }
            }
        }
        return newMatType;
    }

    this.getTopMesh = function (mesh) {
        if (mesh.parent) {
            if (mesh.parent.type === "Group" || mesh.parent.type === "Mesh") {
                return this.getTopMesh(mesh.parent);
            }
        }
        return mesh;
    }

    this.setSelectMeshScript = function (script) {
        if (this.selectObject3d) {
            this.selectObject3d.script = `function(gameObject){
                ${script}
            }`
        }
    }

    this.changeMaterialType = function (changeMeshes, materialType) {

        if (this.selectObject3d && this.editMaterial) {

            let newMat = {}
            let self = this
            if (materialType === "StreamerMaterial") {
                let lgshader = StreamerShader;

                // lgshader.uniforms['texture1'].value = new THREE.TextureLoader().load(require("../../assets/sprites/cloud.png"));
                // lgshader.uniforms['texture2'].value = new THREE.TextureLoader().load(require("../../assets/sprites/lavatile.jpg"));

                newMat = new StreamerMaterial({
                    uniforms: lgshader.uniforms,
                    vertexShader: lgshader.vertexShader,
                    fragmentShader: lgshader.fragmentShader
                });
            } else {
                eval(`newMat = new self.${materialType}()`);
            }
            this.selectMaterialType = materialType;
            // let defineMatTypes = new MaterialType()
            // let matPros = {}
            // for (let t in defineMatTypes) {
            //     let matType = eval(`defineMatTypes.${t}`)
            //     if (matType.materialType === newMat.type) {
            //         matPros = matType.properties
            //         break;
            //     }
            // }
            for (let p in this.editMaterial) {
                if (p != "type" && p != "uuid") {
                    if (eval(`self.editMaterial.hasOwnProperty('${p}')`)) {
                        if (p.indexOf('_') != 0) { //filter private var
                            if (eval(`newMat.hasOwnProperty('${p}')`)) {
                                eval(`newMat.${p}=self.editMaterial.${p}`)
                            }
                        }
                    }
                }
            }
            //this.selectTopObject3d
            if (changeMeshes) {
                for (let i = 0; i < changeMeshes.length; i++) {
                    let allTopMesh = this.getTopMesh(changeMeshes[i])
                    if (allTopMesh.traverse) {
                        //更改所有网格相匹配的材质
                        allTopMesh.traverse((obj) => {
                            if (obj.material) {
                                if (obj.material.length) {
                                    for (let i = 0; i < obj.material.length; i++) {
                                        if (obj.material[i].uuid === self.editMaterial.uuid) {
                                            obj.material[i] = newMat
                                            // break
                                        }
                                    }
                                } else {
                                    if (obj.material.uuid === self.editMaterial.uuid) {
                                        obj.material = newMat
                                    }
                                }
                                if (obj == self.selectObject3d) {
                                    self.selectMaterials = obj.material.length ? obj.material : [obj.material];
                                }
                            }
                        })
                    }
                }
            }
            if (changeMeshes) {
                this.editMaterial = newMat
                return newMat
            }
        }
        return null
    }

    this.changeObjectColor = function (e, obj) {
        // requestAnimationFrame(() => {
        obj.colorValue = e.target.value;
        let color = new THREE.Color(obj.colorValue);
        color.convertSRGBToLinear();
        obj.color = color;
        // })
    }

    this.changeMaterialColor = function (e, pro) {
        if (this.editMaterial) {
            let self = this;
            requestAnimationFrame(() => {
                pro.colorValue = e.target.value
                let color = new THREE.Color(e.target.value);
                // eval("self.editMaterial." + pro.name + "=color");
                self.setRealMatProperty(pro.name, color);
            })
        }
    }

    let checkChangeNumber = function (val) {

        if (typeof (val) === "string") {
            let floatVal = parseFloat(val)
            if (!isNaN(floatVal)) {
                val = floatVal
            }
        }
        return val;
    }

    this.changeMaterialPropertyValue = function (e, proName) {
        if (this.editMaterial) {
            let self = this;
            // requestAnimationFrame(() => {
            let val = e;
            if (e.target) {
                val = e.target.value;
            }
            val = checkChangeNumber(val);
            // eval("console.log(self.editMaterial." + proName+")");
            // eval("self.editMaterial." + proName + "=val");
            this.setRealMatProperty(proName, val);
            self.editMaterial.needsUpdate = true;
            // })
        }
    }

    this.changeMaterialSide = function (side) {
        if (this.editMaterial) {
            this.editMaterial.side = side;
        }
    }

    this.changeMaterialXValue = function (e, proName) {
        if (this.editMaterial) {
            let val = e.target.value;
            // eval("this.editMaterial." + proName + ".x=val");
            val = checkChangeNumber(val);
            this.setRealMatProperty(proName, val, "x");
        }
    }

    this.changeMaterialYValue = function (e, proName) {
        if (this.editMaterial) {
            let val = e.target.value;
            // eval("this.editMaterial." + proName + ".y=val");
            val = checkChangeNumber(val);
            this.setRealMatProperty(proName, val, "y");
        }
    }

    this.changeSelectMeshPropertyValue = function (value, proName) {
        if (this.selectObject3d) {
            // eval("this.selectObject3d." + proName + "=value");
            this.setRealMatProperty(proName, value);
        }
    }

    this.getMeshRotationAngle = function (angle) {
        if (angle == null || angle === "") {
            return 0
        }
        if (!isNaN(angle)) {
            return angle
        }
    }

    this.changeSelectObject3dRotationValue = function (e, proName) {
        if (this.selectObject3d) {
            let value = e.target.value;
            // eval("this.selectObject3d." + proName + "=0");
            // return;
            if (value != null && value !== "") {
                value = checkChangeNumber(value);
                if (!isNaN(value)) {
                    let rotationValue = (value * Math.PI) / 180;
                    // eval("this.selectObject3d.rotation." + proName + "=rotationValue");
                    this.setRealMatProperty("rotation", rotationValue, proName);
                }
                else {
                    // eval("this.selectObject3d.rotation.angle" + proName + "=value");
                    this.setRealMatProperty("rotation", value, "angle");
                }
            }
        }
    }


    this.changeMaterialBooleanValue = function (checked, proName) {
        if (this.editMaterial) {
            let self = this;
            requestAnimationFrame(() => {
                // eval("self.editMaterial." + proName + "=checked")
                this.setRealMatProperty(proName, checked);
                self.editMaterial.needsUpdate = true
            })
        }
    }
    this.changeMaterialPropertyToNullForDelete = function (item) {
        if (this.editMaterial) {
            // eval("this.editMaterial." + item.name + "=null")
            this.setRealMatProperty(item.name, null);
            this.editMaterial.needsUpdate = true
            item.url = null
            item.thumbnail = null;
        }
    }


    this.textureVideos = [];
    this.textureVideoSrcs = [];
    this.setTextureVideosInfo = function (url, texture) {

        let srcUUid = this.textureVideoSrcs[url];
        if (srcUUid) {
            delete this.textureVideos[srcUUid];
            delete this.textureVideoSrcs[url];
        }
        this.textureVideoSrcs[url] = texture.uuid;
        this.textureVideos[texture.uuid] = url;
    }

    this.changeMaterialTextureValueWeb = async function (info, pro) {
        let file = info.file
        let texture = null;
        let dataURL = null;
        let url = window.URL.createObjectURL(file.originFileObj);
        if (info.file.name.indexOf(".tga") !== -1) {
            dataURL = url
            texture = new TGALoader().load(dataURL)
        } if (url.indexOf(".mp4") !== -1) {
            let video = document.createElement("video")
            video.src = url
            video.crossOrigin = ''
            video.loop = 'loop'
            video.play()
            texture = new THREE.VideoTexture(video)
            this.setTextureVideosInfo(url, texture)
        } else {
            dataURL = await Common.getRedrawUrl(url);
            pro.thumbnail = await Common.getRedrawUrl(dataURL, 60);
            texture = new THREE.TextureLoader().load(dataURL);
        }
        texture.encoding = THREE.sRGBEncoding;
        // let image = new Image();
        // console.log(this.editMaterial)

        this.setRealMatProperty(pro.name, texture);
        pro.url = dataURL
        this.editMaterial.needsUpdate = true
        window.URL.revokeObjectURL(url);
    }

    this.changeMaterialTextureValueClient = function ($remote, pro) {

        let self = this;
        // if (pro.url) {
        //     window.URL.revokeObjectURL(pro.url);
        // }
        return new Promise(async (resolve, reject) => {

            if (self.editMaterial) {
                const fs = $remote.require("fs")
                // const path = $remote.require("path")
                let result = await $remote.dialog
                    .showOpenDialog($remote.getCurrentWindow(), {
                        properties: ["openFile"],
                        filters: [
                            { name: "Images", extensions: ["jpg", "jpeg", "png", "mp4"] },//"tga", 
                            { name: "All Files", extensions: ["*"] },
                        ],
                    })
                if (!result.canceled) {
                    try {
                        let filePath = result.filePaths[0]
                        // let ext = Common.getFilePathExt(filePath).toLowerCase();// path.extname(filePath)
                        // let mime = "image/" + ext.replace(".", "")
                        // console.log(mime);
                        // let base64 = "data:" + mime + ";base64," + fs.readFileSync(filePath).toString('base64')
                        let data = fs.readFileSync(filePath);
                        let url = window.URL.createObjectURL(new Blob([data.buffer]));
                        let dataURL = await Common.getRedrawUrl(url);
                        let texture = null;
                        if (url.indexOf(".tga") !== -1) {
                            texture = new TGALoader().load(dataURL)
                        } if (url.indexOf(".mp4") !== -1) {
                            let video = document.createElement("video")
                            video.src = url
                            video.crossOrigin = ''
                            video.loop = 'loop'
                            video.play()
                            this.setTextureVideosInfo(url, texture.uuid)
                        } else {
                            texture = new THREE.TextureLoader().load(dataURL);
                            pro.thumbnail = await Common.getRedrawUrl(url, 60);
                        }
                        // let image = new Image();
                        // eval("self.editMaterial." + pro.name + "=texture")
                        self.setRealMatProperty(pro.name, texture);
                        self.editMaterial.needsUpdate = true

                        pro.url = dataURL
                        window.URL.revokeObjectURL(url);
                    } catch (err) {
                        reject()
                    }
                } else {
                    resolve()
                }
            } else {
                resolve()
            }
        })
    }

    this.changeMaterialTextureValue = function (url, pro) {

        let self = this;
        // if (pro.url) {
        //     window.URL.revokeObjectURL(pro.url);
        // }
        return new Promise(async (resolve, reject) => {
            if (self.editMaterial) {
                try {
                    let texture = null;
                    if (url.indexOf(".tga") !== -1) {
                        texture = new TGALoader().load(url)
                    } if (url.indexOf(".mp4") !== -1) {
                        let video = document.createElement("video")
                        video.src = url
                        video.crossOrigin = ''
                        video.loop = 'loop'
                        video.play()
                        texture = new THREE.VideoTexture(video)
                        this.setTextureVideosInfo(url, texture)
                    } else {
                        texture = new THREE.TextureLoader().load(url);
                    }
                    // let image = new Image();
                    self.setRealMatProperty(pro.name, texture);
                    self.editMaterial.needsUpdate = true
                    resolve()
                } catch (err) {
                    reject()
                }
            }
        })
    }

    this.setRealMatProperty = function (proName, value, subProName = null) {
        if (this.editMaterial.uniforms) {
            if (subProName) {
                this.editMaterial.uniforms[proName].value[subProName] = value
            }
            else {
                this.editMaterial.uniforms[proName].value = value
            }
        }
        else {
            if (subProName) {
                this.editMaterial[proName][subProName] = value;
            }
            else {
                this.editMaterial[proName] = value;
            }
        }
    }

    /* eslint-enable */
}

export { MaterialType, MeshControl };