import * as THREE from 'three';
import { FBXLoader } from "../threejslib/threejs-fbxloader"; //"./three-fbxloader-offical";

import { InitOrbit, OrbitControls } from "../threejslib/OrbitControls";
import { TGALoader } from "../threejslib/TGALoader";
import Common from "../common";
import PassLib from "./passlib";
import SceneEvent from "./sceneevent";
import LightLib from "./lightlib";
import { COORDINATENAME, coordinateName } from '../3d/xyzline'

// import msgpack from "msgpack5";
// let pack = msgpack();
// let encode = pack.encode;
// var decode = pack.decode;
import { unpack, pack } from 'msgpackr'
import ThreeControl from "./three_control"
import axios from "axios";
// import { toRaw } from '@vue/reactivity';

// import { ObjectControls } from 'threejs-object-controls';
import OperationHistory from "../operation_history"

import { ParticleEffects } from "../3d/effects/ParticleEffects.js"

let threeControl = new ThreeControl()

THREE.DefaultLoadingManager.addHandler(/\.tga$/i, new TGALoader());

const RenderInfo = function (parms) {
    this.mapParams = parms.mapParams;
    this.toneMappingOptions = parms.toneMappingOptions;
    this.clearColor = parms.clearColor;
    this.enableShadows = parms.enableShadows;
}

const SceneData = function (parms) {

    this.isPackSelectOnly = parms.isPackSelectOnly;
    this.objectScene = parms.objectScene;
    this.meshBuffers = parms.meshBuffers;
    this.meshPathes = parms.meshPathes;
    this.operation = parms.operation;
    this.lightIds = parms.lightIds;
    this.scripts = parms.scripts;
    this.passInfos = parms.passInfos;
    this.renderInfo = parms.renderInfo;
    this.meshExtInfos = parms.meshExtInfos;
    this.weatherInfo = parms.weatherInfo;
}

OrbitControls(THREE);
const Threelib = function () {

    this.THREE = THREE;
    let particleEffects = null;
    let camera = null;
    let scene = null;
    this.renderer = null;
    let meshes = [];
    let fbxLoader = new FBXLoader(THREE.DefaultLoadingManager);
    // let objLoader = new THREE.ObjectLoader(THREE.DefaultLoadingManager);


    let actions = [];
    let mixers = [];
    let clock = new THREE.Clock();
    let threeCanvas = null;
    let threeContainer = null;
    this.mainLight = { intensity: 1, color: new THREE.Color(1, 1, 1), colorValue: "#ffffff", position: new THREE.Vector3(0, 0, 0), rotation: new THREE.Vector3(0, 0, 0) };
    // this.topLight = { intensity: 1, color: new THREE.Color(1, 1, 1), colorValue: "#ffffff", position: new THREE.Vector3(0, 0, 0), rotation: new THREE.Vector3(0, 0, 0) };
    let cubeCamera = null;
    let pasteFunc = null;
    let resizeRendererFunc = null;
    let isRestorePosition = false;
    let skybox = null;
    this.operation = {
        enabledSkybox: false,
        enabledAnimation: false,
        enabledAutoRotate: false,


        minDistance: 30,
        maxDistance: 1500,


        minPolarAngle: Math.PI / 6, // radians
        maxPolarAngle: Math.PI / 1.2, // radians

        minAzimuthAngle: -Infinity,
        maxAzimuthAngle: Infinity,

        enablePan: true
    };

    let axisObject = null;
    this._passLib = new PassLib()
    this.operationObjectEvent = null;
    let gridHelper = null
    this.initOperation = null

    let initCameraPosition = new THREE.Vector3(300, 88, 300)
    let cameraInitInfo = {
        position0: new THREE.Vector3(0, 0, 0),
        rotation0: new THREE.Vector3(0, 0, 0),
        zoom0: 0,
        rDelta: 0,
    };

    let allScripts = []
    // let stats = null;

    this.devicePixelRatio = window.devicePixelRatio
    this.clearColor = "#000000"
    this.highlightSelect = true
    this.enableShadows = true

    this.mapParams = {
        exposure: 1.0,
        toneMapping: 'Linear'
    };
    this.isEditor = true//是否是编辑器状态，编辑器引用时，设为true，展示端引用时，设为false
    this.lightlib = null
    this.controls = null
    this.objectControls = null

    this.getCamera = function () {
        return camera
    }

    this.getScene = function () {
        return scene
    }

    this.getSceneEvent = function () {
        return sceneEvent
    }

    this.toneMappingOptions = {
        None: THREE.NoToneMapping,
        Linear: THREE.LinearToneMapping,
        Reinhard: THREE.ReinhardToneMapping,
        Cineon: THREE.CineonToneMapping,
        ACESFilmic: THREE.ACESFilmicToneMapping,
        Custom: THREE.CustomToneMapping
    };

    this.setRendererToneMapping = function (toneMapping) {
        this.mapParams.toneMapping = toneMapping;
        this.renderer.toneMapping = this.toneMappingOptions[this.mapParams.toneMapping];
    }


    this.setRendererToneMappingExposure = function (exposure) {
        this.mapParams.exposure = exposure;
        this.renderer.toneMappingExposure = this.mapParams.exposure;
    }

    this.setEnableShadows = function (enable) {
        this.renderer.shadowMap.enabled = enable;
        this.enableShadows = enable;
    }

    let sceneEvent = null;
    this.selectMeshMaterials = null;

    this.selectObject = function (object3d) {
        if (object3d) {
            if (this.selectMeshMaterials) {
                this.selectMeshMaterials(object3d)
            }
            this.setSelectObjectPass(object3d)
        }
    }

    this.bindEvents = function () {

        let scope = this;
        if (this.isEditor) {
            sceneEvent = new SceneEvent({
                scene: scene,
                camera: camera,
                initCameraPosition: initCameraPosition,
                threeContainer: threeContainer,
                renderer: this.renderer,
                selectObjectEvent: this.selectObject.bind(this),
                controls: this.controls,
                meshControl: this.meshControl,
                devicePixelRatio: this.devicePixelRatio,
                axisObject: axisObject,
                operationObjectEvent: this.operationObjectEvent,
                onWindowResize: this.resizeRenderer.bind(this),
                enableRender: (enable) => {
                    if (enable) {
                        scope.renderer.setAnimationLoop(scope.update.bind(scope)); //.bind(this);
                    }
                    else {
                        scope.renderer.setAnimationLoop(null);
                    }
                }
            });
            sceneEvent.bindEvents();
        }

        threeContainer.appendChild(this.renderer.domElement);

        setTimeout(() => {
            scope.resizeRenderer();
        }, 200);

        this.renderer.setAnimationLoop(null); //.bind(this);
        this.renderer.setAnimationLoop(this.update.bind(this)); //.bind(this);

        if (resizeRendererFunc) {
            window.removeEventListener('resize', resizeRendererFunc);
        }
        resizeRendererFunc = this.resizeRenderer.bind(this);
        window.addEventListener("resize", resizeRendererFunc);

        // if (!this.isEditor) {
        threeContainer.ondblclick = () => {
            isRestorePosition = true;
        }
        // }
        particleEffects = new ParticleEffects({
            scene: scene,
            camera: camera
        });
        this.lightlib = new LightLib({
            scene: scene,
            meshControl: this.meshControl
        })
    }

    this.setPasteEvent = function (cloneObject3DInvoke) {
        if (cloneObject3DInvoke) {
            if (pasteFunc) {
                document.removeEventListener('paste', pasteFunc);
            }
            pasteFunc = cloneObject3DInvoke.bind(this);
            document.addEventListener("paste", pasteFunc);
        }
    }

    // Threelib.prototype = {
    //     get enabledSkybox() {
    //         return this.operation.enabledSkybox;
    //     },
    //     get enabledAnimation() {
    //         return this.operation.enabledAnimation;
    //     }
    // }

    this.resetStatusParameters = function () {
        isRestorePosition = false;

        if (this.initOperation) {
            this.operation = JSON.stringify(this.initOperation);
        }
        this.initOperation = null;
        this.resetControl();

        this.mainLight.intensity = 1;
        this.mainLight.color = new THREE.Color(1, 1, 1);
        this.mainLight.colorValue = "#ffffff";

        this.topLight.intensity = 1;
        this.topLight.color = new THREE.Color(1, 1, 1);
        this.topLight.colorValue = "#ffffff";
        this.operation.enabledSkybox = false;
        this.operation.enabledAnimation = false;
        this.operation.enabledAutoRotate = false;
    }

    this.resetActions = function () {
        actions = []; //所有的动画数组
        mixers = []; //所有的动画Mixer
    }


    this.renderAnimation = function (delta) {

        // var isSetRender = this.forceRenderAnimation;
        for (var i = 0; i < mixers.length; i++) {
            var mixer = mixers[i];
            var action = actions[i];
            if (mixer) {
                mixer.update(delta);
            }
            if (action) {
                if (action.time >= action.playTime) { //播放时间到底的时候重置
                    action.time = 0;
                }
            }
        }
    }

    this.initAnimation = function (mesh) {
        for (var i = 0; i < mesh.animations.length; i++) {
            var mixer = new THREE.AnimationMixer(mesh);
            // 查看动画数据
            // obj.animations[0]：获得剪辑对象clip
            var action = mixer.clipAction(mesh.animations[i]);
            // mesh.animations[i].timeScale = 1; //默认1，可以调节播放速度
            action.loop = THREE.LoopRepeat; //循环播放
            // action.clampWhenFinished = true;//暂停在最后一帧播放的状态

            action.duration = mesh.animations[i].duration;
            mixer.duration = action.duration;

            // action.time = mesh.animations[i].duration;
            action.timeScale = 1; //-1逆序播放
            action.playTime = action.duration * action.timeScale; //动画有问题，先测试
            actions.push(action);
            mixers.push(mixer);
        }
    }

    this.setAnimationTime = function (action) {
        if (action.timeScale < 0) {
            action.time = action.duration;
        } else {
            action.time = 0;
        }
    }

    this.reverseAnimationTimeScale = function () {

        for (var i = 0; i < actions.length; i++) {
            if (i < mixers.length) {

                var action = actions[i];
                var mixer = mixers[i];
                // action.enable = false;
                var timeScale = -action.timeScale; //反序播放
                mixer.time = action.duration - action.time;
                action.paused = false;
                // action.enable = true;

                if (!action.isRunning()) {
                    action.timeScale = timeScale;
                    if (action.timeScale > 0) {
                        action.time = mixer.time = 0;
                    } else {
                        action.time = mixer.time = action.duration;
                    }
                    action.play();
                } else {
                    action.timeScale = timeScale;
                }
            }
            // action.play();
        }
    }


    this.playAnimation = function () {
        for (var i = 0; i < actions.length; i++) {
            var action = actions[i];
            action.paused = false;
            action.play();
        }
        this.operation.enabledAnimation = true;
    }

    this.stopAnimation = function () {
        for (var i = 0; i < actions.length; i++) {
            actions[i].stop();
        }
        this.operation.enabledAnimation = false;
    }

    this.switchAnimation = function () {
        this.operation.enabledAnimation = !this.operation.enabledAnimation;
        if (this.operation.enabledAnimation) {
            this.playAnimation();
        } else {
            this.stopAnimation();
        }
        // return JSON.parse(JSON.stringify(this.operation));
    }

    this.switchAutoRotate = function () {
        this.operation.enabledAutoRotate = !this.operation.enabledAutoRotate;
        if (this.operation.enabledAutoRotate) {
            this.setAutoRotate(this.operation.enabledAutoRotate);
        } else {
            this.setAutoRotate(this.operation.enabledAutoRotate);
        }
        // return JSON.parse(JSON.stringify(this.operation));
    }

    this.pauseAnimation = function (idx = -1) {
        if (idx == -1) {
            for (var i = 0; i < actions.length; i++) {
                if (!actions[i].paused) {
                    actions[i].paused = true;
                }
            }
        } else {
            if (idx < actions.length) {
                actions[idx].paused = true;
            }
        }
    }

    this.resetControl = function () {
        if (this.controls.constraint) {
            this.setControlParmeters(this.controls.constraint);
        }
    }

    this.setControlParmeters = function (config) {
        let minDistance = this.operation.minDistance ? this.operation.minDistance : 30;
        let maxDistance = this.operation.maxDistance ? this.operation.maxDistance : 500;

        //使动画循环使用时阻尼或自转 意思是否有惯性
        config.enableDamping = this.operation.enableDamping ? this.enableDamping : false;
        //动态阻尼系数 就是鼠标或者触控拖拽旋转灵敏度
        config.dampingFactor = this.operation.dampingFactor ? this.dampingFactor : 0.5;
        config.dampingZoomFactor = this.operation.dampingZoomFactor ? this.dampingZoomFactor : 5.0;
        // if (Common.isMobile()) {
        config.zoomOperLimit = this.operation.zoomOperLimit ? this.zoomOperLimit : 0.9;
        // }
        config.touchDampingZoomFactor = Math.min(80, 0.25 * (maxDistance - minDistance) / 80);
        config.dampingZoomFactor = Math.min(80, 0.25 * (maxDistance - minDistance) / 80);

        // config.touchDampingZoomFactor = Math.min(1, config.touchDampingZoomFactor);
        // config.dampingZoomFactor = Math.min(5, config.dampingZoomFactor);

        //是否可以缩放
        config.enableZoom = this.operation.enableZoom ? this.enableZoom : true;
        //缩放速度
        config.zoomSpeed = this.operation.zoomSpeed ? this.zoomSpeed : 1.0;
        //是否自动旋转
        config.autoRotate = this.operation.enabledAutoRotate;
        //设置相机距离原点的最近距离
        config.minDistance = minDistance;
        //设置相机距离原点的最远距离
        config.maxDistance = maxDistance;

        //是否开启右键拖拽
        config.enablePan = this.operation.enablePan ? this.operation.enablePan : true; //modelInfo != null ? modelInfo.controls.enablePan : false;

        config.minPolarAngle = this.operation.minPolarAngle ? this.operation.minPolarAngle : Math.PI / 6; // radians
        config.maxPolarAngle = this.operation.maxPolarAngle ? this.operation.maxPolarAngle : Math.PI / 1.2; // radians

        if (isNaN(this.operation.minAzimuthAngle)) {
            this.operation.minAzimuthAngle = -Infinity;
        }
        if (isNaN(this.operation.maxAzimuthAngle)) {
            this.operation.minAzimuthAngle = Infinity;
        }
        config.minAzimuthAngle = this.operation.minAzimuthAngle ? this.operation.minAzimuthAngle : -Infinity;
        config.maxAzimuthAngle = this.operation.maxAzimuthAngle ? this.operation.maxAzimuthAngle : Infinity;


        config.rotateSpeed = this.operation.rotateSpeed ? this.rotateSpeed : 0.5;
        config.autoRotateSpeed = this.operation.autoRotateSpeed ? this.autoRotateSpeed : 0.5;
        config.zoomMultiple = this.operation.zoomMultiple ? this.zoomMultiple : 0.95;

        config.enableKeys = false;

        if (camera) {
            camera.far = Math.max(100000, maxDistance * 2);
            camera.updateProjectionMatrix();
        }
        if (!this.initOperation) {
            this.initOperation = JSON.stringify(this.config);
        }
    }

    this.selectObjectAndSetXYZCoordnate = function () {
        sceneEvent.setRelativeZeroPosition(this.meshControl.selectObject3d);
        this.selectObject(this.meshControl.selectObject3d);
    }

    this.initControl = function () {

        if (camera && this.renderer) {

            let config = {};

            this.setControlParmeters(config);

            // this.controls = null;
            this.controls = InitOrbit(THREE, camera, this.renderer.domElement, config); //创建控件对象 camera是你的相机对象
            // controls.beforeMinDistance = minDistance;
            // controls.beforeMaxDistance = maxDistance;

            // this.objectControls = new ObjectControls(camera, this.renderer.domElement, []);
        }
        return this.controls;
    }

    this.destroyThreeScene = function (forceContextLoss = false) {
        // try {
        this.resetActions();
        this.clearMeshes();

        if (sceneEvent) {
            sceneEvent.dispose(forceContextLoss)
        }

        if (scene != null) {
            scene.remove();
            // scene.dispose();
            scene = null;
        }
        if (camera != null) {
            camera.remove();
            camera = null;
        }

        if (cubeCamera) {
            cubeCamera.remove();
            cubeCamera = null;
        }

        let renderer = this.renderer;
        if (renderer) {
            // renderer.setAnimationLoop(null); //.bind(this);
            if (threeContainer && renderer.domElement) {
                Common.empty(renderer.domElement);
                threeContainer.removeChild(renderer.domElement);
                // threeContainer = null;
            }
            if (forceContextLoss) {
                renderer.forceContextLoss(); //Debug
            }
            renderer.dispose();
            renderer.content = null;
            renderer.domElement = null;
            this.renderer = null;
        }

        // } catch (err) {
        //     alert("destroyThreeScene error:" + err.message);
        // }
    }

    this.traverseAllChildren = function (obj, getMeshOnly = false) {
        let children = [];
        let self = this;
        // if (getParent) {
        //     if (getMeshOnly) {
        //         if (obj instanceof THREE.Mesh) {
        //             children.push(obj);
        //         }
        //     } else {
        //         children.push(obj);
        //     }
        // }
        if (obj.children) {
            obj.traverse((child) => {
                if (child !== obj) {
                    if (getMeshOnly) {
                        if (child instanceof THREE.Mesh) {
                            children.push(child);
                        }
                    } else {
                        children.push(child);
                    }
                    children = children.concat(self.traverseAllChildren(child, getMeshOnly));
                }
                // for (let j = 0; j < childs.length; j++) {
                //     if (getMeshOnly) {
                //         if (childs[j] instanceof THREE.Mesh) {
                //             children.push(childs[j]);
                //         }
                //     } else {
                //         children.push(childs[j]);
                //     }
                // }
            })
        }
        return children;
    }

    this.createPathStrings = function (filename) {
        const basePath = "./skybox/calm_sea/";
        const baseFilename = basePath + filename;
        const fileType = ".jpg";
        const sides = ["ft", "bk", "up", "dn", "rt", "lf"];
        const pathStings = sides.map(side => {
            return baseFilename + "_" + side + fileType;
        });

        return pathStings;
    }

    this.createMaterialArray = async function (skyboxImagepaths, i = 0, materialArray = []) {

        if (i >= skyboxImagepaths.length) {
            return materialArray;
        }
        let image = skyboxImagepaths[i];
        let texture = await new THREE.TextureLoader().loadAsync(image);
        texture.encoding = THREE.LinearEncoding; //使用sRGB编码
        materialArray.push(new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide }));
        i++;
        return await this.createMaterialArray(skyboxImagepaths, i, materialArray);
    }

    this.createSkyBoxTest = function () {

        const skyboxImage = 'calm_sea_';

        const basePath = "./skybox/calm_sea/";
        let skyBoxTex = new THREE.CubeTextureLoader().setPath(basePath)
            .load([
                `${skyboxImage}ft.jpg`,
                `${skyboxImage}bk.jpg`,
                `${skyboxImage}up.jpg`,
                `${skyboxImage}dn.jpg`,
                `${skyboxImage}rt.jpg`,
                `${skyboxImage}lf.jpg`
            ]);

        return skyBoxTex;
    }


    this.createSkyBox = async function () {

        // let skyboxGeo = new THREE.BoxGeometry(10000, 10000, 10000);
        // const skyboxImage = 'calm_sea';
        // const skyboxImagepaths = this.createPathStrings(skyboxImage);
        // const materialArray = await this.createMaterialArray(skyboxImagepaths);
        // let skybox = new THREE.Mesh(skyboxGeo, materialArray);

        const skyboxImage = 'calm_sea_';
        const basePath = "./skybox/calm_sea/";
        let skyBoxTex = new THREE.CubeTextureLoader().setPath(basePath)
            .load([
                `${skyboxImage}ft.jpg`,
                `${skyboxImage}bk.jpg`,
                `${skyboxImage}up.jpg`,
                `${skyboxImage}dn.jpg`,
                `${skyboxImage}rt.jpg`,
                `${skyboxImage}lf.jpg`
            ]);

        return skyBoxTex; //skybox;
    }

    this.initRenderer = function (enableShadow = true) {

        let renderer = new THREE.WebGLRenderer({

            powerPreference: "high-performance",
            // stencil: false,
            // depth: false,

            //增加下面两个属性，可以抗锯齿
            antialias: true,
            alpha: true,
            // 设置logarithmicDepthBuffer属性为true, 便可以处理重影问题
            // logarithmicDepthBuffer, 官方解释: 是否使用对数深度缓存。如果要在单个场景中处理巨大的比例差异，就有必要使用, 默认是false。 使用了会带来额外的开销, 但是效果会变好.
            logarithmicDepthBuffer: true,

            // precision: 'highp', // highp/mediump/lowp 表示着色精度选择
            // premultipliedAlpha: false, // true/false 表示是否可以设置像素深度（用来度量图像的分辨率）
            // preserveDrawingBuffer: true, // true/false 表示是否保存绘图缓冲
            // maxLights: 3, // 最大灯光数
            // stencil: false // false/true 表示是否使用模板字体或图案
        });
        renderer.setPixelRatio(this.devicePixelRatio);
        renderer.setClearColor(new THREE.Color(this.clearColor), 1);
        renderer.outputEncoding = THREE.LinearEncoding;
        renderer.shadowMap.enabled = enableShadow; //开启阴影，加上阴影渲染
        renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

        // renderer.gammaOutput = true;
        // renderer.gammaFactor = 2.2;   //电脑显示屏的gammaFactor为2.2

        this.renderer = renderer;

        //设置渲染模式
        this.setRendererToneMapping(this.mapParams.toneMapping);
        this.setRendererToneMappingExposure(this.mapParams.exposure);

        threeCanvas = renderer.domElement;
        if (threeCanvas) {
            threeCanvas.style.touchAction = 'none';
            threeCanvas.style.width = '100%';
            threeCanvas.style.height = '100%';
        }
        // console.log(renderer.pixelRatio);

        this.initControl();
    }

    this.getSkybox = async function () {
        let skybox = await this.createSkyBox();
        // skybox.name = "skybox";
        return skybox;
    }

    this.enableSkybox = function (enable) {
        if (skybox && scene) {

            let scope = this
            OperationHistory.push(function () {
                scope._enableSkybox(enable)
            }, function () {
                scope._enableSkybox(!enable)
            })

            this._enableSkybox(enable)
        }
    }

    this._enableSkybox = function (enable) {
        if (skybox && scene) {
            this.operation.enabledSkybox = enable;
            if (this.operation.enabledSkybox) {
                // scene.add(skybox);
                scene.background = skybox;
            } else {
                // scene.remove(skybox);
                scene.background = null;
            }
        }
    }

    this.switchSkybox = function () {
        this.enableSkybox(!this.operation.enabledSkybox);
        // return JSON.parse(JSON.stringify(this.operation));
    }

    this.init = async function (container, meshControl, isCreateSceneAndCamera = true, axisMesh = null) {

        // THREE.Cache.enabled = true; //启用缓存
        this.destroyThreeScene();
        this.clearColor = "#3B3B3B";
        threeContainer = container;
        axisObject = axisMesh;
        allScripts = [];
        meshControl.textureVideoSrcs = [];
        meshControl.textureVideos = [];
        this.meshControl = meshControl;
        if (this.isEditor) {
            skybox = await this.getSkybox();
        }

        if (isCreateSceneAndCamera) {

            camera = new THREE.PerspectiveCamera(60, container.offsetWidth / container.offsetHeight, 0.01, 100000);
            camera.name = 'MainCamera';
            // camera.position.x = 50;
            // camera.position.y = 50;
            camera.position.copy(initCameraPosition.clone())

            scene = new THREE.Scene();

            // let lightTop = new THREE.DirectionalLight(new THREE.Color(1, 1, 1), 1, 30, 0.5);
            // lightTop.position.y = 20;
            // lightTop.rotation.x = 0;
            // lightTop.rotation.z = 0;
            // // lightTop.castShadow = true;

            // //Set up shadow properties for the light
            // lightTop.shadow.mapSize.width = 2048; // default
            // lightTop.shadow.mapSize.height = 2048; // default
            // lightTop.shadow.camera.near = 0.5; // default
            // lightTop.shadow.camera.far = 2000; // default
            // lightTop.shadow.camera.left = -30;
            // lightTop.shadow.camera.right = 30;
            // lightTop.shadow.camera.top = -30;
            // lightTop.shadow.camera.bottom = 30;

            // this.topLight = lightTop;
            // this.topLight.colorValue = '#' + this.topLight.color.getHexString();


            //Create a helper for the shadow camera (optional)
            // const helper = new THREE.CameraHelper(lightTop.shadow.camera);
            // scene.add(helper);

            // light.castShadow = true;

            // this.enableSkybox(true);

            scene.add(camera);
            // scene.add(lightTop);
            // let light = new THREE.DirectionalLight(new THREE.Color(1, 1, 1), 1);
            // scene.add(light);
            // this.mainLight = light;
            // this.mainLight.name = "mainLight";
            // this.mainLight.colorValue = '#' + this.mainLight.color.getHexString();
            // camera.add(this.mainLight);

            camera.name = "MainCamera";

            this.addGridHelper();
            // //floor
            // const floorGeometry = new THREE.BoxGeometry(60, 60, 2);
            // const floorMaterial = new THREE.MeshStandardMaterial({ color: 0x888888, side: THREE.DoubleSide });
            // // 创建一个平面对象Plane
            // const floor = new THREE.Mesh(floorGeometry, floorMaterial);
            // floor.receiveShadow = true;
            // floor.position.y = -1;
            // floor.rotation.x = Math.PI / 2;

            this.initRenderer();
            this.addEffects();

            this.bindEvents();

        } else {
            this.initRenderer();
        }
        // scene.add(floor);

        if (isCreateSceneAndCamera) {
            // if (this.controls) {
            //     this.controls.constraint.rotateLeft(-Math.PI / 9.0);
            //     this.controls.constraint.rotateUp(Math.PI / 50);
            // }
            this.saveCameraInitInfo();
        }

        this.initEnv();
        // this.initStats();
        // resolve();

        // setTimeout(async () => {

        //     if (isInit) {

        //         console.log(self.mesh.type);

        //         self.setMeshFromGroupJsonObject(self.mesh, { geometry: new THREE.BufferGeometry() });
        //         let serMesh = self.mesh.toJSON();

        //         let packObjectMesh = encode(serMesh);

        //         scene.remove(self.mesh);
        //         renderer.render(scene, camera);

        //         let jsonObjectScene = scene.toJSON();
        //         let packObjectScene = encode(jsonObjectScene);

        //         var loader = new THREE.ObjectLoader();
        //         let jsonObjectMesh = decode(packObjectMesh);
        //         jsonObjectScene = decode(packObjectScene);

        //         // var blob = new Blob([jsonObjectScene], { type: "text/plain" });

        //         await self.init(container, buffer, false);

        //         let meshReload = self.mesh;
        //         scene = loader.parse(jsonObjectScene);

        //         self.mesh = loader.parse(jsonObjectMesh);
        //         self.setMeshFromGroupJsonObject(self.mesh, meshReload);
        //         // self.mesh.geometries = loader.parse(jsonObjectGeo);
        //         camera = scene.getObjectByName("mainCamera");
        //         scene.add(self.mesh);
        //         self.resizeRenderer();
        //         self.initControl();
        //         self.initAnimation(self.mesh);
        //         renderer.render(scene, camera);
        //         self.playAnimation();
        //     }
        // }, 500);
        // });
        // });
    }

    this.addEffects = function (passInfos) {

        this.passLib.addEffects({
            renderWidth: threeContainer.offsetWidth,
            renderHeight: threeContainer.offsetHeight,
            renderer: this.renderer,
            scene: scene,
            camera: camera,
            devicePixelRatio: this.devicePixelRatio,
            passInfos: passInfos
        });
    }

    this.addGridHelper = function () {
        // 添加调试网格
        if (this.isEditor) {
            gridHelper = new THREE.GridHelper(6000, 50, 0x777777, 0x555555);
            gridHelper.userData[coordinateName] = COORDINATENAME;
            // gridHelper.userData['girdHelper'] = 'girdHelper';
            gridHelper.position.y = -80;
            scene.add(gridHelper);
        }
    }

    this.removeGridHelper = function () {
        // 添加调试网格
        if (scene && gridHelper) {
            scene.remove(gridHelper);
            gridHelper = null;
        }
    }

    this.getRenderTarget = function (cubemap, target) {

        let pmREMGenerator = new THREE.PMREMGenerator(this.renderer);
        let renderTarget = pmREMGenerator.fromCubemap(cubemap, target);
        // renderTarget.setSize(1024,1024);
        return renderTarget;
    }


    this.initEnv = function () {

        if (this.renderer && scene) {
            // scene.add(new THREE.AxesHelper(50));
            //将要创建的纹理对象，定义目标纹理的一些参数
            const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(1024, {
                format: THREE.RGBAFormat,
                generateMipmaps: true,
                minFilter: THREE.LinearMipmapLinearFilter
            });
            cubeCamera = new THREE.CubeCamera(0.01, 10000, cubeRenderTarget); //实例化一个cubeCamera
            cubeCamera.name = "cubeCamera";
            cubeCamera.position.set(0, 0, 0)
            scene.add(cubeCamera); //将其添加到场景中
            if (meshes.length > 0)
                meshes[0].visible = false;
            cubeCamera.update(this.renderer, scene); //更新渲染立方体贴图
            if (meshes.length > 0)
                meshes[0].visible = true;
            cubeCamera.renderTarget.encoding = THREE.LinearEncoding; //使用sRGB编码

            // this.enableSkybox(false);

            // scene.background = cubeRenderTarget.texture; //this.createSkyBoxTest();//cubeRenderTarget.texture;

            let envTexture = this.getRenderTarget(cubeRenderTarget.texture, cubeRenderTarget).texture;
            // console.log(envTexture)
            scene.environment = envTexture;

            const sphereMaterial = new THREE.MeshPhysicalMaterial({
                //cubeCamera生成的纹理作为球体的环境贴图
                // envMap: cubeRenderTarget.texture,
                // envMap: scene.background,
                color: 0xFFFFFF,
                // metalness: 1,
                reflectivity: 0,
                refractionRatio: .1,
                // roughness: 0,
                // envMapIntensity: 1
            })
            sphereMaterial.needsUpdate = true;
            const sphereGeometry = new THREE.SphereGeometry(20, 20, 20)
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)
            sphere.position.set(0, 0, 0)

            // scene.add(sphere)
        }
    }

    this.saveCameraInitInfo = function () {
        cameraInitInfo.position0 = camera.position.clone();
        cameraInitInfo.rotation0 = camera.rotation.clone();
        cameraInitInfo.zoom0 = camera.zoom;
        cameraInitInfo.rDelta = this.controls.constraint.getRDelta();
    }

    this.setMeshMaterials = function (mesh) {
        let children = this.traverseAllChildren(mesh);
        for (let i = 0; i < children.length; i++) {
            let mat = children[i].material;
            children[i].material = this.getNewMaterial(mat);
        }
    }

    this.setMeshShadowsEnable = function (mesh, enable) {
        let children = this.traverseAllChildren(mesh);
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            child.castShadow = enable;
            child.receiveShadow = enable;
        }
        mesh.castShadow = enable;
        mesh.receiveShadow = enable;
    }

    this.getSameGroupMeshes = function (mesh) {
        let selectMeshes = [];
        selectMeshes.push(mesh);
        for (let i = 0; i < meshes.length; i++) {
            if (meshes[i]['groupId'] == mesh['groupId']) {
                selectMeshes.push(meshes[i]);
            }
        }
        return selectMeshes;
    }

    this.getMeshForProxy = function (mesh) {
        for (let i = 0; i < meshes.length; i++) {
            let m = meshes[i];
            let ms = this.traverseAllChildren(m);
            for (let j = 0; j < ms.length; j++) {
                let m2 = ms[j];
                if (m2.uuid == mesh.uuid) {
                    let result = this.traverseAllChildren(m2);
                    return result;
                }
            }
        }
        return null;
    }

    this.selectMeshes = null;
    this.enableHighlightSelect = function (enable) {
        this.highlightSelect = enable;
        if (this.highlightSelect) {
            if (this.selectMeshes) {
                this.passLib.setSelectObjectPass(this.selectMeshes);
            }
        } else {
            this.passLib.setSelectObjectPass([]);
        }
    }

    this.setSelectObjectPass = function (object3d) {
        // if (sceneEvent.checkIsAxis(object3d)) {
        //     return;
        // }
        // if (object3d != null && this.highlightSelect) {
        //     if (object3d.userData[coordinateName] === COORDINATENAME) {
        //         return;
        //     }
        //     let selectMeshes = [];
        //     let children = this.traverseAllChildren(object3d);
        //     if (children && children.length > 0) {
        //         for (let i = 0; i < children.length; i++) {
        //             let child = children[i]
        //             if (child.isGroup) {
        //                 continue;
        //             }
        //             else if (sceneEvent.checkIsAxis(child)) {
        //                 continue
        //             }
        //             else if (child.userData[coordinateName] === COORDINATENAME) {
        //                 continue
        //             }
        //             selectMeshes.push(child)
        //         }
        //         if (selectMeshes.length === 0) {
        //             selectMeshes.push(object3d)
        //         }

        //         this.selectMeshes = selectMeshes;
        //     }
        //     else {
        this.selectMeshes = [object3d];
        // }

        this.passLib.setSelectObjectPass(this.selectMeshes);
        // }
    }

    this.copyAllMaterial = function (originInst, cloneInst) {
        if (originInst && cloneInst) {
            if (originInst.material) {
                cloneInst.material = originInst.material
            }

            if (originInst.children && originInst.children.length
                && cloneInst.children && cloneInst.children.length
                && originInst.children.length === cloneInst.children.length)
                for (let i = 0; i < originInst.children.length; i++) {
                    let originChild = originInst.children[i]
                    let cloneChild = cloneInst.children[i]
                    cloneInst.children[i] = this.copyAllMaterial(originChild, cloneChild);
                }
        }
        return cloneInst;
    }

    this.cloneMesh = function (mesh, isAddToHistory = false, params = {}) {

        if (!mesh) {
            return { mesh: null, meshNames: [] };
        }
        let scope = this

        let visible = mesh.visible

        let resultHandler = function (mesh) {

            sceneEvent.removeXyzLineFromParent();
            let cloneInst = mesh.clone();

            cloneInst.filePath = mesh.filePath;
            cloneInst.arrayBuffer = mesh.arrayBuffer;
            mesh.userData['groupId'] = mesh.userData['groupId'] == null ? mesh.uuid : mesh.userData['groupId'];
            cloneInst.userData['groupId'] = mesh.userData['groupId'];

            cloneInst.position.x = mesh.position.x + 50;
            // cloneInst.position.y = mesh.position.y;
            // cloneInst.position.z = mesh.position.z - 50;

            cloneInst = scope.copyAllMaterial(mesh, cloneInst);

            sceneEvent.addXyzLineToGroup(mesh);
            cloneInst.visible = visible
            scope.addMesh(cloneInst);

            if (scope.passLib.outlinePass) {
                scope.passLib.outlinePass.selectedObjects = [cloneInst];
            }
            const meshNames = threeControl.getMeshNames(cloneInst);
            let results = { mesh: cloneInst, meshNames: meshNames };
            return results
        }

        let results = resultHandler(mesh)
        scope.meshControl.selectObject3d = results.mesh

        if (isAddToHistory) {
            OperationHistory.push(async () => {
                let parent = results.mesh.parent

                if (parent) {
                    parent.add(results.mesh)
                    meshes.add(results.mesh)
                }
                else {
                    scope.addMesh(results.mesh)
                }
                if (params.redoCallback) {
                    await params.redoCallback(results)
                }
            }, async () => {
                scope.removeObject3dToTemp(results.mesh)
                if (params.undoCallback) {
                    await params.undoCallback(results)
                }
            })
        }

        return results;
    }

    this.changeRendererClearColor = function (value) {
        if (this.renderer) {
            if (value) {
                this.clearColor = value;
                this.renderer.setClearColor(new THREE.Color(this.clearColor), 1);
            }
        }
    }

    /* eslint-disable */
    this.addModelFromUrl = function (url, isAddToScene = true, isEdit = true, isAddToHistory = false, params = {}) {
        // this.percent = 0;
        let scope = this;

        return new Promise((resolve) => {
            // let url =
            //     "./models/axis.mod"; //"./gun.mod";
            let qIndx = url.indexOf("?");
            let splitUrls = [""];
            if (qIndx === -1) {
                splitUrls = url.split(".");
            } else {
                splitUrls = url.substring(0, qIndx).split(".");
            }
            let ext = "." + splitUrls[splitUrls.length - 1];
            axios({
                method: "get",
                url: url,
                responseType: "arraybuffer",
                // headers: {
                //   "Cache-Control": "no-cache",
                // },
                // onDownloadProgress(progress) {
                //     // Math.round((progress.loaded / progress.total) * 100) + "%"
                //     // self.percent = Math.round((progress.loaded / progress.total) * 90);
                // },
            }).then(async function (response) {

                let resultHandler = async (response) => {
                    let mesh = null;
                    if (ext === ".fbx") {
                        mesh = await fbxLoader.parse(response.data, "./");
                    } else if (ext === ".obj") {
                        alert("暂不支持 " + ext + " 格式");
                        return;
                    } else if (ext === ".mod" || ext === ".modjson") {
                        let info = (await scope.loadObject3d(new Uint8Array(response.data), null, false, isEdit, false)).meshInfos;
                        if (info.length > 0) {
                            mesh = info[0].mesh
                        }
                    } else {
                        alert("暂不支持 " + ext + " 格式");
                        return;
                    }

                    if (mesh) {
                        if (isEdit) {
                            mesh.arrayBuffer = response.data;
                        }

                        mesh.ext = ext;
                        mesh.position.x = 0;
                        mesh.position.y = 0; //7
                        mesh.position.z = 0;

                        scope.setMeshMaterials(mesh);
                        scope.setMeshShadowsEnable(mesh, true);
                        if (isAddToScene) {
                            scope.addMesh(mesh);
                        }

                        if (ext === ".fbx") { // || ext === ".mod" || ext === ".modjson") {
                            scope.initAnimation(mesh);
                            if (scope.operation.enabledAnimation) {
                                scope.playAnimation();
                            }
                        }
                        let meshNames = threeControl.getMeshNames(mesh);
                        let results = { mesh: mesh, meshNames: meshNames };
                        return results
                    }
                    else {
                        let results = { mesh: null, meshNames: [] };
                        return results
                    }
                }

                let results = await resultHandler(response)
                if (scope.meshControl) {
                    scope.meshControl.selectObject3d = results.mesh
                }
                if (isAddToHistory && results.mesh) {
                    let parent = results.mesh.parent
                    OperationHistory.push(async () => {
                        parent.add(results.mesh)
                        if (params.redoCallback) {
                            params.redoCallback(results)
                        }
                    }, async () => {
                        scope.removeObject3dToTemp(results.mesh)
                        if (params.undoCallback) {
                            await params.undoCallback(results)
                        }
                    })
                }

                resolve(results);
            });
        })
    }
    /* eslint-enable */

    this.addModelFromBuffer = async function (arrayBuffer, filePath, ext, isAddToScene = true, isEdit = true, isAddToHistory = false, params = {}) {

        let scope = this
        let resultHandler = async function (arrayBuffer, filePath, ext, isAddToScene = true, isEdit = true) {

            if (arrayBuffer == null) {
                return { mesh: null, meshNames: [] };
            }
            let mesh = null;
            if (ext === ".fbx") {
                // let fbxLoader = new FBXLoader(THREE.DefaultLoadingManager);
                mesh = await fbxLoader.parse(arrayBuffer, "./");
            } else if (ext === ".obj") {
                // " data:application/octet-stream;base64," + 
                // let base64 = Common.arrayBufferToBase64(arrayBuffer)
                // mesh = objLoader.load(base64, (mesh) => {
                //     console.log(mesh)
                // });
                alert("暂不支持 " + ext + " 格式");
                return;
            } else {
                alert("暂不支持 " + ext + " 格式");
                return;
            }

            // console.log(filePath)
            // console.log(isAddToScene)
            // console.log(isEdit)
            // console.log(fbxLoader)

            // resolve( { mesh: mesh, meshNames: [] });

            // let lowerPath = path.toLowerCase();
            // if (lowerPath.indexOf('.fbx') !== -1) {
            //     mesh.fileType = "fbx";
            // } else if (lowerPath.indexOf('.obj') !== -1) {
            //     mesh.fileType = "obj";
            // }
            // loader.load(buffer, (mesh) => {

            // console.log(mesh.scale);
            // mesh.scale.x = 0.1;
            // mesh.scale.y = 0.1;
            // mesh.scale.z = 0.1;
            // if (filePath === "") {
            if (isEdit) {
                mesh.arrayBuffer = arrayBuffer;
            }
            // }
            // else {
            mesh.filePath = filePath + ext;
            // }
            mesh.ext = ext;
            mesh.position.x = 0;
            mesh.position.y = 0; //7
            mesh.position.z = 0;

            // this.resetActions();
            scope.setMeshMaterials(mesh);
            scope.setMeshShadowsEnable(mesh, true);
            // if (isAddToScene && !scene) {
            //     return { mesh: null, meshNames: [] };
            // }
            if (isAddToScene) {
                if (scene) {
                    scope.addMesh(mesh);
                }
            }

            if (ext === ".fbx") { // || ext === ".mod" || ext === ".modjson") {
                scope.initAnimation(mesh);
                if (scope.operation.enabledAnimation) {
                    scope.playAnimation();
                }
            }
            // let meshNames = [];
            // if (isAddToScene) {
            let meshNames = threeControl.getMeshNames(mesh);
            // }
            let results = { mesh: mesh, meshNames: meshNames };

            return results
        }

        let results = await resultHandler(arrayBuffer, filePath, ext, isAddToScene, isEdit)
        if (scope.meshControl) {
            scope.meshControl.selectObject3d = results.mesh
        }

        if (isAddToHistory && results.mesh) {
            let parent = results.mesh.parent
            OperationHistory.push(async () => {
                parent.add(results.mesh)
                if (params.redoCallback) {
                    await params.redoCallback(results)
                }
            }, async () => {
                scope.removeObject3dToTemp(results.mesh)
                if (params.undoCallback) {
                    await params.undoCallback(results)
                }
            })
        }

        // console.log(meshNames);
        return results;
        // resolve(results);
        // });
        // renderer.render(scene, camera);
    }

    this.removeObject3dToTemp = function (object3d) {
        if (object3d) {
            object3d.removeFromParent()
            for (let i = 0; i < meshes.length; i++) {
                if (meshes[i] === object3d) {
                    meshes.splice(i, 1)
                    break
                }
            }
        }
    }

    this.addMesh = function (mesh) {
        if (scene) {
            meshes.push(mesh);
            scene.add(mesh);
        }
    }

    this.getTopMesh = function (mesh) {
        if (mesh) {
            if (mesh.parent == null || mesh.parent instanceof THREE.Scene) {
                return mesh;
            }
            return this.getTopMesh(mesh.parent);
        }
        return mesh;
    }

    this.removeMesh = function (object3d, parent) {
        if (object3d) {
            if (!parent) {
                parent = scene;
            }
            sceneEvent.detachAxis();
            if (object3d.isLight) {
                this.lightlib.removeLight(object3d);
                this.disposeObject3d(object3d);
            }
            else {
                // let mesh = this.getTopMesh(object3d);
                for (let i = 0; i < parent.children.length; i++) {
                    let arrMesh = parent.children[i];
                    if (arrMesh.uuid === object3d.uuid) {
                        // meshes.splice(i, 1);
                        if (arrMesh.parent)
                            parent.remove(arrMesh);
                        else
                            scene.remove(arrMesh);
                        this.disposeObject3d(arrMesh);
                        for (let j = 0; j < meshes.length; j++) {
                            if (meshes[j].uuid === object3d.uuid) {
                                meshes.splice(j, 1)
                                break
                            }
                        }
                        break;
                    }
                    else {
                        if (arrMesh.children) {
                            for (let j = 0; j < arrMesh.children.length; j++) {
                                this.removeMesh(object3d, arrMesh)
                            }
                        }
                    }
                }
            }
        }
    }

    this.clearMeshes = function () {
        if (scene) {
            for (let i = 0; i < meshes.length; i++) {
                scene.remove(meshes[i]);
                this.disposeObject3d(meshes[i]);
            }
        }
        meshes = [];
    }

    /**
     * 保存场景
     * @param {是否使用JSON模式保存} useJson 
     */
    this.saveObject3d = async function ($remote, useJson = false, isPublish = false, isPackSelectOnly = false) {

        let selectTopObject3d = null;
        if (isPackSelectOnly) {
            if (this.meshControl.selectTopObject3d == null) {
                if (this.meshControl.selectObject3d == null) {
                    return null;
                }
            }
            selectTopObject3d = this.getTopMesh(this.meshControl.selectObject3d);
        }
        let fs = null;
        if ($remote) {
            fs = $remote.require("fs");
        }

        let meshClones = [];
        let meshBuffers = [];
        let meshPathes = [];
        let scripts = [];
        let meshBufferMap = [];
        let enabledSkybox = this.operation.enabledSkybox;
        if (sceneEvent) {
            sceneEvent.removeAxisForSave();
        }
        this._enableSkybox(false);
        for (let i = 0; i < meshes.length; i++) {
            let buffer = null;
            let mesh = meshes[i];
            let children = this.traverseAllChildren(mesh);

            let ext = mesh.ext; //Common.getFilePathExt(mesh.filePath);

            if (isPackSelectOnly) {
                if (selectTopObject3d != mesh) {
                    // let isContinue = true
                    // for (let j = 0; j < children.length; j++) {
                    //     if (this.meshControl.selectTopObject3d === children[j]) {
                    //         isContinue = false;
                    //         children[j].ext = ext;
                    //         children[j].arrayBuffer = mesh.arrayBuffer;
                    //         mesh = children[j];
                    //         break;
                    //     }
                    // }
                    // if (isContinue) {
                    continue;
                    // }
                }
            }
            // let meshId = mesh.userData['groupId'];
            // //如果没有分组网格ID，则取mesh的uuid作为网格buffer的唯一标识
            // if (!meshId) {
            let meshId = mesh.uuid;
            // }
            //不存在这个buffer对应的id的话，则保存入Buffer缓存
            if (!meshBufferMap[meshId]) {
                if (!mesh.filePath && !mesh.arrayBuffer) {
                    continue;
                }
                let data = null;
                if (mesh.arrayBuffer) {
                    data = mesh.arrayBuffer;
                } else {
                    if (!fs) {
                        throw new Error('fs is undefined')
                    }
                    let md5CacheFilePath = mesh.filePath.replace(ext, "");
                    data = fs.readFileSync(md5CacheFilePath).buffer;
                }
                if (useJson) {
                    buffer = Common.arrayBufferToBase64(data); //Buffer.from(mesh.meshBuffer).toString('base64');
                } else {
                    buffer = new Uint8Array(data);
                }
                meshBufferMap[meshId] = buffer;
                meshBuffers.push({ uuid: meshId, buffer: buffer });//Debug
            }
            mesh.arrayBuffer = null;

            for (let j = 0; j < children.length; j++) {
                let child = children[j];
                if (child.startScript || child.updateScript) {
                    scripts.push({ uuid: child.uuid, startScript: child.startScript, updateScript: child.updateScript });
                }
            }

            let cloneMesh = mesh.clone();
            cloneMesh.userData['cloneId'] = mesh.uuid;
            meshClones.push(cloneMesh);
            if (buffer) {
                mesh.arrayBuffer = buffer;
            }
            if (!isPublish) {
                meshPathes.push({ uuid: mesh.uuid, filePath: ext });
            }
            this.setMeshFromGroupJsonObject(mesh, { geometry: new THREE.BufferGeometry() });//Debug
        }

        if (meshClones.length === 0) {
            if (isPackSelectOnly) {
                if (this.isEditor) {
                    sceneEvent.resetAxisForLoad();
                }
                // this.addGridHelper();
                //restore
                this._enableSkybox(enabledSkybox)
                return null;
            }
        }

        // renderer.render(scene, camera);
        this.removeGridHelper();

        ///Pause Animation And Restore///
        let isAnimation = this.operation.enabledAnimation;
        this.stopAnimation();
        this.operation.enabledAnimation = isAnimation;
        ////////////////////////////////

        let objectScene = null;
        let isOpenSnow = particleEffects.isSnow;
        let isOpenRain = particleEffects.isRain;
        let isOpenLightning = particleEffects.isLightning;
        particleEffects.removeUpdateSnow();
        particleEffects.removeUpdateRain();
        particleEffects.removeUpdateLightning();
        if (isPackSelectOnly) {
            objectScene = selectTopObject3d.toJSON();
        }
        else {
            objectScene = scene.toJSON();
        }
        let packScene = null;
        if (useJson) {
            let sceneData = new SceneData({
                isPackSelectOnly: isPackSelectOnly,
                objectScene: objectScene,
                meshBuffers: meshBuffers,
                meshPathes: meshPathes,
                operation: isPackSelectOnly ? null : this.operation,
                lightIds: [this.mainLight.uuid],//, this.topLight.uuid],
                scripts: scripts,
                meshExtInfos: null,
                passInfos: isPackSelectOnly ? null : this.passLib.getAllPassInfos(),
                renderInfo: isPackSelectOnly ? null : new RenderInfo({
                    mapParams: this.mapParams,
                    toneMappingOptions: this.toneMappingOptions,
                    clearColor: this.clearColor,
                    enableShadows: this.enableShadows
                }),
                weatherInfo: {
                    isSnow: isOpenSnow,
                    isRain: isOpenRain,
                    isLightning:isOpenLightning
                }
            });
            packScene = JSON.stringify(sceneData);
        } else {
            let textureVideos = this.meshControl.textureVideos;
            // console.log(textureVideos)
            let videoTextureKeys = [];
            if (objectScene.textures) {
                for (let i = 0; i < objectScene.textures.length; i++) {
                    let tex = objectScene.textures[i];
                    if (textureVideos[tex.uuid]) {
                        videoTextureKeys[tex.image] = textureVideos[tex.uuid];
                    }
                }
            }

            // console.log(toRaw(textureVideos));
            // console.log(objectScene.images);

            if (objectScene.images) {
                let cacheUrls = [];
                for (let i = 0; i < objectScene.images.length; i++) {
                    let image = objectScene.images[i];
                    image.userData = [];
                    // // && (image.url instanceof String && image.url.indexOf('.mp4') === -1)
                    // if (!(image.url instanceof String)) { //image.url  is video element
                    //     console.log("imageUrlLength:",image.url.length)
                    //     for(let aa in image.url){
                    //         console.log(image.url[aa])
                    //     }
                    //     image.url = image.url.src
                    // }

                    if (image.url) {
                        // if (image.url.type !== "Uint8Array") {
                        //     let base64Info = image.url.split(',');
                        //     image.array = new Uint8Array(Common.base64ToArrayBuffer(base64Info[1]));
                        //     image.dataType = base64Info[0];
                        //     image.url = null;
                        // }
                        // else{
                        //     image.array = image.url;
                        // }
                        let isVideo = false;
                        if (videoTextureKeys[image.uuid]) {
                            image.url = videoTextureKeys[image.uuid];
                            isVideo = true;
                        }
                        if (typeof image.url === 'string') {

                            if (!isVideo) {
                                try {
                                    image.url = await Common.getRedrawUrl(image.url);
                                    // else{

                                    // }
                                    let cacheImageUUID = cacheUrls[image.url];
                                    if (cacheImageUUID) {
                                        image.url = null;
                                        image.userData.push({ cacheImageUUID: cacheImageUUID });
                                        objectScene.images[i] = image;
                                    } else {
                                        cacheUrls[image.url] = image.uuid;
                                    }
                                } catch (err) {
                                    console.error(err);
                                }
                            }
                        }
                        // else {
                        //     if (image.url instanceof Array) {
                        //         image.url = [];
                        //     }
                        //     else {
                        //         image.url = null
                        //     }
                        // }
                    }
                }
            }

            let sceneData = new SceneData({
                isPackSelectOnly: isPackSelectOnly,
                objectScene: objectScene,
                meshBuffers: meshBuffers,
                meshPathes: meshPathes,
                operation: isPackSelectOnly ? null : this.operation,
                lightIds: [this.mainLight.uuid],//, this.topLight.uuid],
                scripts: scripts,
                meshExtInfos: null,
                passInfos: isPackSelectOnly ? null : this.passLib.getAllPassInfos(),
                renderInfo: isPackSelectOnly ? null : new RenderInfo({
                    mapParams: this.mapParams,
                    toneMappingOptions: this.toneMappingOptions,
                    clearColor: this.clearColor,
                    enableShadows: this.enableShadows
                }),
                weatherInfo: {
                    isSnow: isOpenSnow,
                    isRain: isOpenRain,
                    isLightning:isOpenLightning
                }
            });
            packScene = pack(sceneData);
        }

        if (this.isEditor) {
            sceneEvent.resetAxisForLoad();
            this.addGridHelper();
        }
        if (isOpenSnow) {
            particleEffects.addUpdateSnow();
        }
        if (isOpenRain) {
            particleEffects.addUpdateRain();
        }
        if (isOpenLightning) {
            particleEffects.addUpdateLightning();
        }
        //Debug
        //Restore
        for (let i = 0; i < meshes.length; i++) {
            let obj = meshes[i];
            let cloneObj = meshClones[i];
            // if (!cloneObj) {
            //     continue;
            // }
            if (obj.uuid === cloneObj.userData['cloneId']) {
                this.setMeshFromGroupJsonObject(obj, cloneObj);
            }
            let children = this.traverseAllChildren(meshes[i]);
            for (let j = 0; j < children.length; j++) {
                if (children[j].uuid === cloneObj.userData['cloneId']) {
                    this.setMeshFromGroupJsonObject(children[j], cloneObj);
                }
            }
        }
        this._enableSkybox(enabledSkybox)
        if (useJson) {
            return packScene
        } else {
            // let blob = new Blob([packScene], { type: "text/plain" });
            // await this.loadScene(await blob.arrayBuffer()); //Debug
            return packScene;
        }
    }

    this.findLightFromObject = function (obj) {
        if (obj instanceof THREE.Light) {
            return obj;
        }
        if (obj.children && obj.children.length) {
            for (let i = 0; i < obj.children.length; i++) {
                return this.findLightFromObject(obj.children[i]);
            }
        } else {
            return obj;
        }
    }

    this.setObjectScript = function (obj, scripts) {
        let children = this.traverseAllChildren(obj);
        for (let k = 0; k < children.length; k++) {
            let child = children[k];
            // console.log(child.script)
            if (scripts[child.uuid]) {
                let scriptObj = scripts[child.uuid];
                child.startScript = scriptObj.startScript;
                child.updateScript = scriptObj.updateScript;
                let scriptInst = { mesh: child };
                if (child.startScript && child.startScript !== '') {
                    let startFunc = null;
                    eval(`startFunc = function(gameObject){${child.startScript}}`)
                    scriptInst.startFunc = () => {
                        scriptInst.gameObject = { mesh: scriptInst.mesh, instance: {} };
                        startFunc(scriptInst.gameObject);
                    };
                }
                if (child.updateScript && child.updateScript !== '') {
                    let updateFunc = null;
                    eval(`updateFunc = function(gameObject){${child.updateScript}}`)
                    scriptInst.updateFunc = updateFunc;
                }
                allScripts.push(scriptInst);
            }
        }
    }

    this.setVideoSource = function (obj) {
        let meshChildren = this.traverseAllChildren(obj);
        for (let j = 0; j < meshChildren.length; j++) {
            let material = meshChildren[j].material;
            if (material) {
                if (material.length) {
                    for (let k = 0; k < material.length; k++) {
                        this.setMaterialTexVideoSource(material[k]);
                    }
                } else {
                    this.setMaterialTexVideoSource(material);
                }
            }
        }
    }

    this.setSceneVideoSource = function (isPackSelectOnly) {
        if (isPackSelectOnly && this.meshControl && this.meshControl.selectObject3d) {
            let obj = this.meshControl.selectObject3d;
            if (obj instanceof THREE.Mesh || obj instanceof THREE.Group) {
                this.setVideoSource(obj);
            }
        }
        else {
            if (scene) {
                for (let i = 0; i < scene.children.length; i++) {
                    let child = scene.children[i];
                    if (child instanceof THREE.Mesh || child instanceof THREE.Group) {
                        this.setVideoSource(child);
                    }
                }
            }
        }
        // if (image.url.indexOf('.png') === -1 && image.url.indexOf('.jpg') === -1
        //     && image.url.indexOf('.jpeg') === -1 && image.url.indexOf('.bmp') === -1) {

        //     let video = document.createElement("video")
        //     video.src = image.url
        //     video.crossOrigin = ''
        //     video.loop = 'loop'
        //     video.play()
        //     image.url = video
        // }
    }

    /* eslint-disable */
    this.setMaterialTexVideoSource = function (material) {
        for (let pro in material) {
            if (eval(`material.${pro} && material.${pro}.image`)) {
                if (eval(`material.${pro}.image.src && material.${pro}.image.src.indexOf('.mp4')!==-1`)) {
                    let video = document.createElement("video")
                    video.src = eval(`material.${pro}.image.src`);
                    video.crossOrigin = ''
                    video.loop = 'loop'
                    video.play()
                    let texture = new THREE.VideoTexture(video);
                    eval(`material.${pro} = texture`);
                }
            }
        }
    }

    /**
     * 读取场景或者自定义模型
     */
    this.loadObject3d = async function (buffer, createCacheFunc, useJson = false, isEdit = true, isAddToScene = true) {
        if (!buffer) {
            return;
        }

        try {
            let sceneData = null;
            if (useJson) {
                sceneData = JSON.parse(buffer);
            } else {
                sceneData = unpack(buffer);

                let cacheImages = [];
                if (sceneData.objectScene.images) {
                    for (let i = 0; i < sceneData.objectScene.images.length; i++) {
                        let image = sceneData.objectScene.images[i];
                        cacheImages[image.uuid] = image;
                    }
                    // intercept
                    if (sceneData.objectScene.images) {
                        for (let i = 0; i < sceneData.objectScene.images.length; i++) {
                            let image = sceneData.objectScene.images[i];
                            // if (typeof image.url === "string") {
                            if (image.url == null || image.url === "") {
                                if (image.userData && image.userData.length > 0) {
                                    let sameUUID = image.userData[0].cacheImageUUID;
                                    if (!sameUUID) {
                                        continue;
                                    }
                                    image.url = cacheImages[sameUUID].url;
                                } else {
                                    continue;
                                }
                            }
                            // else if (image.url.indexOf('.png') === -1 && image.url.indexOf('.jpg') === -1
                            //     && image.url.indexOf('.jpeg') === -1 && image.url.indexOf('.bmp') === -1) {

                            //     let video = document.createElement("video")
                            //     video.src = image.url
                            //     video.crossOrigin = ''
                            //     video.loop = 'loop'
                            //     video.play()
                            //     image.url = video
                            // }
                            //Debug
                            if (!image.url || image.url === "") {
                                if (image.array) {
                                    image.url = image.dataType + "," + Common.uint8ArrayToBase64(image.array);
                                }
                            }
                            // image.url = await Common.getRedrawUrl(image.url);
                            // }
                            image.array = null;
                        }
                    }
                }
            }

            var loader = new THREE.ObjectLoader();
            let object3d = null;
            if (sceneData.isPackSelectOnly) {
                object3d = loader.parse(sceneData.objectScene);
            }
            else {
                this.destroyThreeScene();
                if (sceneData.operation) {
                    this.operation = sceneData.operation;
                }
                await this.init(threeContainer, this.meshControl, false, axisObject);
                if (sceneData.renderInfo) {

                    // this.mapParams = sceneData.renderInfo.mapParams;
                    this.toneMappingOptions = sceneData.renderInfo.toneMappingOptions;

                    this.setRendererToneMapping(sceneData.renderInfo.mapParams.toneMapping);
                    this.setRendererToneMappingExposure(sceneData.renderInfo.mapParams.exposure);

                    this.changeRendererClearColor(sceneData.renderInfo.clearColor);
                    this.renderer.shadowMap.enabled = sceneData.renderInfo.enableShadows;
                }

                scene = loader.parse(sceneData.objectScene);
                this.addGridHelper();
                for (let i = 0; i < scene.children.length; i++) {
                    let child = scene.children[i];
                    if (child instanceof THREE.Camera && !(camera instanceof THREE.CubeCamera)) {
                        camera = child;
                        break;
                    }
                }
            }


            this.setSceneVideoSource(sceneData.isPackSelectOnly);

            let meshInfos = [];
            let pathes = [],
                scripts = [];
            //设置文件路径为map
            for (let i = 0; i < sceneData.meshPathes.length; i++) {
                let path = sceneData.meshPathes[i];
                pathes[path.uuid] = path.filePath;
            }

            if (sceneData.scripts) {
                //设置Mesh挂载的脚本
                for (let i = 0; i < sceneData.scripts.length; i++) {
                    let script = sceneData.scripts[i];
                    scripts[script.uuid] = script;
                }
            }

            if (sceneData.isPackSelectOnly && sceneData.meshBuffers.length > 0) {
                if (isAddToScene) {
                    scene.add(object3d);
                }

                await this.handleObject3dIntoScene({
                    isEdit: isEdit,
                    useJson: useJson,
                    createCacheFunc: createCacheFunc,
                    uuid: object3d.uuid,
                    idx: scene ? scene.children.length - 1 : -1,
                    meshInfos: meshInfos,
                    object3d: object3d
                }, pathes, sceneData.meshBuffers[0], scripts);
            }
            else {
                this.resizeRenderer();
                this.addEffects(sceneData.passInfos);
                for (let i = 0; i < scene.children.length; i++) {
                    let obj = scene.children[i];
                    let findLight = this.findLightFromObject(obj);
                    if (findLight && findLight.isLight && !findLight.parent.isCamera) {
                        if (sceneData.lightIds[0] === findLight.uuid) {
                            this.mainLight = findLight;
                            this.mainLight.colorValue = '#' + this.mainLight.color.getHexString();
                        } else if (sceneData.lightIds[1] === findLight.uuid) {
                            this.topLight = findLight;
                            this.topLight.colorValue = '#' + this.topLight.color.getHexString();
                        }

                        let minfo = { mesh: null, meshNames: threeControl.getMeshNames(findLight) };
                        meshInfos.push(minfo);

                        if (!this.isEditor) {
                            this.lightlib.hideLightMesh(findLight);
                        }

                    } else if (obj.name == "skybox") {
                        skybox = obj;
                    } else if (obj instanceof THREE.CubeCamera) {
                        cubeCamera = obj;
                    } else if (obj.isCamera) {
                        let minfo = { mesh: null, meshNames: threeControl.getMeshNames(obj) };
                        meshInfos.push(minfo);
                    } else if (obj instanceof THREE.Group || obj instanceof THREE.Mesh) {
                        let meshId = obj.userData['groupId'];
                        let uuid = obj.uuid;
                        //如果没有分组网格ID，则取mesh的uuid作为网格buffer的唯一标识
                        if (!meshId) {
                            meshId = uuid;
                        }

                        for (let j = 0; j < sceneData.meshBuffers.length; j++) {
                            if (sceneData.meshBuffers[j].uuid == meshId) {
                                await this.handleObject3dIntoScene({
                                    isEdit: isEdit,
                                    useJson: useJson,
                                    createCacheFunc: createCacheFunc,
                                    uuid: uuid,
                                    idx: i,
                                    meshInfos: meshInfos,
                                    object3d: obj
                                }, pathes, sceneData.meshBuffers[j], scripts);
                                break;
                            }
                        }
                    }
                }

                this.saveCameraInitInfo();

                // //Create a helper for the shadow camera (optional)
                // this.topLight.position.y = 333;
                // this.topLight.shadow.camera.left = -280;
                // this.topLight.shadow.camera.right = 280;
                // this.topLight.shadow.camera.top = -280;
                // this.topLight.shadow.camera.bottom = 280;

                // console.log(cubeCamera)
                // scene.remove(cubeCamera);
                // this.initEnv();

                // this.render();

                for (let i = 0; i < meshes.length; i++) {
                    this.setMeshMaterials(meshes[i]);
                }

                // const helper = new THREE.CameraHelper(this.topLight.shadow.camera);
                // scene.add(helper);

                this.initControl();
                this.bindEvents();
                particleEffects.isSnow = sceneData.weatherInfo.isSnow ? sceneData.weatherInfo.isSnow : false;
                particleEffects.isRain = sceneData.weatherInfo.isRain ? sceneData.weatherInfo.isRain : false;
                particleEffects.isLightning = sceneData.weatherInfo.isLightning ? sceneData.weatherInfo.isLightning : false;
                this._enableSkybox(sceneData.operation.enableSkybox);
            }

            for (let i = 0; i < scripts.length; i++) {
                let exeScripts = scripts[i];
                let startFunc = exeScripts.startFunc;
                if (startFunc) {
                    startFunc();
                }
            }
            return { meshInfos: meshInfos, isPackSelectOnly: sceneData.isPackSelectOnly };
        } catch (err) {
            console.error(err)
            return [];
        }
    }


    this.capture3dScene = function () {
        this.render()
        let canvas = this.renderer.domElement
        let dataUrl = canvas.toDataURL("image/jpeg")
        return dataUrl
    }

    this.copyPropertyExceptOnly = function (src, dst, excepts) {
        //Debug
        for (let key in src) {
            if (src && src.children && dst && dst.children && src.children.length == dst.children.length) {
                for (let i = 0; i < src.children.length; i++) {
                    this.copyPropertyExceptOnly(src.children[i], dst.children[i], excepts);
                }
            }
            let isContinue = false;
            for (let i = 0; i < excepts.length; i++) {
                if (key === excepts[i]) {
                    isContinue = true;
                    break;
                }
            }
            if (isContinue) {
                continue;
            }

            if (dst && dst.hasOwnProperty(key)) {

                if (src[key] != null && src[key] instanceof Array && src[key] instanceof Array) {
                    dst[key] = []
                    for (let i = 0; i < src[key].length; i++) {
                        let srcInst = src[key][i];
                        dst[key].push(srcInst)
                    }
                }
                else {
                    let srcInst = src[key];
                    let dstInst = dst[key];
                    if (srcInst != null && srcInst instanceof THREE.Material) {
                        dst[key] = src[key]
                    }
                    else if (dstInst != null && srcInst != null && dstInst.copy) {
                        dstInst.copy(srcInst)
                    }
                    else {
                        dst[key] = src[key]
                    }
                }
            }
        }
        return dst
    }

    this.handleObject3dIntoScene = async function (params, pathes, meshBufferInfo, scripts) {

        let meshBuffer = meshBufferInfo.buffer;
        let tempBuffer = null;
        let obj = params.object3d;
        if (params.useJson) {
            tempBuffer = new Uint8Array(Common.base64ToArrayBuffer(meshBuffer));
        } else {
            tempBuffer = new Uint8Array(meshBuffer);
        }

        let cacheMd5FilePath = "";
        if (params.createCacheFunc)
            cacheMd5FilePath = await params.createCacheFunc(tempBuffer);
        else
            cacheMd5FilePath = "";
        let filePath = pathes[params.uuid] == null ? ".fbx" : pathes[params.uuid];
        // let minfo = this.addModelFromBuffer(arrayBuffer, "E:\\Model\\Car\\HDM_06_007_Ferrari\\HDM_06_007", ".fbx", false);

        let minfo = await this.addModelFromBuffer(tempBuffer.buffer, cacheMd5FilePath, Common.getFilePathExt(filePath), false, params.isEdit, false);

        // minfo.meshNames = threeControl.getMeshNames(minfo.mesh);

        // let cacheMd5Path = cacheMd5FilePath + Common.getFilePathExt(filePath);
        // obj.filePath = cacheMd5Path;
        if (params.isEdit) {
            obj.arrayBuffer = tempBuffer.buffer;
        }


        this.setObjectScript(minfo.mesh, scripts);

        // minfo.mesh = this.copyPropertyExceptOnly(obj, minfo.mesh, ['geometry', 'parent', 'children'])

        minfo.mesh = this.setMeshFromGroupJsonObject(obj, minfo.mesh);
        this.setMeshShadowsEnable(minfo.mesh, true);
        // if (params.idx >= 0) {
        //     scene.children[params.idx] = minfo.mesh
        // }

        params.obj = minfo.mesh;
        // minfo.mesh = obj;
        params.meshInfos.push(minfo);
        // if (isAddToCache) {
        // let parent = obj.parent;
        //     // console.log(parent)
        //     // console.log(scene)
        //     // console.log(minfo.mesh)
        // if (parent) {
        //     parent.add(minfo.mesh)
        // }
        meshes.push(minfo.mesh)
        minfo.meshNames = threeControl.getMeshNames(minfo.mesh);//set meshNames again
        //     else {
        //         this.addMesh(minfo.mesh)
        //     }
        // }

        this.initAnimation(minfo.mesh);
        if (this.operation.enabledAnimation) {
            this.playAnimation();
        }
        return params.meshInfos;
    }

    // this.test = function (gameObject) {

    //     //Start
    //     gameObject.instance = {
    //         flashSpeed: 3.5,
    //         flashCount: 5,
    //         delayStartSecond: 2.5,
    //         currentIntensity: 1.0,
    //         isToLarge: false,
    //         currentFlashCount: 0,
    //         currentExcuteSecond: 0
    //     }

    //     //Update
    //     let instance = gameObject.instance;

    //     let delta = gameObject.delta;
    //     if (instance.currentExcuteSecond > instance.delayStartSecond) {
    //         if (instance.currentIntensity <= 0.0) {
    //             instance.isToLarge = true;
    //             instance.currentFlashCount++;
    //         }
    //         else if (instance.currentIntensity >= 1.0) {
    //             instance.isToLarge = false;
    //             if (instance.currentFlashCount >= instance.flashCount) {
    //                 instance.currentFlashCount = 0;
    //                 instance.currentExcuteSecond = 0;
    //             }
    //         }
    //         if (instance.isToLarge) {
    //             instance.currentIntensity = Math.min(1.0, instance.currentIntensity + instance.flashSpeed * delta);
    //         }
    //         else {
    //             instance.currentIntensity = Math.max(0.0, instance.currentIntensity - instance.flashSpeed * delta);
    //         }

    //         let updateEmissionIntentsity = function (material, currentIntensity) {
    //             if (material.emissiveIntensity != null) {
    //                 if (!material.emissiveIntensityInit) {
    //                     material.emissiveIntensityInit = material.emissiveIntensity;
    //                 }
    //                 if (currentIntensity === 1.0) {
    //                     material.emissiveIntensity = material.emissiveIntensityInit;
    //                 }
    //                 else {
    //                     material.emissiveIntensity = material.emissiveIntensityInit * currentIntensity;
    //                 }
    //                 material.needsUpdate = true;
    //             }
    //         }
    //         let mesh = gameObject.mesh;
    //         let material = mesh.material;
    //         if (material) {
    //             if (material.length) {
    //                 for (let i = 0; i < material.length; i++) {
    //                     updateEmissionIntentsity(material[i], instance.currentIntensity);
    //                 }
    //             }
    //             else {
    //                 updateEmissionIntentsity(material, instance.currentIntensity);
    //             }
    //         }
    //     }

    //     instance.currentExcuteSecond += delta;
    // }


    // getGroupJsonObject(group) {
    //     let packObjectMesh = { type: group.type };
    //     if (group instanceof THREE.Group) {
    //         packObjectMesh.content = [];
    //         for (let i = 0; i < group.children.length; i++) {
    //             let child = group.children[i];
    //             if (child instanceof THREE.Group) {
    //                 let packObjectGroup = { type: child.type, name: child.name, content: [] };
    //                 packObjectGroup.content.push(this.getGroupJsonObject(child));
    //                 packObjectMesh.content.push(packObjectGroup);
    //             }
    //             else {
    //                 packObjectMesh.content.push(this.getGroupJsonObject(child));
    //             }
    //         }
    //     }
    //     else if (group instanceof THREE.Mesh) {
    //         let jsonObjectGeometry = group.geometry.toJSON();
    //         let matObjects;
    //         if (group.material.length) {
    //             matObjects = [];
    //             for (let i = 0; i < group.material.length; i++) {
    //                 matObjects.push(group.material[i].toJSON());
    //             }
    //         }
    //         else {
    //             matObjects = group.material.toJSON();
    //         }
    //         packObjectMesh = { type: group.type, name: group.name, content: { jsonObjectGeometry, matObjects } };
    //     }
    //     return packObjectMesh;
    // }

    this.disposeObject3d = function (object3d) {
        if (!object3d) {
            return
        }
        if (object3d.type === "Group") {
            for (let i = 0; i < object3d.children.length; i++) {
                let child = object3d.children[i];
                this.disposeObject3d(child);
            }
        } else if (object3d.type === "Mesh") {
            object3d.geometry.dispose();
            if (object3d.material) {
                if (object3d.material.length) {
                    for (let i = 0; i < object3d.material.length; i++) {
                        object3d.material[i].dispose();
                    }
                } else {
                    object3d.material.dispose();
                }
            }
            // mesh.geometry = null;
            // mesh.material = null;
        }
        else {
            if (object3d.children) {
                for (let i = 0; i < object3d.children.length; i++) {
                    let child = object3d.children[i];
                    this.disposeObject3d(child);
                }
            }
            else {
                object3d.dispose();
            }
        }
    }

    this.setMeshFromGroupJsonObject = function (packObjectMesh, meshTemplate) {
        if (packObjectMesh && meshTemplate) {
            if (packObjectMesh.type === "Mesh" && meshTemplate && meshTemplate.geometry) {
                packObjectMesh.geometry = meshTemplate.geometry;
            }

            // if (packObjectMesh.children)
            //     packObjectMesh.children.sort(function (a, b) { return a.id - b.id });
            // if (meshTemplate.children)
            //     meshTemplate.children.sort(function (a, b) { return a.id - b.id });

            for (let i = 0; i < packObjectMesh.children.length; i++) {
                let child = packObjectMesh.children[i];
                let childTemplate = meshTemplate;
                if (meshTemplate.children) {
                    for (let j = 0; j < meshTemplate.children.length; j++) {
                        childTemplate = meshTemplate.children[j];
                        if (childTemplate && childTemplate.name === child.name) {
                            packObjectMesh.children[i] = this.setMeshFromGroupJsonObject(child, childTemplate);
                            break;
                        }
                    }
                }
                else {
                    packObjectMesh.children[i] = this.setMeshFromGroupJsonObject(child, meshTemplate);
                }
            }
        }
        return packObjectMesh;
    }


    this.getNewMaterial = function (mat) {
        // mat.shadowSide = THREE.FrontSide;//default
        // mat.side = THREE.DoubleSide;
        // let newMat = new THREE.MeshStandardMaterial({
        //     side: THREE.DoubleSide,
        //     metalless: 0.5
        // });

        // newMat.transparent = mat.transparent;
        // newMat.opacity = mat.opacity;

        // if (mat.map) {
        //     newMat.map = mat.map;
        //     newMat.map.encoding = THREE.LinearEncoding;//使用sRGB编码
        // }
        // // else {
        // //     let img = new Image();
        // //     img.onload = function (e) {
        // //         console.log(e.target);
        // //     };
        // //     // img.onerror = function(){
        // //     //     if(newMat.map.image.width == 0 || newMat.map.image.height == 0){
        // //     //         newMat.map = null;
        // //     //         newMat.needsUpdate = true;
        // //     //     }
        // //     //     else{
        // //     //         alert(newMat.map.image.width);
        // //     //     }
        // //     // };
        // //     img.src = './model/maps/' + textureName + ".jpg";
        // //     newMat.map = new THREE.Texture(img);
        // //     newMat.map.encoding = THREE.LinearEncoding;//使用sRGB编码
        // // }
        // newMat.color = mat.color;
        // newMat.metalless = 1.0 - mat.metal;
        // // newMat.roughness = roughness;

        // mat.needsUpdate = true;

        // if (mat) {
        //     if (mat.length) {
        //         for (let i = 0; i < mat.length; i++) {
        //             for (let p in mat[i]) {
        //                 if (eval(`mat[i].${p}`) && eval(`mat[i].${p} instanceof this.THREE.Texture`)) {
        //                     console.log(eval(`mat[i].${p}`))
        //                 }
        //             }
        //         }
        //     }
        //     else {
        //         for (let p in mat) {
        //             if (eval(`mat.${p}`) && eval(`mat.${p} instanceof this.THREE.Texture`)) {
        //                 console.log(11111)
        //             }
        //         }
        //     }
        // }
        return mat;
    }

    // /* 性能插件 */
    // this.initStats = function () {

    //     if (stats) {
    //         document.body.removeChild(stats.domElement);
    //     }

    //     stats = new Stats();

    //     document.body.appendChild(stats.domElement);

    //     return stats;
    // }


    this.resizeRenderer = function () {

        if (camera) {
            camera.aspect = threeContainer.offsetWidth / threeContainer.offsetHeight;
            camera.updateProjectionMatrix();
        }
        let width = threeContainer.offsetWidth;
        let height = threeContainer.offsetHeight;
        if (this.renderer) {
            this.renderer.setSize(width, height);
        }
        this.passLib.setSize(width, height);
    }

    this.setRendererOffsetSize = function (allAddWidth, allAddHeight) {
        threeContainer.style.width = (threeContainer.offsetWidth + allAddWidth) + "px"
        threeContainer.style.height = (threeContainer.offsetHeight + allAddHeight) + "px"
        this.resizeRenderer();
    }

    this.setRendererMaxSize = function () {
        threeContainer.style.width = document.body.clientWidth + "px"
        threeContainer.style.height = document.body.clientHeight + "px"
        this.resizeRenderer();
    }

    this.setAutoRotate = function (isAutoRotate) {
        this.operation.enabledAutoRotate = isAutoRotate;
        this.controls.constraint.autoRotate = isAutoRotate;
    }

    this.restoreCameraPosition = function () {
        if (camera) {
            this.controls.constraint.autoRotate = false;
            let alpha = 0.5;

            let position = Common.lerpVector3(camera.position, cameraInitInfo.position0, alpha);
            let rotation = Common.lerpVector3(camera.rotation, cameraInitInfo.rotation0, alpha);
            let target = Common.lerpVector3(this.controls.target, this.controls.target0, alpha);
            let rDelta = Common.lerp(this.controls.constraint.getRDelta(), cameraInitInfo.rDelta, alpha);

            camera.position.copy(position);
            camera.rotation.copy(rotation);
            this.controls.target.copy(target);
            this.controls.constraint.setRDelta(rDelta);

            camera.zoom = Common.lerp(camera.zoom, cameraInitInfo.zoom0, alpha);
            camera.updateProjectionMatrix();

            if (Common.equalVector3(camera.position, cameraInitInfo.position0) &&
                Common.equalVector3(camera.rotation, cameraInitInfo.rotation0) &&
                rDelta == cameraInitInfo.rDelta &&
                Common.equalVector3(this.controls.target, this.controls.target0) &&
                camera.zoom === cameraInitInfo.zoom0
            ) {
                this.controls.constraint.autoRotate = this.operation.enabledAutoRotate;
                isRestorePosition = false;
            }
        }
    }

    this.executeUpdateScript = function (delta) {
        for (let i = 0; i < allScripts.length; i++) {
            let scriptObj = allScripts[i];
            if (scriptObj.updateFunc) {
                if (!scriptObj.gameObject) {
                    scriptObj.gameObject = { mesh: scriptObj.mesh, instance: {} };
                }
                scriptObj.gameObject.delta = delta;
                scriptObj.updateFunc(scriptObj.gameObject);
            }
        }
    }

    // this.update = function (time, frame) {
    this.update = function () {

        // if (this.isEditor) {
        //     let elem = this.renderer.domElement
        //     let overflowX = elem.offsetLeft + elem.clientWidth > document.body.clientWidth || elem.offsetLeft < 0
        //     let overflowY = elem.offsetTop + elem.clientHeight > document.body.clientHeight || elem.offsetTop < 0

        //     if (overflowX || overflowY) {
        //         return
        //     }
        // }

        // this.mesh.rotation.x = time / 2000;
        // this.mesh.rotation.y = time / 1000;
        const delta = clock.getDelta();
        if (this.controls) {
            this.controls.update(delta);
        }
        this.renderAnimation(delta);

        // if (this.mainLight) {
        //     this.mainLight.position.copy(camera.position.clone())
        //     this.mainLight.rotation.copy(camera.rotation.clone())
        // }
        if (isRestorePosition) {
            // if (!sceneEvent.isCanMoveCameraControl()) {
            //     this.restoreCameraPosition();
            // }
            // else {
            //     isRestorePosition = false;
            // }
            this.restoreCameraPosition();
        }

        if (this.isEditor) {
            sceneEvent.renderAxis()
        }
        this.lightlib.update();
        this.executeUpdateScript(delta);
        this.render();
        // if (this.mesh) {
        //     camera.lookAt(this.mesh.position);
        // }
    }

    this.render = function () {
        this.StreamerAni();
        //this.renderSnow();
        particleEffects.renderSnow();
        particleEffects.renderRain();
        
        const delta = clock.getDelta();
        this.renderer.render(scene, camera);
        particleEffects.renderLightning();
        this.passLib.render(delta);
    }

    this.StreamerAni = function () {
        scene.traverse(function (child) {
            if (child.isMesh) {
                let mateobj = child.material;
                if (mateobj) {
                    if (mateobj.type === "StreamerMaterial")
                        mateobj.uniforms['time'].value += mateobj.uniforms['speed'].value;
                }
            }
        });
    }

    this.setSceneToMove = function () {
        sceneEvent.setOperationType(-1)
    }

    this.setObjectToMove = function () {
        sceneEvent.setOperationType(0)
    }
    this.setObjectToRotate = function () {
        sceneEvent.setOperationType(1)
    }
    this.setObjectToScale = function () {
        sceneEvent.setOperationType(2)
    }

    this.setSnow = function () {
        particleEffects.isSnow = !particleEffects.isSnow;
    }

    this.setRain = function () {
        particleEffects.isRain = !particleEffects.isRain;
    }

    this.setLightning = function () {
        particleEffects.isLightning = !particleEffects.isLightning;
    }

    /* eslint-enable */
}

Threelib.prototype = {
    get passLib() {
        return this._passLib;
    }
    // set passLib(p) {
    //     passLib = p;
    // }
}

export { Threelib };