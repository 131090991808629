import * as THREE from 'three';

import { Line2 } from "../threejslib/lines/Line2"
import { LineMaterial } from "../threejslib/lines/LineMaterial"
import { LineGeometry } from "../threejslib/lines/LineGeometry"

const COORDINATENAME = "{59AEE8B3-D2F2-4F83-B78B-6D70FD30AC95}";
import { coordinateAxis, coordinateName } from '../3d/xyzline'
const coordinateCricle = "coordinateCricle"

const XYZCricle = function (parms) {

    let scene = parms.scene
    // let threeContainer = parms.threeContainer

    let topGroup = null

    this.getGroup = function () {
        return topGroup
    }

    this.setPosition = function (position) {
        topGroup.position.copy(position)
    }
    this.getPosition = function () {
        return topGroup.position
    }

    this.setScale = function (scale) {
        topGroup.scale.set(scale, scale, scale)
    }

    this.setLinesVisible = function (visible) {
        // for (let i = 0; i < allLineObjects.length; i++) {
        //     allLineObjects[i].visible = visible
        // }
        // for (let i = 0; i < allTextObjects.length; i++) {
        //     allTextObjects[i].visible = visible
        // }
        if (topGroup.visible !== visible) {
            topGroup.visible = visible
        }
    }


    this.removeLines = function () {
        if (topGroup) {
            topGroup.removeFromParent()
        }
    }

    this.addLines = function () {
        topGroup = new THREE.Group()
        scene.add(topGroup)
        topGroup.position.set(0, 0, 0)
        topGroup.rotation.set(0, 0, 0)
        // topGroup.scale.set(1, 1, 1)
    }

    this.setLinesRotation = function (point3dObject) {

       
        if (point3dObject) {
            let rot = new THREE.Euler(0, 0, 0, point3dObject.rotation.order)
            // let parent = point3dObject
            // while (parent && !parent.isScene) {
            rot.x += point3dObject.rotation.x
            rot.y += point3dObject.rotation.y
            rot.z += point3dObject.rotation.z
            //     parent = parent.parent
            // }

            topGroup.rotateX(-rot.x)
            topGroup.rotateY(-rot.y)
            topGroup.rotateZ(-rot.z)
        }
    }


    let beforeSelectCricleMats = []
    let xyzCricleMats = []
    this.drawXYZLine = function (radius = 100, segments = 64, lineWidth = 2) {

        this.setDeselectCricleMaterialEffect()
        beforeSelectCricleMats = []
        xyzCricleMats = []
        this.removeLines();
        this.addLines();

        //x轴
        let xColor = new THREE.Color(0xFF0000)
        this.drawLine("x", xColor, radius, segments, lineWidth)

        //y轴
        let yColor = new THREE.Color(0x00FF00)
        this.drawLine("y", yColor, radius, segments, lineWidth)

        //z轴
        let zColor = new THREE.Color(0x0000FF)
        this.drawLine("z", zColor, radius, segments, lineWidth)
    }


    this.getCoordinate = function (object3d) {
        if (object3d && object3d.userData[coordinateCricle]) {
            let coordinate = object3d.userData[coordinateAxis]
            // if (!coordinate) {
            //     let idx = topGroup.children.indexOf(object3d)
            //     if (idx !== -1) {
            //         let selectObject = topGroup.children[idx]
            //         coordinate = selectObject.userData[coordinateAxis]
            //     }
            // }
            if (coordinate) {
                return coordinate.toLowerCase();
            }
        }
        return null;
    }


    this.selectCricle = function (axis) {
        this.deselectEffect()
        beforeSelectCricleMats = []
        if (axis) {
            let mats = xyzCricleMats[axis]
            for (let i = 0; i < mats.length; i++) {
                this.setSelectCricleMaterialEffect(mats[i])
                beforeSelectCricleMats.push(mats[i])
            }
        }
    }

    this.setSelectCricleMaterialEffect = function (mat) {
        if (mat) {
            if (mat.isLineMaterial) {
                mat.opacity = 1
            }
            else {
                mat.opacity = 0.35
                mat.emissiveIntensity = 10
            }
            mat.needsUpdate = true
        }
    }

    this.deselectEffect = function () {
        for (let i = 0; i < beforeSelectCricleMats.length; i++) {
            this.setDeselectCricleMaterialEffect(beforeSelectCricleMats[i])
        }
    }

    this.setDeselectCricleMaterialEffect = function (mat) {
        if (mat) {
            if (mat.isLineMaterial) {
                mat.opacity = 0.5
            }
            else {
                mat.opacity = 0
                mat.emissiveIntensity = 1
            }
            mat.needsUpdate = true
        }
    }

    this.udpateLineMaterials = function () {
        for (var k in xyzCricleMats) {
            let mats = xyzCricleMats[k]
            for (let i = 0; i < mats.length; i++) {
                let mat = mats[i]
                if (mat && mat.isLineMaterial)
                    mat.resolution.set(window.innerWidth, window.innerHeight)
            }
        }
    }

    this.drawLine = function (axis, color, radius = 100, segments = 64, lineWidth = 2) {

        const lineGeometry = new LineGeometry();
        let circleGeometry = new THREE.CircleGeometry(radius, segments);

        const diskGeometry1 = new THREE.CircleGeometry(radius * 0.75, segments)
        const diskMaterial1 = new THREE.MeshPhysicalMaterial({
            transparent: true,
            opacity: 0,
            color: color,
            emissive: color,
            emissiveIntensity: 1,
            side: THREE.DoubleSide
        })
        const diskGeometry2 = new THREE.CircleGeometry(radius, segments)
        const diskMaterial2 = new THREE.MeshPhysicalMaterial({
            transparent: true,
            opacity: 0,
            color: color,
            emissive: color,
            emissiveIntensity: 1,
            side: THREE.DoubleSide
        })
        const diskMesh1 = new THREE.Mesh(diskGeometry1, diskMaterial1)
        const diskMesh2 = new THREE.Mesh(diskGeometry2, diskMaterial2)

        let array = circleGeometry.attributes.position.array;
        // console.log(array.buffer.byteLength)
        // let arrayBuffer = array.buffer.slice(3)
        // let fixArr = new Float32Array(arrayBuffer, 1)
        // console.log(fixArr)
        lineGeometry.setPositions(array, true);
        const lineMaterial = new LineMaterial({
            transparent: true,
            opacity: 0.5,
            color: color,
            linewidth: lineWidth
        });
        lineMaterial.resolution.set(window.innerWidth, window.innerHeight)
        const zenithEquator = new Line2(lineGeometry, lineMaterial);
        const mats = []
        mats.push(diskMaterial1, lineMaterial)
        xyzCricleMats[axis] = mats
        if (axis === "x") {
            zenithEquator.rotation.y = THREE.Math.degToRad(90)
            diskMesh1.rotation.y = zenithEquator.rotation.y
            diskMesh2.rotation.y = zenithEquator.rotation.y
        }
        else if (axis === "y") {
            zenithEquator.rotation.x = THREE.Math.degToRad(90)
            diskMesh1.rotation.x = zenithEquator.rotation.x
            diskMesh2.rotation.x = zenithEquator.rotation.x
        }
        else if (axis === "z") {
            zenithEquator.rotation.z = THREE.Math.degToRad(90)
            diskMesh1.rotation.z = zenithEquator.rotation.z
            diskMesh2.rotation.z = zenithEquator.rotation.z
        }

        zenithEquator.userData[coordinateAxis] = axis
        zenithEquator.userData[coordinateName] = COORDINATENAME
        zenithEquator.userData[coordinateCricle] = true

        diskMesh1.userData[coordinateAxis] = axis
        diskMesh1.userData[coordinateName] = COORDINATENAME
        diskMesh1.userData[coordinateCricle] = true

        diskMesh2.userData[coordinateAxis] = axis
        diskMesh2.userData[coordinateName] = COORDINATENAME
        diskMesh2.userData[coordinateCricle] = true
        // zenithEquator.rotation.z = THREE.Math.degToRad(90);

        
        topGroup.userData[coordinateName] = COORDINATENAME
        topGroup.userData[coordinateCricle] = true
        topGroup.add(zenithEquator);
        topGroup.add(diskMesh1);
        topGroup.add(diskMesh2);
    }
}

export { XYZCricle, coordinateName, coordinateAxis, coordinateCricle } 