<template>
  <div>
    <a-collapse class="props-collspse" v-model="activeKey">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel key="1">
        <template #header>
          <div class="header">
            <span class="title">光源配置</span>
            <a-dropdown>
            <span class="set-btn"><svg-icon icon-class="set"></svg-icon></span>
            <template #overlay>
            <a-menu class="main-menu">
              <a-menu-item>复制</a-menu-item>
              <a-menu-item>粘贴</a-menu-item>
            </a-menu>
            </template>
            </a-dropdown>
            <span class="reset-btn"><svg-icon icon-class="reset"></svg-icon></span>
          </div>
        </template>
        <template v-if="light.isLight">
        <div class="props-bar">
          <div class="label">灯光强度</div>
          <div class="props">
            <a-input
            type="number"
            v-model="light.intensity"
            step="0.01"
            :min="0"
            class="light-input"
          />
          </div>
        </div>   
        <div class="props-bar">
          <div class="label">灯光颜色</div>
          <div class="props">
            <a-input
            type="color"
            @change="changeLightColor($event, light)"
            v-model="light.lightColor"
            class="light-input"
          />
          </div>
        </div>
        </template>
        <template v-if="light.isPointLight || light.isSpotLight">
        <div class="props-bar">
          <div class="label">光照距离</div>
          <div class="props">
            <a-input
              type="number"
              v-model="light.distance"
              step="0.01"
              :min="0"
              class="light-input"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">光强衰减</div>
          <div class="props">
            <a-input
              type="number"
              v-model="light.decay"
              step="0.01"
              :min="0"
              class="light-input"
            />
          </div>
        </div>    
        </template>  
      </a-collapse-panel>
    </a-collapse>    
    <!-- <div class="max-size scroll-view">
      <div v-if="light.isLight"> -->
        <!-- <div class="light-input-group">
          <span class="light-input-label">灯光强度</span>
          <a-input
            type="number"
            v-model="light.intensity"
            step="0.01"
            :min="0"
            class="light-input"
          />
        </div> -->
        <!-- <div>
          <span class="light-input-label">灯光颜色</span>
          <a-input
            type="color"
            @change="changeLightColor($event, light)"
            v-model="light.lightColor"
            class="light-input"
          />
        </div> -->
        <!-- <div v-if="light.isPointLight || light.isSpotLight"> -->
          <!-- <div class="light-input-group">
            <span class="light-input-label">光照距离</span>
            <a-input
              type="number"
              v-model="light.distance"
              step="0.01"
              :min="0"
              class="light-input"
            />
          </div> -->
          <!-- <div class="light-input-group">
            <span class="light-input-label">光强衰减</span>
            <a-input
              type="number"
              v-model="light.decay"
              step="0.01"
              :min="0"
              class="light-input"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { MeshControl } from "@/libs/3d/mesh_control";
import * as THREE from "three";

export default {
  name: "LightProperty",
  props: {
    meshControl: MeshControl,
  },

  components: {},
  data() {
    return {
      childMeshControl: this.meshControl,
      light: {
        intensity: 0,
        lightColor: "#ffffff",
        distance: 0,
        decay: 0,
      },
      activeKey:['1']
    };
  },
  mounted() {
    // setInitLightData(this.meshControl.selectObject3d);
  },

  methods: {
    changeLightColor() {
      if (this.light) {
        this.light.color = new THREE.Color(this.light.lightColor);
        this.light.children[0].material.color = this.light.color;
      }
    },
    setInitLightData(object3d) {
      if (object3d && object3d.isLight) {
        this.light = object3d;
        this.light.lightColor = "#" + object3d.color.getHexString();
      }
    },
  },

  watch: {
    "meshControl.selectObject3d": {
      handler(newValue) {
        if (newValue instanceof Array && newValue.length > 0) {
          this.setInitLightData(newValue[0]);
        } else {
          this.setInitLightData(newValue);
        }
      },
      immediate: true,
      // deep: true
    },
  },
};
</script>

<style scoped>
.light-input-group{
    margin-top: 3px;
}

.light-input-label {
    width:30%;
    text-align: left;
    display: inline-block;
}

.light-input {
    margin-left: 1px!important;
    width: 65% !important;
    display: inline-block;
}
</style>