
import Vue from 'vue';

let isElectron = (window != null) && (window.process != null) && (window.process.versions != null) && (window.process.versions['electron'] != null)

const vueElectron = {

    setIsClient: function (isClient) {
        if (window.global) {
            window.global.isClient = isClient
        }
        else {
            window.global = {
                isClient: isClient
            }
        }
    },

    install: function () {
        // if (isElectron) {
        //     app.config.globalProperties.$electron = window.require('electron')
        //     app.config.globalProperties.$remote = window.require('@electron/remote')
        //     let path = require('path')
        //     const isDevelopment = process.env.NODE_ENV !== 'production'
        //     app.config.globalProperties.$public = isDevelopment ? path.join(process.cwd(), "public") : __dirname
        // }
        this.setIsClient(isElectron)
        // app.config.globalProperties.isClient = window.global.isClient

        if (isElectron) {
            Vue.prototype.$electron = window.require('electron')
            Vue.prototype.$remote = window.require('@electron/remote')
            let path = require('path')
            const isDevelopment = process.env.NODE_ENV !== 'production'
            Vue.prototype.$public = isDevelopment ? path.join(process.cwd(), "public") : __dirname
        }
        Vue.prototype.isClient = window.global.isClient
    },
}

export default vueElectron