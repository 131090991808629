// import { createApp } from 'vue'
// import App from './App.vue'
// import './plugins/element.js'
// import installElementPlus from './plugins/element'

import Vue from 'vue';
import vueElectron from './vue_electron.js'
import router from './router'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import "./icons";


// import Vue3ColorPicker from "vue3-colorpicker";
// import "vue3-colorpicker/style.css";


import contextmenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'

import App from './App';

Vue.config.productionTip = false;
Vue.use(Antd);

Vue.use(vueElectron)
// Vue.use(Vue3ColorPicker)
Vue.use(contextmenu);

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')


// const app = createApp(App)
// installElementPlus(app)

// app.mount('#app')