import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
// import SignIn from '../views/SignIn/index.vue'
import CreatePage from '../views/CreatePage/index.vue'
// const About = { template: '<div>About</div>' }

Vue.use(Router)  //Vue全局使用Router

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    // { path: '/', component: SignIn },
    // { path: '/edit', component: CreatePage },
    { path: '/', component: CreatePage }
]

export default new Router({
    routes: routes
});