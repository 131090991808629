<template>
  <a-tree v-bind="$attrs" :tree-data="dataList" show-icon draggable>
    <template v-slot:model>
      <svg-icon icon-class="cube"></svg-icon>
    </template>
    <template v-slot:texture>
      <svg-icon icon-class="texture"></svg-icon>
    </template>
    <template v-slot:light>
      <svg-icon icon-class="light"></svg-icon>
    </template>
    <template v-slot:camera>
      <svg-icon icon-class="cube"></svg-icon>
    </template>
  </a-tree>
</template>
<script>
export default {
  props: {
    treeData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    dataList() {
      this.addSlots(this.treeData);
      return this.treeData;
    },
  },
  methods: {
    findType(item) {
      if (item.mesh.type === "PerspectiveCamera") {
        item.slots = {
          icon: "camera",
        };
      }else if(item.mesh.type === "DirectionalLight"){
        item.slots = {
          icon: "light",
        };
      }else if(item.mesh.type === "Mesh"){
        item.slots = {
          icon: "texture",
        };
      }else if(item.mesh.type === "Object3D"){
        item.slots = {
          icon: "model",
        };
      }
    },
    addSlots(node) {
      node.forEach((item) => {
        this.findType(item);
        if (item.children && item.children.length) {
          this.addSlots(item.children);
        }
      });
    },
  },
};
</script>