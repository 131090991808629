<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>

export default {
  props:{
    iconClass: {
        type: String,
        required: true,
      },
  },
  computed:{
    iconName(){
      return `#icon-${this.iconClass}`
    },
    svgClass(){
       if (this.iconClass) {
            return `svg-icon icon-${this.iconClass}`;
          } else {
            return "svg-icon";
          }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
