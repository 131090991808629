
// import {RenderPass, EffectComposer, OutlinePass,UnrealBloomPass , ToneMappingEffect } from "three-outlinepass"
import { EffectComposer } from "../threejslib/postprocessing/EffectComposer";
// import { EffectPass } from "../threejslib/postprocessing/EffectPass";
import { RenderPass } from "../threejslib/postprocessing/RenderPass";
import { UnrealBloomPass } from "../threejslib/postprocessing/UnrealBloomPass";
import { TAARenderPass } from "../threejslib/postprocessing/TAARenderPass";
import { OutlinePass } from "../threejslib/postprocessing/OutlinePass";
// import { SMAAPass } from "../threejslib/postprocessing/SMAAPass";
// import { FXAAPass } from "../threejslib/postprocessing/FXAAPass";
import * as THREE from 'three';
// import { toRaw } from '@vue/reactivity';

const PassLib = function () {

    let composer = null;

    this.RenderPass = RenderPass;
    this.UnrealBloomPass = UnrealBloomPass;
    this.TAARenderPass = TAARenderPass;
    this.OutlinePass = OutlinePass;

    this._allPass = {
        bloomPass: {},
        taaRenderPass: {},
        outlinePass: {}
    };

    this.setSize = function (width, height) {
        if (composer) {
            composer.setSize(width, height);
        }
    }

    this.render = function (delta) {
        if (composer) {
            composer.render(delta);
        }
    }


    this.getAllPassInfos = function () {
        let passInfos = [];
        let outlinePass = this.allPass.outlinePass;
        passInfos.push({
            type: "OutlinePass",
            renderToScreen: outlinePass.renderToScreen,
            edgeStrength: outlinePass.edgeStrength,
            edgeGlow: outlinePass.edgeGlow,
            edgeThickness: outlinePass.edgeThickness,
            pulsePeriod: outlinePass.pulsePeriod,
            usePatternTexture: outlinePass.usePatternTexture,
            visibleEdgeColor: outlinePass.visibleEdgeColor,
            hiddenEdgeColor: outlinePass.hiddenEdgeColor
        })
        let bloomPass = this.allPass.bloomPass;
        passInfos.push({
            type: "UnrealBloomPass",
            threshold: bloomPass.threshold,
            strength: bloomPass.strength,
            radius: bloomPass.radius
        })
        let taaRenderPass = this.allPass.taaRenderPass;
        passInfos.push({
            type: "TAARenderPass",
            unbiased: taaRenderPass.unbiased
        })
        return passInfos;
    }

    this.renderParameters = { renderWidth: 256, renderHeight: 256, devicePixelRatio: 1 };

    this.addEffects = function (renderParameters) {

        // if (composer) {
        //     this.removeBloomPass();
        //     composer.dispose();
        //     composer = null;
        // }

        let passInfos = renderParameters.passInfos;
        this.renderParameters = renderParameters;
        if (!passInfos) {
            passInfos = [];
        }


        this.removeBloomPass();
        this.removeOutlinePass();
        this.removeTAARenderPass();

        composer = new EffectComposer(renderParameters.renderer);
        composer.setSize(renderParameters.renderWidth, renderParameters.renderHeight);
        composer.setPixelRatio(renderParameters.devicePixelRatio);
        composer.addPass(new RenderPass(renderParameters.scene, renderParameters.camera));

        // this.addTAARenderPass(renderParameters.scene, renderParameters.camera);
        this.addOutlinePass();
        this.addBloomPass();

        //如果有二次加载的passInfos信息，则对后处理循环赋值
        for (let i = 0; i < passInfos.length; i++) {
            let info = passInfos[i];
            let pass = this.getPassForType(info.type);
            if (pass != null) {
                for (let p in info) {
                    if (eval(`this.allPass.${pass}.hasOwnProperty('${p}')`)) {
                        let value = eval(`info.${p}`);
                        if (value.r && value.g && value.b) {
                            value = new THREE.Color(value.r, value.g, value.b)
                            continue;
                        }
                        eval(`this.allPass.${pass}.${p} = value`)
                    }
                }
            }
        }

        // this.addBloomPass();
        // composer.addPass(new EffectPass(camera, new ToneMappingEffect()));
        // composer.addPass(new EffectPass(camera, new BloomEffect()));
    }

    /* eslint-disable */
    this.getPassForType = function (type) {
        for (let pass in this.allPass) {
            let isGetPass = eval(`this.allPass.${pass} instanceof this.${type}`);
            if (isGetPass) {
                return pass;
            }
        }
        return null;
    }
    /* eslint-enable */

    this.setSelectObjectPass = function (meshes) {
        this.allPass.outlinePass.selectedObjects = meshes;
    }

    this.hasPass = function (passType) {
        for (let i = 0; i < composer.passes.length; i++) {
            let pass = composer.passes[i];
            if (pass instanceof passType) {
                return true;
            }
        }
        return false;
    }

    //OutlinePass Logic
    this.addOutlinePass = function () {
        if (composer) {
            // const outlinePass = new OutlinePass(new THREE.Vector2(this.renderParameters.renderWidth, this.renderParameters.renderHeight), toRaw(this.renderParameters.scene), toRaw(this.renderParameters.camera), []);

            const outlinePass = new OutlinePass(new THREE.Vector2(this.renderParameters.renderWidth, this.renderParameters.renderHeight), this.renderParameters.scene, this.renderParameters.camera, []);

            let beofreOutLinePass = this.allPass.outlinePass;
            outlinePass.renderToScreen = beofreOutLinePass.renderToScreen ? beofreOutLinePass.renderToScreen : true;
            outlinePass.edgeStrength = beofreOutLinePass.edgeStrength ? beofreOutLinePass.edgeStrength : 2; //粗
            outlinePass.edgeGlow = beofreOutLinePass.edgeGlow ? beofreOutLinePass.edgeGlow : 2; //发光
            outlinePass.edgeThickness = beofreOutLinePass.edgeThickness ? beofreOutLinePass.edgeThickness : 1; //光晕粗
            // outlinePass.pulsePeriod = beofreOutLinePass.pulsePeriod  ? beofreOutLinePass.pulsePeriod : 1; //闪烁
            outlinePass.usePatternTexture = beofreOutLinePass.usePatternTexture ? beofreOutLinePass.usePatternTexture : false; //是否使用贴图
            outlinePass.visibleEdgeColor.set(beofreOutLinePass.visibleEdgeColor ? beofreOutLinePass.visibleEdgeColor : new THREE.Color(0x00BFFF)); // 设置显示的颜色
            outlinePass.hiddenEdgeColor.set(beofreOutLinePass.hiddenEdgeColor ? beofreOutLinePass.hiddenEdgeColor : new THREE.Color(0x00BFFF)); // 设置隐藏的颜色
            this.allPass.outlinePass = outlinePass;
            composer.addPass(outlinePass);
        }
    }

    this.changeBloom = function (value) {
        this.allPass.bloomPass.strength = value.strength;
    }

    this.removeOutlinePass = function () {
        if (composer) {
            composer.removePass(this.allPass.outlinePass);
        }
    }

    this.enableOutlinePass = function (enable) {
        if (!this.hasPass(OutlinePass)) {
            this.addOutlinePass();
        }
        this.allPass.outlinePass.enabled = enable;
    }

    //Bloom Logic
    this.addBloomPass = function () {
        if (composer) {
            let beofreBloomPasss = this.allPass.bloomPass;
            const bloomPass = new UnrealBloomPass(new THREE.Vector2(this.renderParameters.renderWidth, this.renderParameters.renderHeight), 1.5, 0.4, 0.85);
            bloomPass.threshold = beofreBloomPasss.threshold ? beofreBloomPasss.threshold : 0;
            bloomPass.strength = beofreBloomPasss.strength ? beofreBloomPasss.strength : 0.1;
            bloomPass.radius = beofreBloomPasss.radius ? beofreBloomPasss.radius : 0;
            composer.addPass(bloomPass);
            this.allPass.bloomPass = bloomPass;
        }
    }

    this.removeBloomPass = function () {
        if (composer) {
            composer.removePass(this.allPass.bloomPass);
        }
    }
    this.enableBloomPass = function (enable) {
        if (!this.hasPass(UnrealBloomPass)) {
            this.addBloomPass();
        }
        this.allPass.bloomPass.enabled = enable;
    }
    this.setBloomStrength = function (strength) {
        if (this.allPass.bloomPass) {
            this.allPass.bloomPass.strength = strength;
        }
    }


    //TAA Logic
    this.addTAARenderPass = function (scene, camera) {
        if (composer) {
            let beofreTAARenderPass = this.allPass.taaRenderPass;
            const taaRenderPass = new TAARenderPass(scene, camera);
            taaRenderPass.unbiased = beofreTAARenderPass.unbiased ? beofreTAARenderPass.unbiased : false;
            this.allPass.taaRenderPass = taaRenderPass;
            composer.addPass(taaRenderPass);
        }
    }
    this.removeTAARenderPass = function () {
        if (composer) {
            composer.removePass(this.allPass.taaRenderPass);
        }
    }
    this.enableTAARenderPass = function (enable) {
        if (!this.hasPass(TAARenderPass)) {
            this.addTAARenderPass();
        }
        this.allPass.taaRenderPass.enabled = enable;
    }
}


PassLib.prototype = {
    get allPass() {
        return this._allPass;
    }
    // ,
    // set allPass(p) {
    //     _allPass = p;
    // }
}

export default PassLib;