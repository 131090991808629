

import { StreamerShader } from '../../shaders/StreamerShader.js';
import { ShaderMaterial } from 'three';

class StreamerMaterial extends ShaderMaterial {

    constructor(parameters) {
        super(parameters);

        this.type = "StreamerMaterial";
    }
}

export { StreamerMaterial, StreamerShader };