import * as THREE from 'three';

import { LightningStorm } from 'three/examples/jsm/objects/LightningStorm';


const ParticleEffects = function(parms){

    if (!parms) {
        parms = {}
    }
    let scene = parms.scene
    let camera = parms.camera

    const snowMaterials = [];
    let snowparameters = null;

    let Rainparameters = null;

    this.isSnow = false;
    this.isRain = false;
    this.isLightning = false;

    this.addUpdateSnow = function () {

        const time = Date.now() * 0.00005;
        if (snowparameters == null) {
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "SnowParticles" && object instanceof THREE.Points) {
                    scene.remove(object);
                }
            }
            const geometry = new THREE.BufferGeometry();
            const vertices = [];
            const textureLoader = new THREE.TextureLoader();
            const sprite1 = textureLoader.load(require("../../../assets/sprites/snowflake1.png"));
            const sprite2 = textureLoader.load(require("../../../assets/sprites/snowflake2.png"));
            const sprite3 = textureLoader.load(require("../../../assets/sprites/snowflake3.png"));
            const sprite4 = textureLoader.load(require("../../../assets/sprites/snowflake4.png"));
            const sprite5 = textureLoader.load(require("../../../assets/sprites/snowflake4.png"));
            for (let i = 0; i < 10000; i++) {
    
                const x = Math.random() * 2000 - 1000;
                const y = Math.random() * 2000 - 1000;
                const z = Math.random() * 2000 - 1000;
    
                vertices.push(x, y, z);
    
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            snowparameters = [
                [[1.0, 0.2, 0.5], sprite2, 20],
                [[0.95, 0.1, 0.5], sprite3, 15],
                [[0.90, 0.05, 0.5], sprite1, 10],
                [[0.85, 0, 0.5], sprite5, 8],
                [[0.80, 0, 0.5], sprite4, 5]
            ];
            for (let i = 0; i < snowparameters.length; i++) {
                const color = snowparameters[i][0];
                const sprite = snowparameters[i][1];
                const size = snowparameters[i][2];
                snowMaterials[i] = new THREE.PointsMaterial({ size: size, map: sprite, blending: THREE.AdditiveBlending, depthTest: false, transparent: true });
                snowMaterials[i].color.setHSL(color[0], color[1], color[2]);
                const particles = new THREE.Points(geometry, snowMaterials[i]);
                //particles.rotation.x = Math.random() * 6;
                //particles.rotation.y = Math.random() * 6;
                //particles.rotation.z = Math.random() * 6;
                particles.name = "SnowParticles";
                //particles.userData[coordinateName] = COORDINATENAME;
                scene.add(particles);
                // this.addMesh(particles)
            }
        } else {
            for (let i = 0; i < scene.children.length; i++) {
                const object = scene.children[i];
                if (object.name === "SnowParticles" && object instanceof THREE.Points) {
                    //object.rotation.y = time * (i < 4 ? i + 1 : - (i + 1));
                    const positions = object.geometry.attributes.position;
                    for (let j = 0; j < positions.count; j++) {
                        const px = positions.getX(j);
                        const py = positions.getY(j);
                        const pz = positions.getZ(j);
    
                        positions.setXYZ(
                            j,
                            px,
                            py <= -1000 ? 1000 : py - 0.2,
                            pz
                        );
                    }
                    positions.needsUpdate = true;
                    object.position = camera.position;
                }
            }
            for (let i = 0; i < snowMaterials.length; i++) {
                const color = snowparameters[i][0];
                const h = (360 * (color[0] + time) % 360) / 360;
                snowMaterials[i].color.setHSL(h, color[1], color[2]);
            }
        }
        this.isSnow = true;
    }
    
    this.removeUpdateSnow = function () {  
        if (snowparameters != null) {
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "SnowParticles" && object instanceof THREE.Points) {
                    scene.remove(object)
                }
            }
            this.isSnow = false;
            snowparameters = null;
        }
    }
    
    this.renderSnow = function () {
        if (this.isSnow) {
            this.addUpdateSnow();
        } else {
            this.removeUpdateSnow();
        }
    }

    this.addUpdateRain = function () {
        if (Rainparameters == null) {
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "RainParticles" && object instanceof THREE.Points) {
                    scene.remove(object);
                }
            }
            const geometry = new THREE.BufferGeometry();
            const vertices = [];
            const textureLoader = new THREE.TextureLoader();
            const sprite1 = textureLoader.load(require("../../../assets/sprites/RainStreak.png"));
            for (let i = 0; i < 10000; i++) {
    
                const x = Math.random() * 2000 - 1000;
                const y = Math.random() * 2000 - 1000;
                const z = Math.random() * 2000 - 1000;
    
                vertices.push(x, y, z);
    
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
            Rainparameters = [
                [[0.1, 4, 0.1], sprite1, 100],
            ];
            let RainMaterials = new THREE.PointsMaterial({ size:  50+Math.random() * 50, map: Rainparameters[0][1], blending: THREE.AdditiveBlending, depthTest: false, transparent: true });
            const particles = new THREE.Points(geometry, RainMaterials);
            particles.name = "RainParticles";
            scene.add(particles);
        } else {
            for (let i = 0; i < scene.children.length; i++) {
                const object = scene.children[i];
                if (object.name === "RainParticles" && object instanceof THREE.Points) {
                    //object.rotation.y = time * (i < 4 ? i + 1 : - (i + 1));
                    const positions = object.geometry.attributes.position;
                    for (let j = 0; j < positions.count; j++) {
                        const px = positions.getX(j);
                        const py = positions.getY(j);
                        const pz = positions.getZ(j);
    
                        positions.setXYZ(
                            j,
                            px,
                            py<=-1000?1000:py-10,
                            pz
                        );
                    }
                    positions.needsUpdate = true;
                    object.position = camera.position;
                }
            }
        }
        this.isRain = true;
    }
    
    this.removeUpdateRain = function () {  
        if (Rainparameters != null) {
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "RainParticles" && object instanceof THREE.Points) {
                    scene.remove(object)
                }
            }
            this.isRain = false;
            Rainparameters = null;
        }
    }
    
    this.renderRain = function () {
        if (this.isRain) {
            this.addUpdateRain();
        } else {
            this.removeUpdateRain();
        }
    }
    let storm=null;
   let currentTime=0;
    this.addUpdateLightning = function (){
        if (storm == null){
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "storm" && object instanceof LightningStorm) {
                    scene.remove(object);
                }
            }
            storm = new LightningStorm( {
                size: 1000,
                minHeight: 90,
                maxHeight: 200,
                maxSlope: 0.6,
                maxLightnings: 10,
                lightningParameters: {

                    radius0: 1,//半径
                    radius1: 0.5,//半径
                    minRadius: 0.3,//最小半径
                    maxIterations: 7,//最大迭代次数

                    timeScale: 0.15,//时标
                    propagationTimeFactor: 0.2,//传播时间因子
                    vanishingTimeFactor: 0.6,//消失时间因子
                    subrayPeriod: 4,//子射线周期
                    subrayDutyCycle: 0.6,//子射线占空比

                    maxSubrayRecursion: 3,//最大子射线递归
                    ramification: 3,//结果
                    recursionProbability: 0.4,//递归概率

                    roughness: 0.85,//粗糙度
                    straightness: 0.65,//直线度
                },
                lightningMaterial: new THREE.MeshBasicMaterial( { color: new THREE.Color( 0xB0FFFF ), side: THREE.DoubleSide } ),
            } );
            storm.name = "storm";
            scene.add( storm ); 
                
        }else{          
           currentTime+=0.05;
            storm.update( currentTime );
           storm.position =  camera.position;          
        }
    }
    this.removeUpdateLightning = function (){
        if (storm != null) {
            let count = scene.children.length - 1;
            for (count; count >= 0; count--) {
                const object = scene.children[count];
                if (object.name === "storm" && object instanceof LightningStorm) {
                    console.log(111)
                    scene.remove(object)
                }
            }
            storm = null;
        }
    }
    this.renderLightning = function () {
        if (this.isLightning) {
            this.addUpdateLightning();
            
        } else{
            this.removeUpdateLightning();
        }
    }
}
ParticleEffects.prototype.isParticleEffects = true;
export { ParticleEffects };
