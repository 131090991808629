<template>
  <div>
    <a-collapse class="props-collspse" v-model="activeKey">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel key="1">
        <template #header>
          <div class="header">
            <span class="title">脚本和其他</span>
            <a-dropdown>
              <span class="set-btn"
                ><svg-icon icon-class="set"></svg-icon
              ></span>
              <template #overlay>
                <a-menu class="main-menu">
                  <a-menu-item>复制</a-menu-item>
                  <a-menu-item>粘贴</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="reset-btn"
              ><svg-icon icon-class="reset"></svg-icon
            ></span>
          </div>
        </template>
        <div class="props-bar">
          <div class="label">网格Start脚本</div>
          <div class="props">
            <a-textarea
              :autoSize="{ minRows: 5, maxRows: 10 }"
              v-if="childMeshControl.selectObject3d != null"
              type="text"
              class="max-width"
              placeholder="let mesh = gameObject.mesh; let instance = gameObject.instance; // continue handle logic..."
              v-model="childMeshControl.selectObject3d.startScript"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">网格Update脚本</div>
          <div class="props">
            <a-textarea
              :autoSize="{ minRows: 5, maxRows: 10 }"
              v-if="childMeshControl.selectObject3d != null"
              type="text"
              class="max-width"
              placeholder="let mesh = gameObject.mesh; let instance = gameObject.instance; // continue handle logic..."
              v-model="childMeshControl.selectObject3d.updateScript"
            />
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!-- <div class="max-size scroll-view"> -->
    <!-- <div
        style="margin-top: 0.5rem"
        v-if="childMeshControl.selectObject3d != null"
      >
        <div style="float: left; margin-left: 1rem">网格Start脚本:</div>
        <a-textarea
          :autoSize="{ minRows: 5, maxRows: 10 }"
          v-if="childMeshControl.selectObject3d != null"
          type="text"
          class="max-width"
          placeholder="let mesh = gameObject.mesh; let instance = gameObject.instance; // continue handle logic..."
          v-model="childMeshControl.selectObject3d.startScript"
        />
      </div> -->
    <!-- <div
        style="margin-top: 0.5rem"
        v-if="childMeshControl.selectObject3d != null"
      >
        <div style="float: left; margin-left: 1rem">网格Update脚本:</div>
        <a-textarea
          :autoSize="{ minRows: 5, maxRows: 10 }"
          v-if="childMeshControl.selectObject3d != null"
          type="text"
          class="max-width"
          placeholder="let mesh = gameObject.mesh; let instance = gameObject.instance; // continue handle logic..."
          v-model="childMeshControl.selectObject3d.updateScript"
        />
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { MeshControl } from "../libs/3d/mesh_control";

export default {
  name: "ScriptProperty",
  props: {
    meshControl: MeshControl,
  },
  data() {
    return {
      childMeshControl: this.meshControl,
      activeKey:['1']
    };
  },
  components: {},
};
</script>

<style>
</style>
