// import msgpack from "msgpack5";
// var pack = msgpack();
// var encode = pack.encode;
// var decode = pack.decode;

const Common = {

    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode(html) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
        temp = null;
        return output;
    },


    getNodeAttr(node) {
        if (node) {
            if (node.$props) {
                return node.$props.dataRef;
            } if (node.$attrs) {
                return node.$attrs;
            }
        }
        return null;
    },


    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode(text) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    },

    // tap事件封装
    removeTapEvent(obj, callBack) {
        if (typeof obj != 'object') return;
        obj.removeEventListener('touchstart', callBack);
        obj.removeEventListener('touchmove', callBack);
        obj.removeEventListener('touchend', callBack);
    },



    getFileFromUrl(url, fileName, mime = 'application/octet-stream', responseType = 'blob') {

        return new Promise((resolve) => {
            var blob = null;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.setRequestHeader('Accept', mime);
            xhr.responseType = responseType;
            xhr.onload = () => {
                if (xhr.status == 200) {
                    blob = xhr.response;
                    let file = new File([blob], fileName, { type: mime });
                    resolve(file)
                }
            };
            xhr.send();
        })
    },

    getRedrawUrl(url, size = 2048) {
        if (url.indexOf('.psd') !== -1) {
            return url;
        }
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = () => {
                if (image.width > 0 && image.height > 0) {
                    let canvas = document.createElement("canvas");

                    canvas.width = size
                    canvas.height = size

                    canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

                    let dataURL = null;

                    if (url.indexOf(".png") !== -1 || url.indexOf(".tga") !== -1) {
                        dataURL = canvas.toDataURL("image/png")
                    }
                    else {
                        dataURL = canvas.toDataURL("image/jpeg")
                    }
                    resolve(dataURL)
                }
            };
            image.onerror = (err) => {
                window.URL.revokeObjectURL(url);
                reject(err);
            }
            image.src = url
        })
    },

    getFilePathExt(filePath) {

        let qIdx = filePath.indexOf("?");
        let sIdx = filePath.indexOf("#");
        let subIndex = filePath.length;
        if (qIdx !== -1 && sIdx !== -1) {
            subIndex = Math.min(qIdx, sIdx);
        }
        else if (qIdx !== -1) {
            subIndex = qIdx;
        }
        else if (sIdx !== -1) {
            subIndex = sIdx;
        }

        let pathInfo = [""];
        pathInfo = filePath.substring(0, subIndex).split(".");

        let ext = "";
        if (pathInfo.length > 1) {
            ext = "." + pathInfo[pathInfo.length - 1].toLowerCase();
        }
        return ext;
    },

    getFileNameWithoutExt(fileName) {

        let pathInfo = fileName.split(".");

        if (pathInfo.length > 1) {
            return pathInfo[0]
        }
        return pathInfo;
    },

    // tap事件封装
    addTapEvent(obj, callBack) {
        if (typeof obj != 'object') return;
        // 变量
        var startTime = 0; // 记录触摸开始时间
        var isMove = false; // 记录是否产生移动
        obj.addEventListener('touchstart', function () {
            startTime = Date.now();
        });
        obj.addEventListener('touchmove', function () {
            isMove = true;
        });
        obj.addEventListener('touchend', function (e) {
            if (Date.now() - startTime < 300 && !isMove) {
                //触碰时间在300ms以内,不产生移动
                callBack && callBack(e);
            }
            // 清零
            startTime = 0;
            isMove = false;
        });
    },


    //获取url中的参数
    getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
        var searchs = window.location.href.split('?');
        if (searchs.length < 1) {
            return null;
        }
        var search = "?" + this.htmlDecode(searchs[1]);
        if (!search)
            return null;
        var r = search.substr(1).match(reg); // 匹配目标参数
        if (r == null)
            return null;
        else
            return decodeURIComponent(r[2]);
    },

    isMobile() {

        var u = navigator.userAgent; // app = navigator.appVersion;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器 
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 

        return isAndroid || isiOS;
    },


    isSafari() {

        var isiOS = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        return isiOS;
    },


    base64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    },

    base64ToArrayBuffer(base64String) {
        var array = this.base64ToUint8Array(base64String);
        return array.buffer;
    },

    arrayBufferToBase64(buffer) {
        var bytes = new Uint8Array(buffer);
        var data = this.uint8ArrayToBase64(bytes);
        return data;
    },


    uint8ArrayToBase64(bytes) {
        var binary = '';
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        // binary += String.fromCharCode(...bytes);
        return window.btoa(binary);
    },

    isBase64(str) {
        if (typeof (str) != "string" || str === '' || str.trim() === '') {
            return false;
        }
        try {
            return window.btoa(window.atob(str)) == str;
        } catch (err) {
            return false;
        }
    },

    copy(source) {
        var sourceCopy = source instanceof Array ? [] : {};
        for (var item in source) {
            sourceCopy[item] = typeof source[item] === 'object' ? this.copy(source[item]) : source[item];
        }
        return sourceCopy;
    },

    getObjectName: function (url) {
        var idx = url.lastIndexOf("/") + 1;
        var name = url.substr(idx, url.length - 1);
        var dotIdx = name.indexOf(".");
        name = name.substr(0, dotIdx);
        return name;
    },

    //base64转Blob
    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    },

    //Blob转base64
    blobToDataURL(blob) {
        return new Promise((resolve, reject) => {
            try {
                let a = new FileReader();
                a.onload = function (e) {
                    resolve(e.target.result);
                }
                a.readAsDataURL(blob);
            } catch (err) {
                reject(err);
            }
        });
    },

    stringify(o) {
        var cache = [];
        var json = JSON.stringify(o, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // 移除
                    return;
                }
                // 收集所有的值
                cache.push(value);
            }
            return value;
        });
        cache = null; // 清空
        return json;
    },

    // getEncodeFixedInst(obj, cache = null) {
    //     if (cache == null) {
    //         cache = [];
    //     }
    //     if (obj !== null && (typeof obj === 'object')) {
    //         if (cache.indexOf(obj) !== -1) {
    //             // 移除
    //             return true;
    //         }
    //         // 收集所有的值
    //         cache.push(obj);
    //         for (var c in obj) {
    //             if (isNaN(parseFloat(c + ''))) {
    //                 let proval = eval('obj.' + c);
    //                 let isExistProperty = this.getEncodeFixedInst(proval, cache);
    //                 if (isExistProperty) {
    //                     console.log('1111111 ' + c + ':' + proval);
    //                     eval('delete obj.' + c);
    //                 }
    //             }
    //         }
    //     }
    //     return false;
    // },

    // msgpackEncode(obj) {
    //     this.getEncodeFixedInst(obj);
    //     return encode(obj);
    // },

    blobToArrayBuffer(blob) {
        return new Promise((resolve, reject) => {
            //将Blob 对象转换成 ArrayBuffer
            try {
                var reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = function (e) {
                    // var arr = new Uint8Array(e.target.result);
                    resolve(e.target.result);
                };
            } catch (err) {
                reject(err);
            }
        });
    },

    arrayBufferToArray(buffer) {
        var array = Array.prototype.slice.call(new Uint8Array(buffer));
        return array;
    },

    empty(elem) {
        if (elem != null) {
            while (elem.lastChild) elem.removeChild(elem.lastChild);
        }
    },

    isWechat() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    },


    isQQ() {
        var ua = navigator.userAgent;
        if (ua.match(/QQ\//i) == "QQ/") {
            return true;
        } else {
            return false;
        }
    },

    getHardWareOs() {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            return 0; //安卓
        } else if (isiOS) {
            return 1; //iOS
        } else {
            return 2; //其他
        }
        // var ua = wind7ow.navigator.userAgent.toLowerCase();
        // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //     // alert("微信");
        // } else {
        //     // alert("非微信");
        // }
    },

    // getOrientation(arrayBuffer) {
    //     var dataView = new DataView(arrayBuffer);
    //     var length = dataView.byteLength;
    //     var orientation;
    //     var exifIDCode;
    //     var tiffOffset;
    //     var firstIFDOffset;
    //     var littleEndian;
    //     var endianness;
    //     var app1Start;
    //     var ifdStart;
    //     var offset;
    //     var i;
    //     // Only handle JPEG image (start by 0xFFD8)
    //     if (dataView.getUint8(0) === 0xFF && dataView.getUint8(1) === 0xD8) {
    //         offset = 2;
    //         while (offset < length) {
    //             if (dataView.getUint8(offset) === 0xFF && dataView.getUint8(offset + 1) === 0xE1) {
    //                 app1Start = offset;
    //                 break;
    //             }
    //             offset++;
    //         }
    //     }
    //     if (app1Start) {
    //         exifIDCode = app1Start + 4;
    //         tiffOffset = app1Start + 10;
    //         if (getStringFromCharCode(dataView, exifIDCode, 4) === 'Exif') {
    //             endianness = dataView.getUint16(tiffOffset);
    //             littleEndian = endianness === 0x4949;

    //             if (littleEndian || endianness === 0x4D4D /* bigEndian */) {
    //                 if (dataView.getUint16(tiffOffset + 2, littleEndian) === 0x002A) {
    //                     firstIFDOffset = dataView.getUint32(tiffOffset + 4, littleEndian);

    //                     if (firstIFDOffset >= 0x00000008) {
    //                         ifdStart = tiffOffset + firstIFDOffset;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     if (ifdStart) {
    //         length = dataView.getUint16(ifdStart, littleEndian);

    //         for (i = 0; i < length; i++) {
    //             offset = ifdStart + i * 12 + 2;
    //             if (dataView.getUint16(offset, littleEndian) === 0x0112 /* Orientation */) {

    //                 // 8 is the offset of the current tag's value
    //                 offset += 8;

    //                 // Get the original orientation value
    //                 orientation = dataView.getUint16(offset, littleEndian);

    //                 // Override the orientation with its default value for Safari (#120)
    //                 if (Common.isSafari()) {
    //                     dataView.setUint16(offset, 1, littleEndian);
    //                 }
    //                 break;
    //             }
    //         }
    //     }
    //     return orientation;
    // },

    calcFontSizeForRem(fontSize) {

        (function (doc, win) {
            var docEl = doc.documentElement,
                resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
                recalc = function () {
                    var clientWidth = docEl.clientWidth;
                    if (!clientWidth) return;
                    docEl.style.fontSize = fontSize * (clientWidth / 375) + 'px';
                    // let htmlDom = document.getElementsByTagName('html')[0];
                    // htmlDom.style.fontSize = docEl.style.fontSize;
                    // bodyEl.style.fontSize = docEl.fontSize;
                };
            if (!doc.addEventListener) return;

            win.removeEventListener(resizeEvt, recalc, false);
            doc.removeEventListener('DOMContentLoaded', recalc, false);

            win.addEventListener(resizeEvt, recalc, false);
            doc.addEventListener('DOMContentLoaded', recalc, false);

            recalc();
        })(document, window);
    },

    //获取DPI
    getDeviceDPI() {
        var arrDPI = new Array();
        if (window.screen.deviceXDPI != undefined) {
            arrDPI[0] = window.screen.deviceXDPI;
            arrDPI[1] = window.screen.deviceYDPI;
        } else {
            var tmpNode = document.createElement("DIV");
            tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
            document.body.appendChild(tmpNode);
            arrDPI[0] = parseInt(tmpNode.offsetWidth);
            arrDPI[1] = parseInt(tmpNode.offsetHeight);
            tmpNode.parentNode.removeChild(tmpNode);
        }
        return arrDPI;

    },

    lerp(min, max, fraction, subLimit = 0.05) {
        let val = (max - min) * fraction + min;
        if (Math.abs(max - val) < subLimit) {
            val = max;
        }
        return val;
    },

    lerpVector3(minvet, maxvet, fraction, subLimit = 0.1) {
        minvet.x = this.lerp(minvet.x, maxvet.x, fraction, subLimit);
        minvet.y = this.lerp(minvet.y, maxvet.y, fraction, subLimit);
        minvet.z = this.lerp(minvet.z, maxvet.z, fraction, subLimit);
        return minvet;
    },

    equalVector3(val1, val2) {
        return val1.x === val2.x && val1.y === val2.y && val1.z === val2.z;
    },

    getStyle(dom, attr) {
        return dom.currentStyle ? dom.currentStyle[attr] : getComputedStyle(dom, false)[attr];
    },
}

//方法一扩展（C#中PadLeft、PadRight）
String.prototype.padLeft = function (len, charStr) {
    var s = this + '';
    return new Array(len - s.length + 1).join(charStr || '') + s;
}
String.prototype.padRight = function (len, charStr) {
    var s = this + '';
    return s + new Array(len - s.length + 1).join(charStr || '');
}

export default Common;