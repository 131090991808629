// In your Vue component.

import { MeshControl } from "../../libs/3d/mesh_control";
import ThreeControl from "../../libs/3d/three_control";
import { Threelib } from "../../libs/3d/threelib";

import Common from "../../libs/common";
import * as THREE from "three";
import OperationHistory from "@/libs/operation_history";

const DesignTopButtons = {
    name: "DesignTopButtons",
    components: {
    },
    props: {
        meshControl: MeshControl,
        threeControl: ThreeControl,
        threelib: Threelib,
        meshNodes: Array,
        selectTopNode: null,
    },
    data() {
        return {
            THREE: THREE,
            isLoading: false,
            saveButtonText: "生成场景",
        };
    },
    mounted() {
        this.setKeyPressEvent()
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.keyPressHandler);
    },
    methods: {

        setKeyPressEvent() {
            document.removeEventListener('keydown', this.keyPressHandler);
            document.addEventListener("keydown", this.keyPressHandler);
        },

        keyPressHandler(ev) {
            if (ev.key === "Delete") {
                if (this.meshControl.selectObject3d) {
                    this.removeMesh(this.meshControl.selectObject3d, this.meshNodes)
                }
            }
        },

        undo() {
            OperationHistory.undo()
        },

        redo() {
            OperationHistory.redo()
        },

        cloneObject3D() {
            let scope = this
            if (scope.meshControl.selectObject3d) {
                scope.meshNodes.push(
                    scope.threelib.cloneMesh(scope.meshControl.selectObject3d, true, {
                        undoCallback: (result) => {
                            for (let i = 0; i < scope.meshNodes.length; i++) {
                                let nodes = scope.meshNodes[i]
                                for (let j = 0; j < nodes.length; j++) {
                                    if (nodes[j].mesh === result.mesh) {
                                        scope.meshNodes.splice(i, 1);
                                        return
                                    }
                                }
                            }
                        },
                        redoCallback: (node) => {
                            scope.meshNodes.push(node);
                        },
                    }).meshNames
                )
                scope.threelib.selectObject(scope.meshControl.selectObject3d)
            }
        },

        async resolveSaveSceneUrl($event, isPackSelectOnly = false) {
            // href = this.$refs.downloadLinkLabel.href
            // if (href !== null && href !== "") {
            //     // this.$refs.downloadLinkLabel.style.pointerEvents = "none"
            //     // let self = this
            //     setTimeout(() => {
            //         window.URL.revokeObjectURL(href)
            //         // self.$refs.downloadLinkLabel.style.pointerEvents = ""
            //     }, 200)
            // }
            let saveIsOk = await this.threeControl.saveSceneWeb(this.threelib, this.$refs.downloadLinkLabel, isPackSelectOnly)

            if (saveIsOk) {

                let fileLink = this.$refs.downloadLinkLabel
                let href = fileLink.href
                try {
                    fileLink.click();
                }
                catch {
                    window.location.href = href;
                }

                setTimeout(() => {
                    if (href != null && href != "") {
                        window.URL.revokeObjectURL(href)
                    }
                    let url = this.threelib.capture3dScene()
                    fileLink.download = fileLink.download.replace(".mod", ".jpg").replace(".modjson", ".jpg")
                    fileLink.href = url
                    try {
                        fileLink.click();
                    }
                    catch {
                        window.location.href = href;
                    }
                }, 1)
            }
        },

        async createSceneDownloadUrl() {
            // self.$refs.downloadLinkLabel.disabled = false;
        },

        removeMesh(obj3d, nodes) {
            let isRemeved = false
            let deleteNode = null
            if (nodes) {
                if (obj3d) {
                    for (let i = 0; i < nodes.length; i++) {
                        let node = nodes[i]
                        if (node.mesh && node.mesh === obj3d) {
                            deleteNode = node;
                            break
                        }
                        else {
                            for (let j = 0; j < node.children.length; j++) {
                                // let child = node.children[j]
                                if (this.removeMesh(obj3d, node.children)) {
                                    isRemeved = true
                                    break
                                }
                            }
                        }
                        if (isRemeved) {
                            break
                        }
                    }
                }
            }
            if (deleteNode) {
                this.threelib.getSceneEvent().removeXyzGroup();
                this.threelib.removeMesh(obj3d);
                nodes.splice(nodes.indexOf(deleteNode), 1);
                this.$emit('update:selectTopNode', null);
                this.meshControl.selectObject3d = null;
                // this.meshControl.selectTopObject3d = new THREE.Mesh()// { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, scale: { x: 0, y: 0, z: 0 } }
                return true
            }
            return false
        },

        /* eslint-disable */
        async selectFile(data, ext) {

            this.isLoading = true;
            this.meshControl.initParmeters();
            // this.threelib.clearMeshes();
            // this.threelib.resetStatusParameters();
            try {
                let handlePath = "";
                if (this.isClient) {
                    handlePath = await this.threeControl.getCacheFile(
                        data,
                        this.$remote
                    );
                    data = data.buffer;
                }
                let scope = this
                this.meshNodes.push((await this.threelib.addModelFromBuffer(
                    data,
                    handlePath.replace(ext, ""),
                    ext, true, true, true, {
                    redoCallback: (result) => {
                        scope.meshNodes.push(result.meshNames)
                    },
                    undoCallback: (result) => {
                        for (let i = 0; i < scope.meshNodes.length; i++) {
                            let nodes = scope.meshNodes[i]
                            for (let j = 0; j < nodes.length; j++) {
                                if (nodes[j].mesh === result.mesh) {
                                    scope.meshNodes.splice(i, 1);
                                    return
                                }
                            }
                        }
                    }
                }
                )).meshNames
                );

            } catch (err) {
                // Notify.create({
                //   // caption: "Error",
                //   color: "red",
                //   textColor: "white",
                //   position: "top",
                //   message: err.message,
                // });
                this.$message.error(err.message);
            }
            this.isLoading = false;

        },

        async selectFileClient() {
            // async selectFile() {
            //fileList
            // const isJPG = file.type === "image/jpeg";
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //   this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            // if (!isLt2M) {
            //   this.$message.error("上传头像图片大小不能超过 2MB!");
            // }
            // return isJPG && isLt2M;
            // console.log(fileList);

            const fs = this.$remote.require("fs");
            // const app = this.remote.app;
            // const path = require("path");
            let result = await this.$remote.dialog.showOpenDialog(
                this.$remote.getCurrentWindow(), {
                properties: ["openFile"],
                filters: [
                    // { name: "Images", extensions: ["jpg", "png", "gif"] },
                    // { name: "Movies", extensions: ["mkv", "avi", "mp4"] },
                    { name: "Model Files", extensions: ["fbx"] },
                    // { name: "All Files", extensions: ["*"] },
                ],
            }
            );
            if (!result.canceled) {
                let filePath = result.filePaths[0];
                let ext = Common.getFilePathExt(filePath);
                let data = fs.readFileSync(filePath);
                await this.selectFile(data, ext)
            }

            // let buffer = await file.raw.arrayBuffer();
            // const file = result.filePaths[0];
            // mainWindow.loadURL(`file://${file}`);
            //   }
            // })
            // .catch((err) => {
            //   console.log(err);
            // });
        },

        async selectFileWeb(info) {

            let file = info.file;
            let ext = Common.getFilePathExt(file.name);
            let data = await Common.blobToArrayBuffer(file.originFileObj);
            await this.selectFile(data, ext)

            // let buffer = await file.raw.arrayBuffer();
            // const file = result.filePaths[0];
            // mainWindow.loadURL(`file://${file}`);
            //   }
            // })
            // .catch((err) => {
            //   console.log(err);
            // });
        },

        async loadSceneClient() {
            let nodes = await this.threeControl.loadSceneClient(this.threelib, this.$remote)

            for (let i = 0; i < nodes.length; i++) {
                this.meshNodes.push(nodes[i].meshNames);
            }
            this.$emit('update:meshNodes', this.meshNodes);
        },

        async loadSceneWeb(info) {
            let res = await this.threeControl.loadSceneWeb(this.threelib, info)

            let nodes = res.meshInfos
            if (!res.isPackSelectOnly) {
                this.meshNodes.splice(0, this.meshNodes.length)
            }
            if (nodes) {
                for (let i = 0; i < nodes.length; i++) {
                    this.meshNodes.push(nodes[i].meshNames)
                }
            }
            this.$emit('update:meshNodes', this.meshNodes)
        }
    },
};
export default DesignTopButtons;