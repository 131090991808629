<template>
  <div @click="click">
    <svg-icon :icon-class="isFullscreen ? 'exitFull' : 'fullscreen'" />
  </div>
</template>

<script>
import screenfull from "screenfull";

export default {
  name: "ScreenFull",
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  created() {
    this.isFullscreen = this.value;
    if (this.isScreenfull && !screenfull.isFullscreen) {
      this.isFullscreen = this.isScreenfull;
      screenfull.toggle();
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    click() {
      console.log("点击全屏");
      if (!screenfull.isEnabled) {
        this.$message({ message: "你的浏览器不支持全屏", type: "warning" });
        return false;
      }
      screenfull.toggle();
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
      this.$emit("input", this.isFullscreen);
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off("change", this.change);
      }
    },
  },
};
</script>
