
const OperationHistory = {

    // undoList: [],
    // redoList: [],
    operationIndex: 0,
    operationsList: [],

    historyEvent: null,
    mousedownEvent: null,

    // isInUndoState: false,
    // isInRedoState: false,

    bindEvents: function () {
        if (this.historyEvent) {
            window.removeEventListener("keydown", this.historyEvent)
        }
        if (this.mousedownEvent) {
            window.removeEventListener("mousedown", this.mousedownEvent)
        }

        this.historyEvent = this.handleHistory.bind(this)
        window.addEventListener("keydown", this.historyEvent)
        this.mousedownEvent = this.handleMouseDown.bind(this)
        window.addEventListener("mousedown", this.mousedownEvent)
    },

    handleMouseDown: function (ev) {
        let inputs = document.querySelectorAll("input,select,textarea")
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].blur()
        }
        ev.stopPropagation()
    },

    isFocusInputEl: function (target) {
        let isFocus = target instanceof HTMLInputElement || target instanceof HTMLSelectElement || target instanceof HTMLTextAreaElement
            || target instanceof HTMLAreaElement || target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement
        return isFocus
    },

    handleHistory: async function (ev) {

        if (ev.ctrlKey) {
            if (ev.key === "z") {
                await this.undo()
                ev.stopPropagation()
                ev.preventDefault()
            }
            else if (ev.key === "y") {
                await this.redo()
                ev.stopPropagation()
                ev.preventDefault()
            }
        }
    },

    push: function (redo, undo) {
        // this.redoList.push(redo)
        // this.undoList.push(undo)
        // this.operationsList = this.operationsList.slice(this.operationIndex)
        this.operationsList.push({ redo: redo, undo: undo })
        this.operationIndex = this.operationsList.length
    },

    undo: async function () {
        // if (this.isInRedoState) {
        //     this.redoList.push(this.undoList.pop())
        //     this.isInRedoState = false
        // }

        if (this.operationIndex > 0) {
            this.operationIndex--
            let oper = this.operationsList[this.operationIndex].undo
            // this.isInUndoState = true
            if (oper) {
                // this.redoList.push(oper)
                await oper.call()
            }
        }
        else {
            this.operationIndex = 0
        }
    },

    redo: async function () {
        // if (this.isInUndoState) {
        //     this.undoList.push(this.redoList.pop())
        //     this.isInUndoState = false
        // }

        if (this.operationIndex < this.operationsList.length) {
            let oper = this.operationsList[this.operationIndex].redo
            this.operationIndex++
            // this.isInRedoState = true
            if (oper) {
                // this.undoList.push(oper)
                await oper.call()
            }
        }
        else {
            this.operationIndex = this.operationsList.length
        }
    },

    debug: function () {
        console.log(this.histories)
    }
}

export default OperationHistory