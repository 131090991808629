<template>
  <div>
    <a-collapse class="props-collspse">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel>
        <template #header>
          <div class="header">
            <span class="title">材质配置</span>
            <a-dropdown>
              <span class="set-btn"
                ><svg-icon icon-class="set"></svg-icon
              ></span>
              <template #overlay>
                <a-menu class="main-menu">
                  <a-menu-item>复制</a-menu-item>
                  <a-menu-item>粘贴</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <span class="reset-btn"
              ><svg-icon icon-class="reset"></svg-icon
            ></span>
          </div>
        </template>
        <div class="props-bar">
          <div class="label">材质类型</div>
          <div class="props">
            <a-select
              :value="meshControl.selectMaterialType"
              class="max-width"
              style="margin-top: 0.5rem; background-color: #333333"
              v-if="meshControl.editMaterialValue.types.length > 0"
              @change="changeMaterialType($event)"
            >
              <a-select-option value="THREE.MeshPhongMaterial">
                MeshPhongMaterial
              </a-select-option>
              <a-select-option value="THREE.MeshPhysicalMaterial">
                MeshPhysicalMaterial
              </a-select-option>
              <a-select-option value="THREE.MeshBasicMaterial">
                MeshBasicMaterial
              </a-select-option>
              <a-select-option value="StreamerMaterial">
                流光材质
              </a-select-option>
            </a-select>
            <a-select
              class="max-width"
              style="margin-top: 0.5rem"
              :value="meshControl.editMaterial.side"
              @change="meshControl.changeMaterialSide($event)"
            >
              <a-select-option :value="THREE.FrontSide">
                前面材质
              </a-select-option>
              <a-select-option :value="THREE.BackSide">
                背面材质
              </a-select-option>
              <a-select-option :value="THREE.DoubleSide">
                双面材质
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="props-bar">
          <div class="label">列表</div>
          <div class="props">
            <a-list
              item-layout="horizontal"
              class="center max-width"
              :data-source="meshControl.editMaterialValue.types"
            >
              <template #renderItem="item">
                <a-list-item v-if="item.type === 'Color'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-input
                        type="color"
                        @change="meshControl.changeMaterialColor($event, item)"
                        :value="item.colorValue"
                        class="property-color"
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Combine'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div
                        class="
                          button-group-left
                          property-name property-select-label
                        "
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-select
                        :value="
                          item.value == null
                            ? THREE.MultiplyOperation
                            : item.value
                        "
                        class="max-width"
                        style="margin-top: 0.5rem"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      >
                        <a-select-option :value="THREE.MultiplyOperation">
                          MultiplyOperation
                        </a-select-option>
                        <a-select-option :value="THREE.MixOperation">
                          MixOperation
                        </a-select-option>
                        <a-select-option :value="THREE.AddOperation">
                          AddOperation
                        </a-select-option>
                      </a-select>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'BlendingDstFactor'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div
                        class="
                          button-group-left
                          property-name property-select-label
                        "
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-select
                        v-model="item.value"
                        class="max-width"
                        style="margin-top: 0.5rem"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      >
                        <a-select-option :value="THREE.ZeroFactor">
                          ZeroFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneFactor">
                          OneFactor
                        </a-select-option>
                        <a-select-option :value="THREE.SrcColorFactor">
                          SrcColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusSrcColorFactor">
                          OneMinusSrcColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.SrcAlphaFactor">
                          SrcAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusSrcAlphaFactor">
                          OneMinusSrcAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.DstAlphaFactor">
                          DstAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusDstAlphaFactor">
                          OneMinusDstAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.DstColorFactor">
                          DstColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusDstColorFactor">
                          OneMinusDstColorFactor
                        </a-select-option>
                      </a-select>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'BlendingSrcFactor'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div
                        class="
                          button-group-left
                          property-name property-select-label
                        "
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-select
                        v-model="item.value"
                        class="max-width"
                        style="margin-top: 0.5rem"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      >
                        <a-select-option :value="THREE.SrcAlphaSaturateFactor">
                          SrcAlphaSaturateFactor
                        </a-select-option>
                        <a-select-option :value="THREE.ZeroFactor">
                          ZeroFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneFactor">
                          OneFactor
                        </a-select-option>
                        <a-select-option :value="THREE.SrcColorFactor">
                          SrcColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusSrcColorFactor">
                          OneMinusSrcColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.SrcAlphaFactor">
                          SrcAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusSrcAlphaFactor">
                          OneMinusSrcAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.DstAlphaFactor">
                          DstAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusDstAlphaFactor">
                          OneMinusDstAlphaFactor
                        </a-select-option>
                        <a-select-option :value="THREE.DstColorFactor">
                          DstColorFactor
                        </a-select-option>
                        <a-select-option :value="THREE.OneMinusDstColorFactor">
                          OneMinusDstColorFactor
                        </a-select-option>
                      </a-select>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'BlendingEquation'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div
                        class="
                          button-group-left
                          property-name property-select-label
                        "
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-select
                        v-model="item.value"
                        class="max-width"
                        style="margin-top: 0.5rem"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      >
                        <a-select-option :value="THREE.AddEquation">
                          AddEquation
                        </a-select-option>
                        <a-select-option :value="THREE.SubtractEquation">
                          SubtractEquation
                        </a-select-option>
                        <a-select-option :value="THREE.ReverseSubtractEquation">
                          ReverseSubtractEquation
                        </a-select-option>
                        <a-select-option :value="THREE.MinEquation">
                          MinEquation
                        </a-select-option>
                        <a-select-option :value="THREE.MaxEquation">
                          MaxEquation
                        </a-select-option>
                      </a-select>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Blending'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div
                        class="
                          button-group-left
                          property-name property-select-label
                        "
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-select
                        v-model="item.value"
                        class="max-width"
                        style="margin-top: 0.5rem"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      >
                        <a-select-option :value="THREE.NoBlending">
                          NoBlending
                        </a-select-option>
                        <a-select-option :value="THREE.NormalBlending">
                          NormalBlending
                        </a-select-option>
                        <a-select-option :value="THREE.AdditiveBlending">
                          AdditiveBlending
                        </a-select-option>
                        <a-select-option :value="THREE.SubtractiveBlending">
                          SubtractiveBlending
                        </a-select-option>
                        <a-select-option :value="THREE.MultiplyBlending">
                          MultiplyBlending
                        </a-select-option>
                        <a-select-option :value="THREE.CustomBlending">
                          CustomBlending
                        </a-select-option>
                      </a-select>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Number'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-input
                        type="number"
                        v-model="item.value"
                        step="0.01"
                        :min="item.min"
                        :max="item.max"
                        class="property-input"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Slider'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-input
                        type="range"
                        class="property"
                        step="0.001"
                        :title="item.value"
                        v-model="item.value"
                        :min="item.min"
                        :max="item.max"
                        style="width: 5rem; padding: 0"
                        @change="
                          meshControl.changeMaterialPropertyValue(
                            $event,
                            item.name
                          )
                        "
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Boolean'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-switch
                        class="property"
                        v-model="item.value"
                        style="width: 3rem"
                        @change="
                          meshControl.changeMaterialBooleanValue(
                            $event,
                            item.name
                          )
                        "
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Texture'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-upload
                        :disabled="isClient ? 'disabled' : null"
                        @change="changeMaterialTextureValueWeb($event, item)"
                        :fileList="[]"
                        :customRequest="() => {}"
                        accept="image/jpeg,image/jpg,image/png,video/mp4"
                        class="property"
                      >
                        <a-avatar
                          shape="square"
                          :size="50"
                          style="cursor: pointer"
                          :src="item.thumbnail"
                          @contextmenu.prevent.stop="
                            meshControl.changeMaterialPropertyToNullForDelete(
                              item
                            );
                            $forceUpdate();
                          "
                          @click="
                            isClient
                              ? meshControl.changeMaterialTextureValueClient(
                                  $remote,
                                  item
                                )
                              : null
                          "
                          >none</a-avatar
                        >
                      </a-upload>
                      <div class="button-group-left">
                        <a-input
                          type="text"
                          style="padding: 1rem 2rem 0 0"
                          @change="onTextureInputChange($event, item)"
                          @paste="stopPropagation($event)"
                        >
                          <template v-slot:addonAfter>
                            <!-- <setting-filled
                      @click="
                        meshControl.changeMaterialTextureValue(
                          item.url,
                          item.name
                        )
                      "
                    /> -->
                            <a-icon
                              type="setting"
                              @click="
                                changeMaterialTextureValueWeb(
                                  item.url,
                                  item.name
                                )
                              "
                            />
                          </template>
                        </a-input>
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'String'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-input
                        type="text"
                        class="property-input"
                        v-model="item.value"
                        @change="changeItemValueForName($event, item)"
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
                <a-list-item v-else-if="item.type === 'Vector2'">
                  <a-list-item-meta description="">
                    <template #avatar>
                      <div class="button-group-left property-name">
                        {{ item.name }}
                      </div>
                    </template>
                    <template #title>
                      <a-input
                        type="number"
                        placeholder="X:"
                        v-model="item.value.x"
                        step="0.01"
                        class="property-input"
                        @change="
                          meshControl.changeMaterialXValue($event, item.name)
                        "
                      />
                      <a-input
                        type="number"
                        placeholder="Y:"
                        v-model="item.value.y"
                        step="0.01"
                        class="property-input"
                        @change="
                          meshControl.changeMaterialYValue($event, item.name)
                        "
                      />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
              <div style="height: 100px" />
            </a-list>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { MeshControl } from "../libs/3d/mesh_control";
import { Threelib } from "../libs/3d/threelib";
import * as THREE from "three";
// import SettingFilled from "@ant-design/icons-vue/SettingFilled";

export default {
  name: "MaterialPropertyList",
  props: {
    meshControl: MeshControl,
    threelib: Threelib,
  },
  data() {
    return {
      THREE: THREE,
      childMeshControl: this.meshControl,
    };
  },
  // components: { "setting-filled": SettingFilled },
  mounted() {},
  methods: {
    async changeMaterialTextureValueWeb($event, item) {
      await this.meshControl.changeMaterialTextureValueWeb($event, item);

      // let scope = this;

      // setTimeout(() => {
      this.$forceUpdate();
      // }, 30);
    },

    onTextureInputChange($event, item) {
      item.url = $event.target.value;
    },

    stopPropagation($event) {
      $event.stopPropagation();
    },

    changeItemValueForName($event, item) {
      eval(`item.${item.name} = ` + $event.target.value);
    },
    changeMaterialType($event) {
      this.$emit("changeMaterialType", $event);
    },
  },
};
</script>

<!--<style scoped>
@import url("@/components/DesignPage/designscope.css");
</style>-->
