<template>
  <div>
    <div class="top-main-div">
      <!-- <div class="panel-color"> -->
      <div class="button-group-left button-group left-middle">
        <a ref="downloadLinkLabel"> </a>
        <div
          class="button-group-left top-button save-button"
          @mousedown="isClient ? null : createSceneDownloadUrl($event)"
          @click.stop="
            isClient
              ? threeControl.saveScene(threelib, $remote)
              : resolveSaveSceneUrl($event, true)
          "
          title="保存选中"
        ></div>
        <a-upload
          :disabled="isClient ? 'disabled' : null"
          @change="selectFileWeb"
          :fileList="[]"
          :customRequest="() => {}"
          accept=".fbx"
          class="top-button button-group-left"
        >
          <div
            class="top-button upload-menu open-button"
            @click="isClient ? selectFileClient() : null"
            :disabled="isLoading"
            :loading="isLoading"
            title="导入模型"
          />
        </a-upload>
        <a-upload
          :disabled="isClient ? 'disabled' : null"
          @change="loadSceneWeb"
          :fileList="[]"
          :customRequest="() => {}"
          accept=".mod,.modjson"
          class="top-button button-group-left"
        >
          <div
            class="top-button upload-menu open-button"
            @click="isClient ? loadSceneClient() : null"
            title="导入编辑资源"
          ></div>
        </a-upload>
        <div
          class="button-group-left top-button publish-button"
          @mousedown="isClient ? null : createSceneDownloadUrl($event)"
          @click.stop="
            isClient
              ? threeControl.saveScene(threelib, $remote)
              : resolveSaveSceneUrl($event)
          "
          title="发布场景"
        ></div>
      </div>
      <div class="button-group-left button-group left-middle">
        <div
          class="button-group-left top-button undo-button"
          @click.stop="undo"
          title="撤销"
        ></div>
        <div
          class="button-group-left top-button redo-button"
          @mousedown="isClient ? null : createSceneDownloadUrl($event)"
          @click.stop="redo"
          title="前进"
        ></div>
      </div>
      <div class="button-group-left button-group left-middle left-top-main-div">
        <div
          color="secondary"
          class="top-button delete-button"
          title="删除选中对象"
          @click="removeMesh(meshControl.selectObject3d, meshNodes)"
        ></div>
        <div
          color="secondary"
          class="top-button clone-button"
          @click="cloneObject3D()"
          title="复制选中对象"
        ></div>
      </div>
      <div class="button-group-left button-group left-middle">
        <div
          class="top-button move-button"
          @click="threelib.setObjectToMove()"
          title="移动"
        ></div>
        <div
          class="top-button rotate-button"
          @click="threelib.setObjectToRotate()"
          title="旋转"
        ></div>
        <div
          class="top-button scale-button"
          @click="threelib.setObjectToScale()"
          title="缩放"
        ></div>
        <div
          class="top-button sky-button"
          @click="threelib.switchSkybox()"
          :title="
            threelib.operation.enabledSkybox ? '关闭天空盒' : '打开天空盒'
          "
        ></div>
        <div
          class="top-button scenerotate-button"
          :title="
            threelib.operation.enabledAutoRotate
              ? '关闭场景旋转'
              : '开启场景旋转'
          "
          @click="threelib.switchAutoRotate()"
        ></div>
        <div
          class="top-button animation-button"
          @click="threelib.switchAnimation()"
          :title="threelib.operation.enabledAnimation ? '关闭动画' : '开启动画'"
        ></div>

        <div
          class="top-button Snow-button"
          :title="threelib.isSnow ? '关闭下雪效果' : '开启下雪效果'"
          @click="threelib.setSnow()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
// import DesignPage from "./components/DesignPage.vue";

import DesignTopButtons from "./designtopbuttons";
export default DesignTopButtons;
</script>

<style>
</style>
