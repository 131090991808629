import * as THREE from 'three'
// import ThreeControl from "./three_control";
// import { toRaw } from '@vue/reactivity';

const LightLib = function (parms) {

    if (!parms) {
        parms = {}
    }
    let scene = parms.scene
    let lights = []

    this.update = function () {
        for (let i = 0; i < lights.length; i++) {
            let light = lights[i]
            if (light.isSpotLight) {
                spotLightLookAtTarget(light)
            }
        }
    }

    // let camera = parms.camera
    // let threeControl = new ThreeControl()
    // let meshControl = parms.meshControl

    // console.log(camera)

    this.setShadowOption = function (light, shadowOption) {
        if (shadowOption) {
            // 设置阴影分辨率
            light.shadow.mapSize.width = shadowOption.width;
            light.shadow.mapSize.height = shadowOption.height;

            // 投影近点 --> 从距离光源的哪一才产生阴影
            light.shadow.camera.near = shadowOption.near;//0.1
            // 投影原点 --> 到光源的哪一点位置不产生阴影
            light.shadow.camera.far = shadowOption.far;//300
            // 投影视场
            light.shadow.camera.fov = shadowOption.fov;//40
        }
    }

    this.addPointLight = function (shadowOption, color, intensity = 1, distance = 0, decay = 1, isAddToScene = true) {
        let sphere = new THREE.SphereBufferGeometry(1, 10, 10)
        let pointLight = new THREE.PointLight(color, intensity, distance, decay)

        this.setShadowOption(pointLight, shadowOption)

        let lightMesh = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: color }))
        lightMesh.name = "PointLight_Mesh";

        let scale = 10;
        lightMesh.scale.x = scale;
        lightMesh.scale.y = scale;
        lightMesh.scale.z = scale;
        pointLight.add(lightMesh)
        pointLight.name = "PointLight";
        // light.castShadow = isEnableShadow
        if (isAddToScene) {
            scene.add(pointLight)
        }

        lights.push(pointLight)
        return pointLight;
    }

    this.addSpotLight = function (shadowOption, color, intensity = 1, distance = 0, angle = Math.PI / 3, penumbra = 0, decay = 1, isAddToScene = true) {
        let sphere = new THREE.CylinderGeometry(10, 0, 40, 5, 5, false)
        let spotLight = new THREE.SpotLight(color, intensity, distance, angle, penumbra, decay)


        this.setShadowOption(spotLight, shadowOption)

        let lightMesh = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: color }))
        lightMesh.name = "SpotLight_Mesh";

        let scale = 1;
        lightMesh.scale.x = scale
        lightMesh.scale.y = scale
        lightMesh.scale.z = scale
        spotLight.position.y = 200
        spotLight.add(lightMesh)
        let focusTarget = new THREE.Object3D()
        focusTarget.name = "SpotLight_Target";
        spotLight.add(focusTarget)
        // focusTarget.target = focusTarget
        spotLightLookAtTarget(spotLight)
        // spotLight.target = lightMesh;
        spotLight.name = "SpotLight"
        // spotLight.rotation.x = Math.PI * 2
        // light.castShadow = isEnableShadow
        if (isAddToScene) {
            scene.add(spotLight)
        }

        spotLight.scale.y = -1
        lights.push(spotLight)
        return spotLight;
    }


    this.addDirectionLight = function (shadowOption, color, intensity = 1, isAddToScene = true) {
        let sphere = new THREE.CylinderGeometry(10, 0, 40, 5, 5, false)
        let directionalLight = new THREE.DirectionalLight(color, intensity)


        this.setShadowOption(directionalLight, shadowOption)

        let lightMesh = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: color }))
        lightMesh.name = "DirectionalLight_Mesh";

        let scale = 1;
        lightMesh.scale.x = scale
        lightMesh.scale.y = scale
        lightMesh.scale.z = scale
        directionalLight.position.y = 350
        directionalLight.add(lightMesh)
        let focusTarget = new THREE.Object3D()
        focusTarget.name = "DirectionalLight_Target";
        directionalLight.add(focusTarget)
        // focusTarget.target = focusTarget
        spotLightLookAtTarget(directionalLight)
        // spotLight.target = lightMesh;
        directionalLight.name = "DirectionalLight"
        // spotLight.rotation.x = Math.PI * 2
        // light.castShadow = isEnableShadow
        if (isAddToScene) {
            scene.add(directionalLight)
        }

        directionalLight.scale.y = -1
        lights.push(directionalLight)
        return directionalLight;
    }


    let spotLightLookAtTarget = function (spotLight) {
        let focusTarget = spotLight.children[1]

        let axis = new THREE.Euler()
        axis = axis.setFromQuaternion(spotLight.quaternion)
        // let axis = new THREE.Vector3(focusTarget.rotation.x, focusTarget.rotation.y, focusTarget.rotation.z)
        // axis = axis.normalize()

        focusTarget.position.x = spotLight.position.x
        focusTarget.position.y = spotLight.position.y
        focusTarget.position.z = spotLight.position.z
        focusTarget.translateOnAxis(axis, 1)
        // focusTarget.lookAt(spotLight.position)

        // // console.log(axis)
        // focusTarget.updateMatrixWorld();
        spotLight.target = focusTarget

        // console.log(spotLight.target)
    }

    this.removeLight = function (object3d) {
        if (object3d && object3d.isLight) {
            // object3d = toRaw(object3d)
            for (let i = 0; i < lights.length; i++) {
                if (lights[i] == object3d) {
                    lights.splice(i, 1)
                    break
                }
            }
            scene.remove(object3d)
        }
    }

    
    this.hideLightMesh = function (light) {
        if (light && light.children) {
            if (light.children.length && light.children.length > 0) {
                light.children[0].visible = false
            }
        }
    }
}

export default LightLib