// import * as THREE from 'three';

// import JSZip from "jszip";
import { v4 as uuidv4 } from 'uuid';
import { FsUtil } from "../fsutil"
import Common from "../common"

const ThreeControl = function () {

    this.getMeshNames = function (mesh, id = '1', parentId = '0') {
        if (id.split('_').length === 1) {
            id = mesh.uuid + "_" + id + "_" + uuidv4();
        }
        if (!mesh) {
            return [{ id: id, name: '无网格节点', children: [], mesh: mesh, parentId: parentId }];
        }
        let meshNames = []
        if (mesh.children) {

            let children = []
            if (mesh.children.length && mesh.children.length > 0) {
                for (let i = 0; i < mesh.children.length; i++) {
                    let child = mesh.children[i]
                    let childNames = this.getMeshNames(child, id + '-' + i.toString(), id)
                    children = children.concat(childNames)
                }
            }
            let groupInfo = { id: id, name: mesh.name == '' ? "Group(No Name)" : mesh.name, children: children, mesh: mesh, parentId: parentId }
            meshNames.push(groupInfo)
        } else {
            let meshInfo = {
                id: id,
                name: mesh.name == '' ? "Mesh(No Name)" : mesh.name,
                mesh: mesh,
                children: [],
                parentId: id
            };
            meshNames.push(meshInfo)
        }

        if (parentId === '0') {
            let children = meshNames;
            if (meshNames.length > 0 && meshNames[0].mesh === mesh) {
                children = meshNames[0].children
            }
            let result = { id: "top_" + mesh.uuid + "_" + uuidv4(), name: mesh.name == '' ? "Group(No Name)" : mesh.name, children: children, mesh: mesh, parentId: parentId }
            return result
        }
        return meshNames
    }

    this.getCacheFile = async function (data, $remote) {

        const fs = $remote.require("fs")
        const path = $remote.require("path")
        const crypto = $remote.require("crypto")

        const hash = crypto.createHash("md5")
        hash.update(data, "utf8")
        const md5 = hash.digest("hex")

        let execDirPath = path.dirname(process.execPath)
        let cacheDict = path.join(execDirPath, "Library")
        let md5FilePath = path.join(cacheDict, md5)
        if (await FsUtil.exitsFile(md5FilePath, fs)) {
            return md5FilePath
        }
        if (!(await FsUtil.createFolderIfNoExist(cacheDict, fs))) {
            throw `create Library Folder ${cacheDict} Error!`
        }
        await fs.writeFileSync(md5FilePath, data)
        return md5FilePath
    }

    this.saveSceneClient = async function (threelib, $remote, isPackSelectOnly) {
        return new Promise((resolve, reject) => {
            let useJson = false;
            if (threelib) {
                $remote.dialog.showSaveDialog({
                    title: '保存场景信息',
                    filters: [
                        { name: "Model Scene Files", extensions: ["mod"] },
                        { name: "Model Scene Json Files", extensions: ["modjson"] }
                    ]
                }).then(async (res) => {
                    if (res.canceled) {
                        resolve({ mesh: null, meshNames: [] });
                    } else {
                        const path = $remote.require("path");
                        const ext = path.extname(res.filePath).toLowerCase();
                        if (ext === '.mod') {
                            useJson = false;
                        } else if (ext === '.modjson') {
                            useJson = true;
                        }
                        let arrayBuffer = await threelib.saveObject3d($remote, useJson, false, isPackSelectOnly)
                        if (arrayBuffer) {
                            let buffer = new Uint8Array(arrayBuffer)// Buffer.from(arrayBuffer)
                            // let zip = new JSZip();
                            // zip.file("model", buffer);
                            // zip.generateAsync({
                            //     type: "arraybuffer",
                            //     // compression: "DEFLATE",
                            //     // compressionOptions: {
                            //     //     level: 5
                            //     // }
                            // }).then(function(content) {
                            //     // see FileSaver.js
                            const fs = $remote.require("fs");

                            fs.writeFileSync(res.filePath, buffer)
                            // });
                            let results = { mesh: threelib.meshControl.selectObject3d, meshNames: [] };
                            resolve(results)
                            alert("保存成功")
                        }
                    }
                }).catch((req) => {
                    console.log(req)
                    reject(req)
                })
            } else {
                resolve({ mesh: null, meshNames: [] })
            }
        })
    }

    this.saveScene = async function (threelib, $remote, isPackSelectOnly) {
        if ($remote) {
            var result = await this.saveSceneClient(threelib, $remote, isPackSelectOnly)
            if (result.mesh) {
                return true
            }
            return false;
        } else {
            return await this.saveSceneWeb(threelib, isPackSelectOnly)
        }
    }


    this.saveSceneWeb = async function (threelib, fileLink, isPackSelectOnly) {
        let arrayBuffer = await threelib.saveObject3d(null, false, false, isPackSelectOnly)
        if (arrayBuffer) {
            let blob = new Blob([arrayBuffer], { type: 'application/octet-stream' })

            let url = window.URL.createObjectURL(blob)
            fileLink.href = url
            let date = new Date()
            fileLink.download = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}${date.getMinutes()}${date.getSeconds()}.mod`;
            return true
        }
        return false
        // window.location.href = url
    }

    this.loadSceneWeb = async function (threelib, info) {
        let file = info.file
        let ext = Common.getFilePathExt(file.name)
        let data = await Common.blobToArrayBuffer(file.originFileObj)
        let arr = new Uint8Array(data)
        let res = await this.showScene(threelib, null, arr, ext, null)
        return res
    }

    this.loadSceneClient = function (threelib, $remote) {
        let self = this;
        return new Promise((resolve, reject) => {
            if (threelib) {
                $remote.dialog.showOpenDialog(
                    $remote.getCurrentWindow(), {
                    properties: ["openFile"],
                    filters: [
                        // { name: "Images", extensions: ["jpg", "png", "gif"] },
                        // { name: "Movies", extensions: ["mkv", "avi", "mp4"] },
                        { name: "Model Scene Files", extensions: ["mod"] },
                        { name: "Model Scene Json Files", extensions: ["modjson"] },
                        { name: "All Files", extensions: ["*"] }
                    ]
                }
                ).then((res) => {
                    if (res.canceled) {
                        resolve({ mesh: null, meshNames: [] });
                    } else {
                        const fs = $remote.require("fs");
                        const path = $remote.require("path");
                        const ext = path.extname(res.filePaths[0]).toLowerCase();
                        let data = fs.readFileSync(res.filePaths[0])
                        self.showScene(threelib, $remote, data, ext, resolve);
                    }
                }).catch((req) => {
                    console.log(req)
                    reject(req)
                });
            }
        })
    }

    /* eslint-disable */
    this.showScene = async function (threelib, $remote, data, ext, resolve = null) {
        // let zip = new JSZip();
        // zip.loadAsync(data).then(async(res) => {
        // let self = this;
        if (ext === '.mod') {
            // let data = await res.file("model").async("arraybuffer")
            // let buffer = Buffer.from(data);

            // let meshInfo = await threelib.loadScene(data, $remote ? async (buffer) => {
            //     return await self.getCacheFile(buffer, $remote);
            // } : null, false);


            let info = await threelib.loadObject3d(data, null, false)

            if (resolve) {
                resolve(info);
            }
            return info;
        } else if (ext === '.modjson') {
            // let data = await res.file("model").async("string")

            // let meshInfo = await threelib.loadScene(data, $remote ? async (buffer) => {
            //     return await self.getCacheFile(buffer, $remote);
            // } : null, true);

            let info = await threelib.loadObject3d(data, null, true)

            if (resolve) {
                resolve(info);
            }
            return info;
        }

        // });
    }
    /* eslint-enable */
}

export default ThreeControl;