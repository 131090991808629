const FsUtil = {

    exitsFolder: async(absPath, fs) => {
        // const absPath = path.resolve(__dirname, reaPath);
        try {
            await fs.promises.stat(absPath)
            return true
        } catch (e) {
            // 不存在文件夹，直接创建 {recursive: true} 这个配置项是配置自动创建多个文件夹
            // await fs.promises.mkdir(absPath, { recursive: true })
            return false
        }
    },

    createFolderIfNoExist: (absPath, fs) => {
        // const absPath = path.resolve(__dirname, reaPath);
        return new Promise((resolve) => {
            fs.stat(absPath, function(err1, stats) {
                if (!stats) {
                    fs.mkdir(absPath, { recursive: true }, err => {
                        if (err) {
                            resolve(false)
                            throw err;
                        }
                        resolve(true)
                    }); //Create dir in case not found
                } else {
                    resolve(true);
                }
            });
        })
    },

    exitsFile: async(path_way, fs) => {
        return new Promise((resolve) => {
            fs.access(path_way, (err) => {
                if (err) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            })
        });
    },

    createFile: async(path_way, fs) => {
        fs.appendFileSync(path_way, '', 'utf-8', (err) => {
            if (err) {
                return console.log('该文件不存在，重新创建失败！')
            }
        });
    },

    /**
     * 判断文件是否存在,如果存在，则不做任何操作，如果不存在，则创建并以UTF-8编码写入内容
     * @param {文件路径} path_way 
     * @param {*} fs 
     * @param {*} content 
     * @returns 
     */
    createFileIfNoExist: async(path_way, fs, content = '') => {
        return new Promise((resolve, reject) => {
            fs.access(path_way, (err) => {
                if (err) {
                    fs.writeFileSync(path_way, content, 'utf-8', (err) => {
                        if (err) {
                            console.log('该文件不存在，重新创建失败！')
                            return reject(false);
                        } else {
                            resolve(true);
                        }
                        fs.close()
                    });
                } else {
                    resolve(true);
                }
            })
        })
    },
}

export { FsUtil };