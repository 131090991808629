<template>
  <div class="max-size">
    <!-- <img src="./assets/logo.png" />
    <div>
      <p>
        If Element Plus is successfully added to this project, you'll see an
        <code v-text="'<el-button>'"></code>
        below
      </p>
      <el-button type="primary">el-button</el-button>
    </div> -->
    <!-- <DesignPage :resUrl="resUrl" :resInit="resInit" /> -->
    <!-- @change="callback"  force-render-->
    <!-- <a-tabs
      style="display: none"
      :default-active-key="activeKey"
      v-model="activeKey"
      class="tabs"
      @change="tabChange"
    >
      <a-tab-pane key="1" tab="首页"> 首页 </a-tab-pane>
      <a-tab-pane key="2" tab="我的场景">
        <SceneList
          :changeActiveKey="changeActiveKey"
          :changeResUrl="changeResUrl"
        />
      </a-tab-pane>
      <a-tab-pane key="3" tab="制作场景">
        <CreatePage :resUrl="resUrl" :resInit="resInit" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="我的库"> 我的库 </a-tab-pane>
      <a-tab-pane key="5" tab="商城"> <ShopList /></a-tab-pane>
      <a-tab-pane key="6" tab="设置"> 设置 </a-tab-pane>
      <a-tab-pane key="7" tab="图表Debug">
        <ChartsPage />
      </a-tab-pane>
      <a-tab-pane key="8" tab="创建"> </a-tab-pane>
    </a-tabs> -->
    <router-view></router-view>
    <footer>
      <a href="http://beian.miit.gov.cn/" class="footer-a" target="_blank"
        >闽ICP备2022001860号</a
      >
    </footer>
  </div>
</template>

<script>
var homePageRedirect = function () {
  var reg = /^(192.168.1.)/;
  if (
    window.location.hostname !== "localhost" &&
    !reg.test(window.location.hostname)
  ) {
    // 判断非本地server时 http强制转换成https
    var targetProtocol = "https:";
    if (window.location.protocol != targetProtocol) {
      var href = window.location.href.substring(
        window.location.protocol.length + 2
      );

      if (href.indexOf("www.") !== 0) {
        href = "//www." + href;
      }
      window.location.href = targetProtocol + href;
    }
  }
};

// 重定向到https，如无需可以去掉 this.$router.push('/dashboard')
homePageRedirect();
// import SignIn from "./components/ui/SignIn.vue";
// import ShopList from "./components/ui/ShopList.vue";
// import SceneList from "./components/ui/SceneList.vue";
// import DesignPage from "./components/DesignPage.vue";
// import ChartsPage from "./components/ui/ChartsPage.vue";
// import CreatePage from "./components/CreatePage.vue";

export default {
  name: "App",
  components: {
    // DesignPage,
    // SceneList,
    // ChartsPage,
    // CreatePage,
    // ShopList,
    // SignIn,
  },
  data() {
    return {
      // resUrl: "/useredit/2022-3-28 12531.mod",
      resUrl: "",
      activeKey: "3",
      resInit: false,
    };
  },
  mounted() {},
  methods: {
    tabChange(ev) {
      if (ev === "3") {
        this.setResInit();
      }
    },

    setResInit() {
      this.resInit = true;
    },
    //Debug
    changeActiveKey(key) {
      this.activeKey = key;
    },
    changeResUrl(resUrl) {
      this.resUrl = resUrl;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* margin-top: 0px; */
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.max-size {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.tabs {
  min-height: 100%;
  text-align: left;
}

.tab-heads {
  margin-left: 20px;
}

footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  height: 1.5rem;
  text-align: center;
  z-index: 999;
}

.footer-a {
  font-size: 16px;
  color: #eeeeee;
  cursor: pointer;
  /* display: none; */
  z-index: 999;
}

.ant-tabs-bar {
  margin: 0;
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  height: calc(100vh - 44px);
}
.ant-tabs-nav {
  margin-left: 25px;
}
</style>
