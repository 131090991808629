<template>
  <div>
    <div class="model-header">
      <div class="icon-checked">
        <svg-icon icon-class="cube" />
        <a-checkbox class="view-checked"></a-checkbox>
      </div>
      <a-input size="small" placeholder="名称"></a-input>
    </div>
    <a-collapse class="props-collspse" v-model="activeKey">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel key="1">
        <template #header>
          <div class="header">
            <span class="title">形态变化</span>
            <a-dropdown>
            <span class="set-btn"><svg-icon icon-class="set"></svg-icon></span>
            <template #overlay>
            <a-menu class="main-menu">
              <a-menu-item>复制</a-menu-item>
              <a-menu-item>粘贴</a-menu-item>
            </a-menu>
            </template>
            </a-dropdown>
            <span class="reset-btn"><svg-icon icon-class="reset"></svg-icon></span>
          </div>
        </template>
        <div class="props-bar">
          <div class="label">移动</div>
          <div class="props">
            <div class="prop">
              <div class="label">X</div>
              <a-input-number
                size="small"
                step="0.1"
                v-model="selectObject3d.position.x"
              />
            </div>
            <div class="prop">
              <div class="label">Y</div>
              <a-input-number
                size="small"
                step="0.1"
                v-model="selectObject3d.position.y"
              />
            </div>
            <div class="prop">
              <div class="label">Z</div>
              <a-input-number
                type="number"
                size="small"
                step="0.1"
                v-model="selectObject3d.position.z"
              />
            </div>
          </div>
        </div>
        <div class="props-bar">
          <div class="label">旋转</div>
          <div class="props">
            <div class="prop">
              <div class="label">X</div>
              <a-input-number
                size="small"
                step="0.1"
                placeholder="0"
                :value="(selectObject3d.rotation.x * 180) / Math.PI"
                @change="
                  childMeshControl.changeSelectObject3dRotationValue(
                    $event,
                    'x'
                  )
                "
              />
            </div>
            <div class="prop">
              <div class="label">Y</div>
              <a-input-number
                size="small"
                step="0.1"
                placeholder="0"
                :value="(selectObject3d.rotation.y * 180) / Math.PI"
                @change="
                  childMeshControl.changeSelectObject3dRotationValue(
                    $event,
                    'y'
                  )
                "
              />
            </div>
            <div class="prop">
              <div class="label">Z</div>
              <a-input-number
                size="small"
                step="0.1"
                placeholder="0"
                :value="(selectObject3d.rotation.z * 180) / Math.PI"
                @change="
                  childMeshControl.changeSelectObject3dRotationValue(
                    $event,
                    'z'
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="props-bar">
          <div class="label">缩放</div>
          <div class="props">
            <div class="prop">
              <div class="label">X</div>
              <a-input-number
                size="small"
                step="0.1"
                v-model="selectObject3d.scale.x"
              />
            </div>
            <div class="prop">
              <div class="label">Y</div>
              <a-input-number
                size="small"
                step="0.1"
                v-model="selectObject3d.scale.y"
              />
            </div>
            <div class="prop">
              <div class="label">Z</div>
              <a-input-number
                size="small"
                step="0.1"
                v-model="selectObject3d.scale.z"
              />
            </div>
          </div>
        </div>
        <div class="props-bar">
          <div class="label">
            <a-checkbox
              size="small"
              v-model="childThreelib.operation.enabledAutoRotate"
            >
              保存的场景自动旋转
            </a-checkbox>
          </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2">
        <template #header>
          <div class="header">
            <span class="title">模型材质</span>
            <a-dropdown>
            <span class="set-btn"><svg-icon icon-class="set"></svg-icon></span>
            <template #overlay>
            <a-menu class="main-menu">
              <a-menu-item>复制</a-menu-item>
              <a-menu-item>粘贴</a-menu-item>
            </a-menu>
            </template>
            </a-dropdown>
            <span class="reset-btn"><svg-icon icon-class="reset"></svg-icon></span>
          </div>
        </template>
        <div class="props-bar">
          <div class="label">场景背景色</div>
          <div class="props">
            <a-input
             size="small"
              type="color"
              @change="
                childThreelib.changeRendererClearColor($event.target.value)
              "
              v-model="childThreelib.clearColor"
              class="ver-div-margin input-scale-padding-left-p3"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">近距限制</div>
          <div class="props">
            <a-input-number
             size="small"
              class="ver-div-margin input-scale-padding-left-p3"
              step="0.1"
              @change="childThreelib.resetControl()"
              v-model="childThreelib.operation.minDistance"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">远距限制</div>
          <div class="props">
            <a-input-number
            size="small"
              class="ver-div-margin input-scale-padding-left-p3"
              step="0.1"
              @change="childThreelib.resetControl()"
              v-model="childThreelib.operation.maxDistance"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">上限制</div>
          <div class="props">
            <a-input
              type="range"
              class="ver-div-margin input-scale-margin-left"
              :min="0"
              :max="Math.PI"
              step="0.1"
              @change="childThreelib.resetControl()"
              :title="childThreelib.operation.minPolarAngle"
              v-model="childThreelib.operation.minPolarAngle"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">下限制</div>
          <div class="props">
            <a-input
              type="range"
              class="ver-div-margin input-scale-margin-left"
              :min="0"
              :max="Math.PI"
              step="0.1"
              :title="childThreelib.operation.maxPolarAngle"
              @change="childThreelib.resetControl()"
              v-model="childThreelib.operation.maxPolarAngle"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">左限制</div>
          <div class="props">
            <a-input
              type="text"
              pattern="([0-9]|&#8734;)+"
              class="ver-div-margin input-scale-margin-left"
              step="0.1"
              :title="childThreelib.operation.minAzimuthAngle"
              @change="childThreelib.resetControl()"
              v-model="childThreelib.operation.minAzimuthAngle"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">右限制</div>
          <div class="props">
            <a-input
              type="text"
              pattern="([0-9]|&#8734;)+"
              class="ver-div-margin input-scale-margin-left"
              step="0.1"
              :title="childThreelib.operation.maxAzimuthAngle"
              @change="childThreelib.resetControl()"
              v-model="childThreelib.operation.maxAzimuthAngle"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">主灯亮度</div>
          <div class="props">
            <a-input
              type="number"
              step="0.1"
              class="light-intensity input-property input-scale-padding-left-p5"
              v-model="childThreelib.mainLight.intensity"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">主灯颜色</div>
          <div class="props">
            <a-input
              type="color"
              class="light-color input-property input-scale-padding-left-p6"
              @change="
                meshControl.changeObjectColor($event, childThreelib.mainLight)
              "
              v-model="childThreelib.mainLight.colorValue"
            /><a-checkbox
              class="input-checkbox"
              v-model="childThreelib.mainLight.visible"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">是否开启Bloom</div>
          <div class="props">
            <a-switch
              class="property button-group-left label-margin-top-right"
              :checked="childThreelib.passLib.allPass.bloomPass.enabled"
              style="width: 3rem"
              checkedChildren=""
              unCheckedChildren=""
              @change="childThreelib.passLib.enableBloomPass($event)"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">Bloom强度</div>
          <div class="props">
            <a-input
              type="number"
              v-model="childThreelib.passLib.allPass.bloomPass.strength"
              @change="
                childThreelib.passLib.changeBloom({
                  strength: $event.target.value,
                })
              "
              step="0.1"
              min="0"
              class="property-input"
            />
          </div>
        </div>
        <div class="props-bar">
          <div class="label">Mapping 模式</div>
          <div class="props">
            <a-select
              size="small"
              v-model="childThreelib.mapParams.toneMapping"
              class="max-width"
              style="margin-top: 0.5rem"
              @change="childThreelib.setRendererToneMapping($event)"
            >
              <a-select-option value="None"> None </a-select-option>
              <a-select-option value="Linear"> Linear </a-select-option>
              <a-select-option value="Reinhard"> Reinhard </a-select-option>
              <a-select-option value="Cineon"> Cineon </a-select-option>
              <a-select-option value="ACESFilmic"> ACESFilmic </a-select-option>
              <a-select-option value="Custom"> Custom </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="props-bar">
          <div class="label">Mapping 强度</div>
          <div class="props">
            <a-input
              type="range"
              v-model="childThreelib.mapParams.exposure"
              step="0.01"
              :min="0"
              :max="10"
              :title="childThreelib.mapParams.exposure"
              style="padding: 0"
              @change="
                childThreelib.setRendererToneMappingExposure(
                  $event.target.value
                )
              "
            />
            <a-button
              color="primary"
              class="top-button button-group-left"
              @click="childThreelib.setRendererToneMappingExposure(1.0)"
            >
              Reset
            </a-button>
          </div>
        </div>
        <div class="props-bar">
          <div class="label"><a-checkbox
            style="float: left; margin-top: 5px"
            v-model="childThreelib.highlightSelect"
            @click="childThreelib.enableHighlightSelect($event.target.checked)"
          >
            选中边缘高亮（Editor)
          </a-checkbox></div>
        </div>
        <div class="props-bar">
          <div class="label"><a-checkbox
            style="float: left; margin-top: 5px"
            v-model="childThreelib.enableShadows"
            @click="childThreelib.setEnableShadows($event.target.checked)"
          >
            开启阴影
          </a-checkbox></div></div>    
      </a-collapse-panel>      
    </a-collapse>

    <!-- <div class="top-main">
      <div class="ver-div-margin"> -->
        <!-- <div class="ver-div-margin">
          <a-input
            type="number"
            prefix="顶灯亮度:"
            step="0.1"
            class="light-intensity input-property input-scale-padding-left-p5"
            v-model="childThreelib.topLight.intensity"
          />
          <a-input
            type="color"
            prefix="顶灯颜色:"
            class="light-color input-property input-scale-padding-left-p6"
            @change="
              meshControl.changeObjectColor($event, childThreelib.topLight)
            "
          />
          <a-checkbox
            class="input-checkbox"
            v-model="childThreelib.topLight.visible"
          />
        </div> -->
        <!-- <div class="ver-div-margin left-middle">
          <div class="label-margin-top-right">是否开启Bloom</div>
          <a-switch
            class="property button-group-left label-margin-top-right"
            :checked="childThreelib.passLib.allPass.bloomPass.enabled"
            style="width: 3rem"
            checkedChildren=""
            unCheckedChildren=""
            @change="childThreelib.passLib.enableBloomPass($event)"
          />
          <div class="label-margin-top-right">Bloom强度</div>
          <a-input
            type="number"
            v-model="childThreelib.passLib.allPass.bloomPass.strength"
            @change="
              childThreelib.passLib.changeBloom({
                strength: $event.target.value,
              })
            "
            step="0.1"
            min="0"
            class="property-input"
          />
        </div> -->
        <!-- <div class="ver-div-margin left-middle">
          <div class="label-margin-top-right" style="width: 8rem">
            Mapping 模式
          </div>
          <a-select
            v-model="childThreelib.mapParams.toneMapping"
            class="max-width"
            style="margin-top: 0.5rem"
            @change="childThreelib.setRendererToneMapping($event)"
          >
            <a-select-option value="None"> None </a-select-option>
            <a-select-option value="Linear"> Linear </a-select-option>
            <a-select-option value="Reinhard"> Reinhard </a-select-option>
            <a-select-option value="Cineon"> Cineon </a-select-option>
            <a-select-option value="ACESFilmic"> ACESFilmic </a-select-option>
            <a-select-option value="Custom"> Custom </a-select-option>
          </a-select>
        </div> -->
        <!-- <div class="ver-div-margin left-middle">
          <div class="label-margin-top-right" style="width: 11rem">
            Mapping 强度
          </div>
          <div style="width: 100%">
            <a-input
              type="range"
              v-model="childThreelib.mapParams.exposure"
              step="0.01"
              :min="0"
              :max="10"
              :title="childThreelib.mapParams.exposure"
              style="padding: 0"
              @change="
                childThreelib.setRendererToneMappingExposure(
                  $event.target.value
                )
              "
            />
          </div>
          <a-button
            color="primary"
            class="top-button button-group-left"
            @click="childThreelib.setRendererToneMappingExposure(1.0)"
          >
            Reset
          </a-button>
        </div> -->
        <!-- <div class="ver-div-margin left-middle">
          <a-checkbox
            style="float: left; margin-top: 5px"
            v-model="childThreelib.highlightSelect"
            @click="childThreelib.enableHighlightSelect($event.target.checked)"
          >
            选中边缘高亮（Editor)
          </a-checkbox>
        </div> -->
        <!-- <div class="ver-div-margin left-middle">
          <a-checkbox
            style="float: left; margin-top: 5px"
            v-model="childThreelib.enableShadows"
            @click="childThreelib.setEnableShadows($event.target.checked)"
          >
            开启阴影
          </a-checkbox>
        </div> -->
        <!-- <div style="height: 100px" /> -->
        <!-- <div class="ver-div-margin left-middle">
                        <div class="label-margin-top-right">
                          是否开启TAARenderer
                        </div>
                        <a-switch
                          class="
                            property
                            button-group-left
                            label-margin-top-right
                          "
                          :checked="childThreelib.passLib.allPass.taaRenderPass.enabled"
                          style="width: 3rem"
                          checkedChildren=""
                          unCheckedChildren=""
                          @change="childThreelib.passLib.enableTAARenderPass($event)"
                        />
                      </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
// import DesignPage from "./components/DesignPage.vue";
import { MeshControl } from "@/libs/3d/mesh_control";
import { Threelib } from "@/libs/3d/threelib";
import SvgIcon from "./SvgIcon.vue";

export default {
  components: { SvgIcon },
  name: "SceneProperty",
  props: {
    threelib: Threelib,
    meshControl: MeshControl,
  },

  watch: {
    "meshControl.selectObject3d": {
      handler(newValue) {
        if (newValue) {
          this.selectObject3d = newValue;
        }
      },
      immediate: true,
      // deep: true
    },
  },

  data() {
    return {
      childThreelib: this.threelib,
      childMeshControl: this.meshControl,
      selectObject3d: this.meshControl.selectObject3d
        ? this.meshControl.selectObject3d
        : {
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 0, y: 0, z: 0 },
          },
      activeKey:['1']    
    };
  },
  //   components: {
  //      DesignPage,
  //   },
};
</script>

<style lang="scss">
.model-header {
  display: flex;
  background-color: #454545;
  padding: 10px 18px;
  height: 62px;
  align-items: center;
  .icon-checked {
    width: 42px;
    height: 42px;
    line-height: 42px;
    position: relative;
    margin-right: 15px;
    .icon-cube {
      font-size: 40px;
    }
    .view-checked {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }
}
.props-collspse {
  .header{
    display: flex;
    .title{
      flex:1;
    }
    .set-btn,.reset-btn{    
      cursor: pointer;
    }
    .set-btn{margin-right: 10px;}
  }
  
  .props-bar {
    display: flex;
    color: #ccc;
    margin-bottom: 10px;
    & > .label {
      padding: 0 10px;
    }
    .props {
      flex: 1;
      display: flex;
      align-items: center;
      .ant-input-number {
        width: 60px;
      }
    }
    .prop {
      flex: 1;
      display: flex;
      & > .label {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
}
</style>
