<template>
  <div class="contianer">
    <DesignTopButtons
      ref="topControl"
      :meshControl="meshControl"
      :threeControl="threeControl"
      :threelib="threelib"
      :meshNodes="meshNodes"
      :selectTopNode="selectTopNode"
      style="display: none"
    />
    <div class="header" v-show="!isScreenfull" ref="header">
      <div class="menu-list">
        <a-dropdown :trigger="['click']">
          <span class="menu-item">文件</span>
          <template v-slot:overlay>
            <a-menu class="main-menu">
              <a-menu-item @click="init(200)"> 新建(N)...</a-menu-item>
              <a-menu-item>
                <a-upload
                  class="max-width"
                  @change="$refs.topControl.selectFileWeb"
                  :fileList="[]"
                  style="z-index: 99"
                  :customRequest="() => {}"
                  accept=".fbx"
                >
                  打开(O)...
                </a-upload></a-menu-item
              >
              <a-menu-divider />
              <a-menu-item
                @mousedown="
                  isClient
                    ? null
                    : $refs.topControl.createSceneDownloadUrl($event)
                "
                @click="
                  isClient
                    ? threeControl.saveScene(threelib, $remote)
                    : $refs.topControl.resolveSaveSceneUrl($event, true)
                "
              >
                导出(E)
              </a-menu-item>
              <a-menu-item>
                <a-upload
                  class="max-width"
                  @change="$refs.topControl.loadSceneWeb"
                  :fileList="[]"
                  style="z-index: 99"
                  :customRequest="() => {}"
                  accept=".mod,modjson"
                  >导入(I)
                </a-upload></a-menu-item
              >
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown :trigger="['click']">
          <span class="menu-item">编辑</span>
          <template #overlay>
            <a-menu class="main-menu">
              <!-- <a-menu-item>剪切(T) </a-menu-item> -->

              <a-menu-item
                @click="removeMesh(meshControl.selectObject3d, meshNodes)"
                >删除选中 (DELETE)
              </a-menu-item>

              <a-menu-item> 拷贝 (CTRL+C) </a-menu-item>
              <a-menu-item @click="$refs.topControl.cloneObject3D">
                粘贴 (CTRL+V)
              </a-menu-item>

              <a-menu-item @click.stop="undo">撤销 (CTRL+Z)</a-menu-item>

              <a-menu-item @click.stop="redo">前进 (CTRL+Y) </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown :trigger="['click']">
          <span class="menu-item">工具</span>
          <template v-slot:overlay>
            <a-menu class="main-menu">
              <a-menu-item @click="threelib.switchSkybox()"
                >{{
                  threelib.operation.enabledSkybox ? "关闭天空盒" : "打开天空盒"
                }}
              </a-menu-item>

              <a-menu-item @click="threelib.setSceneToMove()"
                >场景旋转
              </a-menu-item>

              <a-menu-item @click="threelib.setObjectToMove()"
                >平移
              </a-menu-item>

              <a-menu-item @click="threelib.setObjectToRotate()"
                >旋转
              </a-menu-item>

              <a-menu-item @click="threelib.setObjectToScale()"
                >缩放
              </a-menu-item>
              <!-- <a-menu-item @click="threelib.setSnow()"
                >{{ threelib.isSnow ? "关闭下雪效果" : "开启下雪效果" }}
              </a-menu-item> -->
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown :trigger="['click']">
          <span class="menu-item">创建</span>
          <template v-slot:overlay>
            <!-- <a-menu>
               <a-menu-item>剪切(T) </a-menu-item>
              <a-menu-item> 拷贝(C) </a-menu-item>
              <a-menu-item> 粘贴(V) </a-menu-item> 
            </a-menu>-->
          </template>
        </a-dropdown>
        <a-dropdown :trigger="['click']">
          <span class="menu-item">设置</span>
          <template v-slot:overlay>
            <!--<a-menu>
               <a-menu-item>剪切(T) </a-menu-item>
              <a-menu-item> 拷贝(C) </a-menu-item>
              <a-menu-item> 粘贴(V) </a-menu-item> 
            </a-menu>-->
          </template>
        </a-dropdown>
        <a-dropdown :trigger="['click']">
          <span class="menu-item">帮助</span>
          <template v-slot:overlay>
            <a-menu class="main-menu">
              <a-menu-item> 关于 </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="handle-bar">
        <div class="btn" @click="threelib.switchAutoRotate()">预览</div>
        <div
          class="btn"
          @mousedown="
            isClient ? null : $refs.topControl.createSceneDownloadUrl($event)
          "
          @click="
            isClient
              ? threeControl.saveScene(threelib, $remote)
              : $refs.topControl.resolveSaveSceneUrl($event)
          "
        >
          发布
        </div>
      </div>
    </div>

    <!-- 右键菜单系列 -->
    <v-contextmenu
      ref="contextmenu"
      style="width: auto"
      v-if="$refs.topControl != null"
    >
      <!-- <v-contextmenu-submenu title="打开模型" style="width: 100%"> -->
      <!-- :disabled="topControl.isClient ? 'disabled' : null" -->
      <div class="menu" @click="$refs.contextmenu.hide()">
        <a-upload
          class="max-width"
          @change="$refs.topControl.selectFileWeb"
          :fileList="[]"
          style="z-index: 99"
          :customRequest="() => {}"
          accept=".fbx"
        >
          <div class="max-width menu submenu-leftpadding">导入模型</div>
        </a-upload>
      </div>
      <div class="menu" @click="$refs.contextmenu.hide()">
        <a-upload
          class="max-width"
          @change="$refs.topControl.loadSceneWeb"
          :fileList="[]"
          style="z-index: 99"
          :customRequest="() => {}"
          accept=".mod,modjson"
        >
          <div class="max-width menu submenu-leftpadding">导入编辑资源</div>
        </a-upload>
      </div>
      <!-- </v-contextmenu-submenu> -->
      <v-contextmenu-submenu title="添加光源" class="menu">
        <v-contextmenu-item @click="addDirectionLight(null, null, 0xfff4d6, 1)"
          >全局方向光</v-contextmenu-item
        >
        <v-contextmenu-item @click="addPointLight(null, null, 0xfff4d6, 1)"
          >点光源</v-contextmenu-item
        >
        <v-contextmenu-item @click="addSpotLight(null, null, 0xfff4d6, 1, 0)"
          >聚光灯</v-contextmenu-item
        >
      </v-contextmenu-submenu>
      <v-contextmenu-item
        class="menu"
        @click="
          $refs.topControl.removeMesh(meshControl.selectObject3d, meshNodes)
        "
        >删除选中Object3D
      </v-contextmenu-item>
    </v-contextmenu>
    <div class="main">
      <splitpanes @resize="threelib.resizeRenderer()">
        <pane min-size="17" size="19" max-size="22" class="bg-color">
          <div class="left-side" v-show="!isScreenfull" ref="leftSide">
            <div class="toggle-btn" @click="toggleLeftSide">
              <svg-icon
                :icon-class="isShowLeft ? 'arrow-left' : 'arrow-right'"
              ></svg-icon>
            </div>
            <div class="box">
              <div class="tool-bar">
                <div
                  class="tool-box"
                  v-for="(group, index) in toolsList"
                  :key="index"
                >
                  <a-tooltip
                    v-for="item in group"
                    :key="item.label"
                    :placement="item.children ? 'topRight' : 'right'"
                    overlayClassName="tool-tip"
                  >
                    <template v-slot:title>
                      {{ item.label }}
                    </template>

                    <div v-if="item.disabled" class="tool-item disabled">
                      <svg-icon :icon-class="item.icon"></svg-icon>
                      <div class="expand">
                        <i class="tri" @click="toggleTool(item)"></i>
                      </div>
                      <div class="more-tool" v-if="item.isShowMore">
                        <div
                          class="tool-item"
                          v-for="child in item.children"
                          :key="child.label"
                          @click="checkedAlign(item, child)"
                        >
                          <svg-icon :icon-class="child.icon"></svg-icon>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="tool-item"
                      :class="[
                        activeTool === item.label ? 'active' : '',
                        item.children ? 'more' : '',
                      ]"
                      @click="handleTools(item)"
                    >
                      <svg-icon :icon-class="item.icon"></svg-icon>
                      <div class="expand">
                        <i class="tri" @click="toggleTool(item)"></i>
                      </div>
                      <div class="more-tool" v-if="item.isShowMore">
                        <div
                          class="tool-item"
                          v-for="child in item.children"
                          :key="child.label"
                          @click="checkedAlign(item, child)"
                        >
                          <svg-icon :icon-class="child.icon"></svg-icon>
                        </div>
                      </div>
                    </div>
                  </a-tooltip>
                </div>
              </div>
              <div class="component-box" v-show="isShowLeft">
                <ul class="tab">
                  <li
                    :class="{ active: activeTab === '组件' }"
                    @click="handleTab('组件')"
                    class="default-cursor no-select"
                  >
                    组件
                  </li>
                  <li
                    :class="{ active: activeTab === '大纲' }"
                    @click="handleTab('大纲')"
                    class="default-cursor no-select"
                  >
                    大纲
                  </li>
                </ul>
                <div class="tab-panel">
                  <!--组件-->
                  <div class="component-content" v-if="activeTab === '组件'">
                    <div class="search-bar">
                      <div class="search-input">
                        <input v-model="componentKey" /><svg-icon
                          @click="searchComponent"
                          icon-class="search"
                        ></svg-icon>
                      </div>
                    </div>
                    <div class="component-cate">
                      <ul class="component-tab">
                        <li
                          :class="{ active: activeCate === '场景' }"
                          @click="handleCate('场景', sceneList)"
                        >
                          场景
                        </li>
                        <li
                          :class="{ active: activeCate === '模型' }"
                          @click="handleCate('模型', modelList)"
                        >
                          模型
                        </li>
                        <li
                          :class="{ active: activeCate === '特效' }"
                          @click="handleCate('特效', [])"
                        >
                          特效
                        </li>
                        <li
                          :class="{ active: activeCate === '看板' }"
                          @click="handleCate('看板', [])"
                        >
                          看板
                        </li>
                      </ul>
                      <div class="component-panel">
                        <ul
                          class="component-list"
                          v-if="activeCate === '场景' || activeCate === '模型'"
                        >
                          <li
                            v-for="item in resList"
                            :key="item.label"
                            @click="loadResFromSrc(item.src)"
                          >
                            <div class="pic">
                              <img :src="item.src" @dragstart="imgDragStart" />
                            </div>
                            <div class="label">{{ item.label }}</div>
                          </li>
                        </ul>
                        <ul class="component-list" v-if="activeCate === '特效'">
                          <li @click="threelib.setSnow()">
                            <div class="pic">
                              <img />
                            </div>
                            <div class="label">雪</div>
                          </li>
                           <li @click="threelib.setRain()">
                            <div class="pic">
                              <img />
                            </div>
                            <div class="label">雨</div>
                          </li>
                          <li @click="threelib.setLightning()">
                            <div class="pic">
                              <img />
                            </div>
                            <div class="label">雷电</div>
                          </li>
                        </ul>
                        <ul class="component-list" v-if="activeCate === '看板'">
                          <li v-for="item in []" :key="item.label">
                            <div class="pic">
                              <img :src="item.src" @dragstart="imgDragStart" />
                            </div>
                            <div class="label">{{ item.label }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!--大纲-->
                  <div
                    class="scroll-view max-height"
                    @contextmenu.prevent="onRightClick"
                  >
                    <div v-if="activeTab === '大纲'">
                      <!-- <a-tree
                        v-if="meshNodes != null && meshNodes.length > 0"
                        :tree-data="meshNodes"
                        :replaceFields="meshTreeReplaceFields"
                        :selectedKeys="nodelSelected"
                        :checkedKeys="nodeTicked"
                        :defaultExpandedKeys="nodeExpanded"
                        draggable
                        @drop="dropTreeNode"
                        @click="meshNodeSelect"
                      /> -->
                      <layer-tree
                        v-if="meshNodes != null && meshNodes.length > 0"
                        :tree-data="meshNodes"
                        :replaceFields="meshTreeReplaceFields"
                        :selectedKeys="nodelSelected"
                        :checkedKeys="nodeTicked"
                        :defaultExpandedKeys="nodeExpanded"
                        @drop="dropTreeNode"
                        @click="meshNodeSelect"
                      />
                      <div style="height: 100px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </pane>

        <!--画布-->
        <pane
          min-size="20"
          size="70"
          max-size="100"
          class="center-panel bg-color"
        >
          <div class="max-size right-side" ref="container"></div>
        </pane>
        <!--end 画布-->
        <pane min-size="17" size="19" max-size="22" class="bg-color">
          <div class="right-side" v-show="!isScreenfull" ref="rightSide">
            <div class="toggle-btn" @click="toggleRightSide">
              <svg-icon
                :icon-class="isShowRight ? 'arrow-right' : 'arrow-left'"
              ></svg-icon>
            </div>
            <div class="fullscreen-btn">
              <screen-full v-model="isScreenfull" />
            </div>
            <div class="property-wrap" v-show="isShowRight">
              <splitpanes @resize="threelib.resizeRenderer()" horizontal>
                <pane
                  min-size="20"
                  size="70"
                  max-size="100"
                  class="left-top scroll-view"
                >
                  <div class="property-bar">
                    <a-list
                      item-layout="horizontal"
                      :data-source="meshControl.selectMaterials"
                    >
                      <a-list-item v-slot="{ item, index }">
                        <a-list-item-meta description="">
                          <template #avatar>
                            <div
                              class="
                                button-group-left
                                property-name
                                transparent-color
                                white-color
                              "
                            >
                              材质{{ index }}
                            </div>
                          </template>
                          <template #title>
                            <a-button
                              :key="index"
                              color="primary"
                              class="button-group-left display-inline-left"
                              style="width: 80%"
                              @click="
                                setMaterial(item);
                                changeMaterialType(
                                  'THREE.MeshPhysicalMaterial'
                                );
                              "
                              >{{ item.name }}</a-button
                            >
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                    <SceneProperty
                      :meshControl="meshControl"
                      :threelib="threelib"
                    />
                    <ScriptProperty :meshControl="meshControl" />
                    <LightProperty
                      v-if="
                        meshControl.selectObject3d &&
                        meshControl.selectObject3d.isLight
                      "
                      :meshControl="meshControl"
                    />
                    <div v-else>
                      <a-checkbox-group
                        class="input-checkbox"
                        :options="[
                          {
                            label: '是否显示选中对象',
                            value: 'true',
                          },
                        ]"
                        :value="[getSelectObject3dVisible().toString()]"
                        @change="changeSelectObject3dVisible"
                      />
                      <MaterialPropertyList
                        @changeMaterialType="changeMaterialType"
                        :meshControl="meshControl"
                        :threelib="threelib"
                      />
                    </div>
                  </div>
                </pane>
              </splitpanes>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import DesignPage from "./designpage";
export default DesignPage;
</script>

<style lang="scss" scoped>
@import "./createpage_scope.scss";
</style>

<style lang="scss">
@import "./createpage.scss";
</style>