

/* eslint-disable */
import * as THREE from 'three'
import { XYZLine, COORDINATENAME } from '../3d/xyzline'
import { XYZCricle, coordinateName } from '../3d/xyzcricle'
import Common from "../common";
import OperationHistory from "../operation_history"
import { TransformControls, TransformControlsGizmo, TransformControlsPlane } from "../threejslib/TransformControls.js"
const SceneEvent = function (parms) {

    if (!parms) {
        parms = {}
    }
    let scene = parms.scene
    let camera = parms.camera
    let meshControl = parms.meshControl
    let initCameraPosition = parms.initCameraPosition
    let controls = parms.controls
    let threeContainer = parms.threeContainer
    let selectObjectEvent = parms.selectObjectEvent
    let axisObject = parms.axisObject
    let renderer = parms.renderer
    let operationObjectEvent = parms.operationObjectEvent
    let onWindowResize = parms.onWindowResize
    // let enableRender = parms.enableRender

    let axisCamera, axisScene, axisRenderer;

    let raycaster = new THREE.Raycaster()
    this.operationType = -1;//-1场景旋转移动,0:平移,1:旋转，2:缩放

    let beforeMovePosition3D = new THREE.Vector3(0, 0, 0)
    let beforeAxisDistance = 0;
    // let beforeSelectObject3d = null;

    let object3dState = {
        positions: [],
        rotations: [],
        scales: []
    }
    let scaleCanvasSize = 150

    this.getCasterObjects = function (position, object) {

        raycaster.setFromCamera(position, camera)

        const intersects = raycaster.intersectObject(object, true)

        return intersects
    }

    this.dispose = function (forceContextLoss = true) {
        // try {
        beforeAxisDistance = 0;
        if (axisCamera) {
            axisCamera.remove();
            axisCamera = null;
        }
        if (axisScene != null) {
            axisScene.remove();
            // axisScene.dispose();
            axisScene = null;
        }
        if (axisRenderer) {
            // axisRenderer.setAnimationLoop(null); //.bind(this);
            if (threeContainer && axisRenderer.domElement) {
                threeContainer.removeChild(axisRenderer.domElement);
                Common.empty(axisRenderer.domElement);
                // threeContainer = null;
            }
            if (forceContextLoss) {
                axisRenderer.forceContextLoss(); //Debug
            }
            axisRenderer.dispose();
            axisRenderer.content = null;
            axisRenderer.domElement = null;
            axisRenderer = null;
        }

        // } catch (err) {
        //     alert("SceneEvent dispose Error" + err.message);
        // }
    }

    let transformControl;
    this.initAxisCanvas = function () {

        this.dispose()

        axisScene = new THREE.Scene();
        axisRenderer = new THREE.WebGLRenderer({
            //增加下面这个属性，可以抗锯齿
            antialias: true,
            alpha: true,
            logarithmicDepthBuffer: true,
        });
        axisRenderer.setPixelRatio(devicePixelRatio);

        let clearColor = new THREE.Color()
        renderer.getClearColor(clearColor)
        axisRenderer.setClearColor(clearColor, 0)
        axisRenderer.outputEncoding = THREE.LinearEncoding
        axisRenderer.setAnimationLoop(null) //.bind(this);
        let canvas = axisRenderer.domElement

        //Debug
        for (let i = 0; i < threeContainer.children.length; i++) {
            let child = threeContainer.children[i]
            if (child === canvas) {
                threeContainer.removeChild(child)
                break
            }
        }

        canvas.style.backgroundColor = "rgba(0,0,0,0)"
        canvas.style.width = `${scaleCanvasSize}px`
        canvas.style.height = `${scaleCanvasSize}px`
        canvas.style.position = "absolute"
        canvas.style.zIndex = "10"

        threeContainer.appendChild(canvas)
        axisCamera = new THREE.PerspectiveCamera(60, canvas.offsetWidth / canvas.offsetHeight, 0.01, 10000)
        axisCamera.name = 'axisCamera'
        axisCamera.position.z = 60

        this.setAxisCanvasPosition();
        axisRenderer.setAnimationLoop(this.update.bind(this)) //.bind(this);


        transformControl = new TransformControls(camera, renderer.domElement);
        transformControl.addEventListener('change', onWindowResize);
        transformControl.addEventListener('dragging-changed', function (event) {
            controls.enabled = !event.value;
        });
        scene.add(transformControl);

    }

    this.setAxisCanvasPosition = function () {

        // let w = threeContainer.offsetWidth * 0.2
        // w = Math.max(scaleCanvasSize, w)

        // threeContainer.offsetLeft + 
        let clientX = threeContainer.offsetWidth - scaleCanvasSize - 10;
        let clientY = threeContainer.offsetTop - 30
        let canvas = axisRenderer.domElement
        if (canvas.style.left !== `${clientX}px`) {
            canvas.style.left = `${clientX}px`
        }
        if (canvas.style.top !== `${clientY}px`) {
            canvas.style.top = `${clientY}px`
        }
    }

    // this.update = function (time, frame) {
    this.update = function () {
        this.setAxisCanvasPosition()
        axisRenderer.render(axisScene, axisCamera);
    }



    let beforeIntersects = []
    let beforeSelectIndex = 0


    let threeDivClick = null
    let threeDivDown = null
    let threeDivUp = null
    let threeDivMove = null

    let isLeftMouseDown = false
    // let isMoved = false
    let isMouseMoveOpetationed = false

    let beforeMovePosition = new THREE.Vector3(0, 0, 0)
    /**
     * 上一次选择的坐标方向
     */
    let coordinate = null

    this.initAxisCanvas()
    let xyzLine = new XYZLine({ scene: scene })
    let allXyzLine = null
    // if (axisObject) {
    allXyzLine = axisObject//new XYZLine({ scene: axisScene })
    // }
    // else {
    //     allXyzLine = new XYZLine({ scene: axisScene })
    // }

    this.getXyzLineGroup = function () {
        if (xyzLine) {
            return xyzLine.getGroup()
        }
    }

    this.removeXyzLineFromParent = function () {
        xyzLine.getGroup().removeFromParent()
    }

    this.addXyzLineToGroup = function (group) {
        group.add(xyzLine.getGroup())
    }


    let cricleLine = new XYZCricle({ scene: scene })

    this.removeAllXYZAxisLine = function () {
        if (axisObject) {
            axisScene.remove(allXyzLine)
        }
        // else {
        //     allXyzLine.removeLines()
        // }
    }

    this.removeXyzGroup = function () {
        this.removeXyzLineFromParent()
        this.removeCricleLine()
    }

    this.removeCricleLine = function () {
        cricleLine.getGroup().removeFromParent()
    }

    this.addXYZAxisLine = function () {
        xyzLine.removeLines()
        xyzLine.drawXYZLine(150, 100)
        xyzLine.setLinesVisible(false)
        cricleLine.drawXYZLine()
        cricleLine.setLinesVisible(false)
    }

    this.addAllXYZAxisLine = function () {
        this.removeAllXYZAxisLine()
        if (axisObject) {
            axisScene.add(allXyzLine)
        }
        // else {
        //     allXyzLine.drawXYZLine(35, 20, 0.2, 0.5)
        // }

        this.renderAxis()
    }

    this.removeAxisForSave = function () {

        this.removeAllXYZAxisLine()
        this.removeDynamicCoordnates();
    }

    this.resetAxisForLoad = function () {
        this.addXYZAxisLine()
        this.addAllXYZAxisLine()
    }

    this.addXYZAxisLine();
    xyzLine.setLinesVisible(false)

    let getPosition = function (ev) {
        // console.log("mouse:", ev.clientX, ev.clientY);
        // console.log("mouse:", ev.offsetX, ev.offsetY);
        let mouse = new THREE.Vector2(ev.offsetX, ev.offsetY)
        mouse = getPositionFromPoint(mouse)
        return mouse
    }

    let getPositionFromPoint = function (mouse) {
        let canvas = renderer.domElement
        mouse.x = (mouse.x / canvas.offsetWidth) * 2 - 1
        mouse.y = -(mouse.y / canvas.offsetHeight) * 2 + 1
        return mouse
    }

    /* eslint-disable */
    this.renderAxis = function () {

        let canvas = renderer.domElement
        let x = canvas.offsetWidth
        let y = canvas.offsetHeight

        axisCamera.position.copy(camera.position)
        axisCamera.rotation.copy(camera.rotation)

        x *= 0.52
        y *= 0.55
        let pos = null;
        if (axisObject) {
            pos = allXyzLine.position
        }
        // else {
        //     pos = allXyzLine.getPosition()
        // }
        let offset = getPositionFromPoint({ x: x, y: y })

        if (isNaN(offset.x) || isNaN(offset.y)) {
            return
        }
        // let addScale = threeContainer.offsetWidth / canvas.offsetWidth * 0.8
        // offset.y *= threeContainer.offsetHeight / canvas.offsetHeight

        let screenPosition = pos.project(camera)
        screenPosition.x = offset.x
        screenPosition.y = offset.y
        let worldVector = new THREE.Vector3(0, 0, 0)
        let distance = 0

        if (beforeAxisDistance === 0) {
            let cloneCamera = camera.clone()
            cloneCamera.position.copy(initCameraPosition)
            worldVector = screenPosition.unproject(cloneCamera);
            distance = cloneCamera.position.distanceTo(worldVector)
            beforeAxisDistance = distance
        }
        else {
            worldVector = screenPosition.unproject(camera);
            distance = camera.position.distanceTo(worldVector)
            // console.log(distance)
            // console.log(beforeAxisDistance)
        }

        let scale = distance / beforeAxisDistance * 1.5// * addScale
        if (axisObject) {
            allXyzLine.scale.x = scale;
            allXyzLine.scale.y = scale;
            allXyzLine.scale.z = scale;
            allXyzLine.position.copy(worldVector)//.setPosition(worldVector)
        }
        // else {
        //     allXyzLine.setScale(scale)
        //     allXyzLine.setPosition(worldVector)
        // }

        xyzLine.udpateLineMaterials()
        cricleLine.udpateLineMaterials()
        // this.setRelativeZeroPosition(meshControl.selectObject3d)

        // this.setObject3DPosition();
        // if (meshControl.selectObject3d) {
        //     xyzLine.setLinesVisible(true)
        // }
        // else {
        //     xyzLine.setLinesVisible(false)
        // }

        // const x = threeContainer.offsetWidth - xyzSize
        // const v4 = renderer.getViewport(new THREE.Vector4())
        // renderer.setViewport(x, 0, xyzSize, xyzSize)
        // renderer.render(scene, camera)
        // renderer.setViewport(v4)

    }

    this.addAllXYZAxisLine()
    // this.onWindowTouch = function (ev) {
    //     if (ev.changedTouches) {
    //         var x, y;
    //         x = ev.changedTouches[0].clientX;
    //         y = ev.changedTouches[0].clientY;
    //         this.handleEventXY(ev, x, y);
    //     }
    // },


    this.resetXYZScale = function () {

        let obj3d = meshControl.selectObject3d
        if (obj3d) {
            if (this.operationType === 1) {
                let xyzGroup = cricleLine.getGroup()
                scene.attach(xyzGroup)
                cricleLine.setScale(1)
                // obj3d.attach(xyzGroup)
            }
            else {
                let xyzGroup = xyzLine.getGroup()
                scene.attach(xyzGroup)
                cricleLine.setScale(1)
                // obj3d.attach(xyzGroup)
            }
        }
    }


    this.isCanMoveCameraControl = function () {
        return meshControl.selectObject3d == null
    }

    let checkIsAxisObject = function (object) {
        let isAxisObject = object instanceof TransformControlsGizmo || object instanceof TransformControlsPlane || object instanceof TransformControls
        return isAxisObject
    }

    this.checkIsAxis = function (object) {
        if (!object) {
            return false
        }
        if (checkIsAxisObject(object)) {
            return true
        }
        if (object.parent) {
            // if (!(checkIsAxisObject(object.parent))) {
            //     return false;
            // }
            // else {
            return this.checkIsAxis(object.parent)
            // }
        }
        return false
    }

    this.triggerCasterEvent = function (ev) {

        let mesh = null
        let mouse = getPosition(ev)
        let intersects = this.getCasterObjects(mouse, scene);
        //选取的数组对象不一样时候。则重置befireSelectIndex = 0
        if (beforeIntersects.length !== intersects.length) {
            beforeSelectIndex = 0;
        }
        // console.log(intersects)
        // let isintersectCoord = false
        if (intersects.length > 0) {
            // for (let i = 0; i < intersects.length; i++) {
            //     let selectObject = intersects[i].object
            //     if (this.operationType === 1) {
            //         coordinate = cricleLine.getCoordinate(selectObject)
            //     }
            //     else {
            //         coordinate = xyzLine.getCoordinate(selectObject)
            //     }
            //     if (coordinate) {
            //         isintersectCoord = true
            //         break
            //     }
            // }

            if (beforeSelectIndex >= intersects.length) {
                beforeSelectIndex = 0;
            }
            mesh = intersects[beforeSelectIndex].object
            if (mesh) {
                // if (mesh.userData[coordinateName] !== COORDINATENAME) {
                //     // xyzLine.setPosition(mesh.position)
                //     // xyzLine.setLinesVisible(true)
                //     beforeSelectIndex++
                // }
                // else {
                while (mesh.userData[coordinateName] === COORDINATENAME || this.checkIsAxis(mesh)) {
                    beforeSelectIndex++
                    if (beforeSelectIndex >= intersects.length) {
                        beforeSelectIndex = 0
                        break;
                    }
                    mesh = intersects[beforeSelectIndex].object
                }
                if (mesh.userData[coordinateName] === COORDINATENAME) {
                    // if (mesh.userData['gridHelper'] == null) {
                    //     isintersectCoord = true
                    // }
                    // coordinate = mesh.userData[coordinateAxis]
                    //点击控制对象的XYZ方向的控件时，不可操作场景位置，只操作选中对象位移
                    // controls.enabled = false
                    mesh = null
                }
                else {
                    //点击控制对象时，不可操作场景位置，只操作选中对象位移
                    // controls.enabled = false
                    coordinate = null
                }
                // }
            }
        }

        if (mesh) {
            if (mesh.isCamera) {
                mesh = null
                isintersectCoord = true
            }
            if (mesh && (!mesh.parent || this.checkIsAxis(mesh))) {
                mesh = null
            }
        }

        // //选中对象为空时，或者点击场景的时候可以操作
        // if (mesh == null) {//场景不算选中对象
        //     controls.enableRotate = !isintersectCoord;
        // }
        // else {
        //     controls.enableRotate = false;
        // }

        beforeIntersects = intersects;
        return mesh;
    }

    this.setObject3DPosition = function (ev) {

        let obj3d = meshControl.selectObject3d
        // if (beforeSelectObject3d !== obj3d) {
        //     if (obj3d.userData[coordinateName] !== COORDINATENAME) {
        //         beforeMovePosition = new THREE.Vector3(0, 0, 0)
        //         beforeSelectObject3d = obj3d
        //     }
        // }
        if (!obj3d || !obj3d.parent) {
            return
        }
        let subVector = null
        let mouse = null
        if (ev) {
            mouse = getPosition(ev)
            subVector = new THREE.Vector2(mouse.x - beforeMovePosition.x, mouse.y - beforeMovePosition.y)
        }
        else {
            subVector = new THREE.Vector2(0, 0)
        }

        let xyzGroup = null;

        if (this.operationType === 0 || this.operationType === 2) {
            xyzGroup = xyzLine.getGroup()
        }
        else {
            xyzGroup = cricleLine.getGroup()
        }

        scene.attach(xyzGroup)
        // xyzLine.setScale(1)

        // let xyzParent = xyzGroup.parent

        let screenVector3 = xyzGroup.position.clone().project(camera)
        screenVector3.x += subVector.x;
        screenVector3.y += subVector.y

        let newPosition = screenVector3.unproject(camera);

        if (mouse != null) {
            beforeMovePosition = mouse
        }

        if (coordinate === "x") {
            newPosition.x = xyzGroup.position.x
            newPosition.y = xyzGroup.position.y
        }
        else if (coordinate === "y") {
            newPosition.x = xyzGroup.position.x
            newPosition.z = xyzGroup.position.z
        }
        else if (coordinate === "z") {
            newPosition.z = xyzGroup.position.z
            newPosition.y = xyzGroup.position.y
        }

        let parent = obj3d.parent
        xyzLine.setPosition(newPosition)

        //该语句默认在threejs渲染的过程中执行  如果想获得世界矩阵属性、世界位置属性等属性，需要手动更新
        scene.updateMatrixWorld(true);
        let worldNewPosition = new THREE.Vector3(0, 0, 0);
        xyzGroup.getWorldPosition(worldNewPosition);

        scene.attach(obj3d)
        obj3d.position.copy(worldNewPosition)

        parent.attach(obj3d)
    }

    this.setObject3DRotation = function (ev) {

        if (coordinate != null) {

            let mouse = getPosition(ev)
            let subVector = new THREE.Vector2(mouse.x - beforeMovePosition.x, mouse.y - beforeMovePosition.y)

            // let xyzGroup = cricleLine.getGroup()

            let distance = Math.max(Math.abs(subVector.x), Math.abs(subVector.y))
            if (distance === Math.abs(subVector.x)) {
                distance = subVector.x
            }
            else if (distance === Math.abs(subVector.y)) {
                distance = subVector.y
            }
            distance *= 3

            let vector = new THREE.Vector3(mouse.x, mouse.y, 0)
            vector.cross(beforeMovePosition);
            beforeMovePosition = mouse

            if (vector.z > 0) {
                //角度是逆时针方向的 
                distance = Math.abs(distance)
            } else {
                //角度是顺时针方向的
                distance = -Math.abs(distance)
            }

            // let xyzParent = xyzGroup.parent
            let obj3d = meshControl.selectObject3d

            // console.log(changeAxis)
            if (coordinate === "x") {
                obj3d.rotateX(-distance)
            }
            else if (coordinate === "y") {
                obj3d.rotateY(distance)
            }
            else if (coordinate === "z") {
                obj3d.rotateZ(-distance)
            }

            // xyzGroup.rotation.x = 0
            // xyzGroup.rotation.y = 0
            // xyzGroup.rotation.z = 0
        }

    }

    this.setObject3DScale = function (ev) {

        let mouse = getPosition(ev)
        let subVector = new THREE.Vector2(mouse.x - beforeMovePosition.x, mouse.y - beforeMovePosition.y)

        let obj3d = meshControl.selectObject3d

        let screenVector3 = obj3d.position.clone().project(camera)
        screenVector3.x += subVector.x;
        screenVector3.y += subVector.y

        let newPosition = screenVector3.unproject(camera);

        beforeMovePosition = mouse
        xyzLine.setLinesVisible(true)

        let subVector3D = newPosition.sub(beforeMovePosition3D)
        beforeMovePosition3D = newPosition

        subVector3D = subVector3D.applyEuler(camera.rotation)

        let distance = Math.max(Math.abs(subVector.x), Math.abs(subVector.y))
        if (distance === Math.abs(subVector.x)) {
            distance = subVector.x
        }
        else if (distance === Math.abs(subVector.y)) {
            distance = subVector.y
        }

        if (coordinate === "x") {
            // xyzGroup.rotation.z += distance
            obj3d.scale.x += distance
        }
        else if (coordinate === "y") {
            // xyzGroup.rotation.y += distance
            obj3d.scale.y += distance
        }
        else if (coordinate === "z") {
            // xyzGroup.rotation.x += distance
            obj3d.scale.z += distance
        }

        // this.resetXYZScale();
    }

    this.triggerMeshMoveEvent = function (ev) {
        // this.isMoved = true;
        if (meshControl.selectObject3d && meshControl.selectObject3d.parent) {
            if (isLeftMouseDown && !controls.enabled) {
                isMouseMoveOpetationed = true
                // // if (coordinate) {
                // // this.setRelativeZeroPosition(meshControl.selectObject3d)
                // if (this.operationType === 0) {//Position
                //     //this.setObject3DPosition(ev)
                //     transformControl.setMode("translate");//translate 平移   rotate 旋转    scale  缩放
                // }
                // else if (this.operationType === 1) {//Rotate
                //     // this.setObject3DRotation(ev)
                //     transformControl.setMode("rotate");
                // }
                // else if (this.operationType === 2) {//Scale
                //     // this.setObject3DScale(ev)
                //     transformControl.setMode("scale");
                // }
            }
            // else {
            //     this.getCoordinate(ev)
            // }
        }
    }

    // this.getCoordinate = function (ev) {
    //     let mouse = getPosition(ev)
    //     let intersects = this.getCasterObjects(mouse, scene);
    //     // if (meshControl.selectObject3d == null) {
    //     coordinate = null
    //     // }
    //     for (let i = 0; i < intersects.length; i++) {
    //         let selectObject = intersects[i].object
    //         if (this.operationType === 1) {
    //             coordinate = cricleLine.getCoordinate(selectObject)
    //         }
    //         else {
    //             coordinate = xyzLine.getCoordinate(selectObject)
    //         }
    //         if (coordinate) {
    //             break
    //         }
    //     }
    // }

    this.setRelativeZeroPosition = function (mesh, setRotation = true) {

        if (mesh) {
            let xyzGroup = xyzLine.getGroup() //Default Position 
            if (xyzGroup.parent == null) {
                xyzGroup = cricleLine.getGroup() //Default Position 
            }

            scene.attach(xyzGroup)
            let xyzParent = xyzGroup.parent


            if (xyzParent != mesh) {
                mesh.add(xyzGroup)
            }

            if (setRotation) {
                if (this.operationType === -1 || this.operationType === 0 || this.operationType === 2) {
                    xyzLine.setLinesRotation(mesh)
                }
            }

            // scene.updateMatrixWorld(true);

            xyzGroup.position.x = 0
            xyzGroup.position.y = 0
            xyzGroup.position.z = 0

            transformControl.attach(mesh);
            // scene.attach(xyzGroup)
            // xyzParent.attach(xyzGroup)
        }
    }

    this.setOperationType = function (type) {
        this.operationType = type
        if (meshControl.selectObject3d) {
            if (type === -1) {
                transformControl.detach();
            }
            else if (type === 0) {// Position
                // this.setMoveOrScaleCoordParams(meshControl.selectObject3d)
                transformControl.setMode("translate");//translate 平移   rotate 旋转    scale  缩放
                transformControl.attach(meshControl.selectObject3d);
            }
            else if (type === 1) {// Rotation
                // this.setRotationCoordParams(meshControl.selectObject3d)
                transformControl.setMode("rotate");
                transformControl.attach(meshControl.selectObject3d);
            }
            else if (type === 2) {// Scale
                // this.setMoveOrScaleCoordParams(meshControl.selectObject3d)
                transformControl.setMode("scale");
                transformControl.attach(meshControl.selectObject3d);
            }
            if (selectObjectEvent) {
                // beforeSelectPos = meshControl.selectObject3d.position
                selectObjectEvent(meshControl.selectObject3d)
            }
            if (operationObjectEvent) {
                operationObjectEvent(this.operationType)
            }
        }
    }

    this.getMeshSize = function (mesh) {

        this.removeXyzGroup()

        var box = new THREE.Box3().setFromObject(mesh);//Calc Box Size
        let size = new THREE.Vector3()
        box.getSize(size)
        let scale = 0.8;
        let drawSize = Math.max(size.x * scale, size.y * scale, size.z * scale)
        // drawSize = Math.min(120, drawSize)
        // drawSize = Math.max(60, drawSize)

        return drawSize
    }

    // let resetObject3dMatrix = function (obj3d) {
    //     obj3d.rotation.x = 0
    //     obj3d.rotation.y = 0
    //     obj3d.rotation.z = 0
    //     obj3d.position.set(0, 0, 0)
    //     obj3d.scale.set(1, 1, 1)
    // }

    // let resetWorldGroupPositionAndRrtation = function (xyzGroup) {

    //     const parent = xyzGroup.parent
    //     scene.attach(xyzGroup)
    //     xyzGroup.position = new THREE.Vector3(0, 0, 0)

    //     xyzGroup.rotation.x = 0
    //     xyzGroup.rotation.y = 0
    //     xyzGroup.rotation.z = 0

    //     parent.attach(xyzGroup)
    // }

    this.removeDynamicCoordnates = function () {
        xyzLine.removeLines()
        cricleLine.removeLines()
    }

    /**
     * 设置鼠标按下时拖动或者缩放的参数
     * @param {*} mesh 
     * @param {*} drawSize 
     */
    this.setMoveOrScaleCoordParams = function (mesh) {


        let drawSize = this.getMeshSize(mesh)

        this.removeDynamicCoordnates()

        xyzLine.drawXYZLine(drawSize, drawSize * 1.01)

        xyzLine.setLinesVisible(true)
        const xyzGroup = xyzLine.getGroup()
        mesh.add(xyzGroup)

        scene.attach(xyzGroup)
        xyzLine.setScale(1)
        mesh.attach(xyzGroup)

        this.setRelativeZeroPosition(mesh);
        // xyzLine.setLinesRotation(mesh)
    }



    // let setPositionAndRotate = function (before, after) {
    //     after.position.copy(before.position)
    //     after.rotation.copy(before.rotation)
    // }

    /**
     * 设置鼠标按下时旋转的参数
     * @param {*} mesh 
     * @param {*} drawSize 
     */
    this.setRotationCoordParams = function (mesh) {

        let drawSize = this.getMeshSize(mesh)

        this.removeDynamicCoordnates()

        cricleLine.drawXYZLine(drawSize, drawSize * 1.01)
        cricleLine.setLinesVisible(true)
        let xyzGroup = cricleLine.getGroup()
        mesh.add(xyzGroup)
        xyzGroup.rotation.x = 0
        xyzGroup.rotation.y = 0
        xyzGroup.rotation.z = 0

        scene.attach(xyzGroup)
        cricleLine.setScale(1)
        // mesh.attach(xyzGroup)
    }

    this.detachAxis = function () {
        if (transformControl) {
            transformControl.detach();
        }
    }

    this.setObject3DSelectState = function (mesh) {

        // beforeMovePosition3D = new THREE.Vector3(0, 0, 0)
        if (meshControl.selectObject3d) {
            if (this.operationType === -1) {
                transformControl.detach();
            }
            else if (this.operationType === 0 || this.operationType === 2) {
                this.setMoveOrScaleCoordParams(mesh)
            }
            else if (this.operationType === 1) {
                this.setRotationCoordParams(mesh)
            }
        }
    }

    this.bindEvents = function () {

        let self = this

        let canvas = renderer.domElement
        //click event
        if (threeDivClick) {
            canvas.removeEventListener('click', threeDivClick)
        }
        threeDivClick = (() => {
        }).bind(this)
        canvas.addEventListener('click', threeDivClick)

        //mousedown event
        if (threeDivDown) {
            canvas.removeEventListener('mousedown', threeDivDown)
        }
        threeDivDown = ((ev) => {

            // this.isMoved = false;
            if (ev.button === 0) {
                this.isMouseMoveOpetationed = false
                isLeftMouseDown = true
                beforeMovePosition = getPosition(ev)
                let mesh = self.triggerCasterEvent(ev)
                // if (mesh != null && mesh.isCamera) {
                //     mesh = null
                //     meshControl.selectObject3d = null
                //     controls.enableRotate = true
                // }
                // let selectMesh = self.triggerCasterEvent(ev)
                if (mesh != null) {
                    if (mesh.parent && mesh.parent.isLight) {
                        mesh = mesh.parent
                    }
                    if (mesh.userData[coordinateName] !== COORDINATENAME) {

                        if (mesh != meshControl.selectObject3d) {
                            meshControl.selectObject3d = mesh
                        }
                    }

                    // this.setObject3DSelectState(meshControl.selectObject3d)
                    // this.setRelativeZeroPosition(meshControl.selectObject3d, false)

                    // controls.enableRotate = false
                }

                if (meshControl.selectObject3d && meshControl.selectObject3d.parent) {
                    transformControl.attach(meshControl.selectObject3d);
                }

                if (selectObjectEvent) {
                    // beforeSelectPos = meshControl.selectObject3d.position
                    selectObjectEvent(meshControl.selectObject3d)
                }

                // else {
                //     if (meshControl.selectObject3d) {
                //         // if (this.operationType !== -1 && meshControl.selectObject3d) {
                //         if (this.operationType === 1) {
                //             cricleLine.selectCricle(coordinate)
                //         }
                //         else {
                //             xyzLine.selectLine(coordinate)
                //         }
                //     }
                // }

                // if (this.operationType === -1) {
                //     controls.enableRotate = true
                // }
                // else {
                if (meshControl.selectObject3d) {
                    let obj3d = meshControl.selectObject3d
                    if (obj3d.parent) {
                        let parent = obj3d.parent
                        scene.attach(obj3d)
                        object3dState.positions.push(obj3d.position.clone())
                        object3dState.rotations.push(obj3d.rotation.clone())
                        object3dState.scales.push(obj3d.scale.clone())
                        parent.attach(obj3d)
                    }
                    // if (this.operationType !== -1) {
                    // }
                }
                // else {
                //     selectMesh = null
                //     // controls.enableRotate = true
                //     // xyzLine.setLinesVisible(false)
                // }
                // }
            }
            else {
                controls.enableRotate = true
            }

            if (operationObjectEvent) {
                // if (controls.enableRotate) {
                //     this.operationType = -1
                // }
                if (this.operationType === -1 && !controls.enableRotate) {
                    this.operationType = 0
                }
                operationObjectEvent(this.operationType)
            }

        }).bind(this)
        canvas.addEventListener('mousedown', threeDivDown)

        //mouseup event
        if (threeDivUp) {
            canvas.removeEventListener('mouseup', threeDivUp)
        }
        threeDivUp = ((ev) => {
            controls.enableRotate = true

            // if (this.isMoved) {
            //     this.isMoved = false;
            //     if (ev.button === 2) {
            //         ev.stopPropagation();
            //         ev.preventDefault();
            //     }
            // }

            if (isMouseMoveOpetationed) {
                let obj3d = meshControl.selectObject3d
                let scope = this
                if (obj3d) {
                    let parent = obj3d.parent
                    scene.attach(obj3d)
                    let redoPos = obj3d.position.clone(), redoRot = obj3d.rotation.clone(), redoScale = obj3d.scale.clone(),
                        undoPos = object3dState.positions.pop(), undoRot = object3dState.rotations.pop(), undoScale = object3dState.scales.pop()
                    parent.attach(obj3d)

                    OperationHistory.push(() => {

                        let obj3d = meshControl.selectObject3d
                        let parent = obj3d.parent
                        scope.setObject3DSelectState(obj3d)

                        scene.attach(obj3d)

                        obj3d.position.copy(redoPos)
                        obj3d.rotation.copy(redoRot)
                        obj3d.scale.copy(redoScale)

                        parent.attach(obj3d)
                    }, () => {

                        let obj3d = meshControl.selectObject3d
                        let parent = obj3d.parent
                        scope.setObject3DSelectState(obj3d)

                        scene.attach(obj3d)

                        obj3d.position.copy(undoPos)
                        obj3d.rotation.copy(undoRot)
                        obj3d.scale.copy(undoScale)

                        parent.attach(obj3d)
                    })
                }

            }
            else {
                object3dState.positions.pop()
                object3dState.rotations.pop()
                object3dState.scales.pop()
            }
            isLeftMouseDown = false
            isMouseMoveOpetationed = false
            if (ev.button === 0) {
                this.triggerMeshMoveEvent(ev)
            }
            coordinate = null
            if (this.operationType === 1) {
                cricleLine.deselectEffect()
            }
            else {
                xyzLine.deselectEffect()
            }
            // beforeSelectPos = new THREE.Vector3(0, 0, 0)
            // beforeMoveMul = 0
        }).bind(this)
        canvas.addEventListener('mouseup', threeDivUp)


        //mousemove event
        if (threeDivMove) {
            canvas.removeEventListener('mousemove', threeDivMove)
        }

        threeDivMove = this.triggerMeshMoveEvent.bind(this)
        canvas.addEventListener('mousemove', threeDivMove)
    }
}

export default SceneEvent